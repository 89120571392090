import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Icon, Box } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { TranslationLabels } from '@generated/translation-labels';
import { ReactComponent as ChecklistPenIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-edition/checklist-pen.svg';
import { ReactComponent as NotesChecklistFlipIcon } from '@heimstaden/icons-library/img/streamline-regular/content/notes/notes-checklist-flip.svg';
import { ReactComponent as CommonFileStackIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-stack.svg';
import { ReactComponent as CommonFileTextEditIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-text-edit.svg';
import { useStyles } from './how-to-prepare-for-viewing.styles';

export const HowToPrepareForViewing: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <Box className={classes.box}>
        <div className={classes.titleWrapper}>
          <Icon
            className={classes.icon}
            width={48}
            height={48}
            icon={ChecklistPenIcon}
          />
          <Typography className={classes.title}>
            {t(TranslationLabels.howToPrepareForViewingTitle)}
          </Typography>
        </div>
        <hr className={classes.line} />
        <Typography className={classes.subTitle}>
          {t(TranslationLabels.howToPrepareForViewingDescription)}
        </Typography>
        <div className={classes.infoSectionWrapper}>
          <div className={classes.infoSection}>
            <div className={classes.infoSectionTitleWrapper}>
              <Icon
                className={classes.icon}
                width={48}
                height={48}
                icon={NotesChecklistFlipIcon}
              />
              <Typography className={classes.infoSectionTitle}>
                <strong>
                  {t(
                    TranslationLabels.howToPrepareForViewingPrepareSectionTitle,
                  )}
                </strong>
              </Typography>
            </div>
            <Typography className={classes.infoSectionDescription}>
              {t(
                TranslationLabels.howToPrepareForViewingPrepareSectionDescription,
              )}
            </Typography>
          </div>
          <hr className={classes.dashedLine} />
          <div className={classes.infoSection}>
            <div className={classes.infoSectionTitleWrapper}>
              <Icon
                className={classes.icon}
                width={48}
                height={48}
                icon={CommonFileStackIcon}
              />
              <Typography className={classes.infoSectionTitle}>
                <strong>
                  {t(
                    TranslationLabels.howToPrepareForViewingDocumentSectionTitle,
                  )}
                </strong>
              </Typography>
            </div>
            <Typography className={classes.infoSectionDescription}>
              {t(
                TranslationLabels.howToPrepareForViewingDocumentSectionDescription,
              )}
            </Typography>
          </div>
          <hr className={classes.dashedLine} />
          <div className={classes.infoSection}>
            <div className={classes.infoSectionTitleWrapper}>
              <Icon
                className={classes.icon}
                width={48}
                height={48}
                icon={CommonFileTextEditIcon}
              />
              <Typography className={classes.infoSectionTitle}>
                <strong>
                  {t(
                    TranslationLabels.howToPrepareForViewingContractSectionTitle,
                  )}
                </strong>
              </Typography>
            </div>
            <Typography className={classes.infoSectionDescription}>
              {t(
                TranslationLabels.howToPrepareForViewingContractSectionDescription,
              )}
            </Typography>
          </div>
        </div>
      </Box>
    </div>
  );
};
