import { ContactData } from './contact-info.type';

export const getContactDataInOrder = (
  contactData: ContactData[],
  currentCountry: GenericTypes.Country,
): ContactData[] => {
  const currentCountryContactData = contactData.find(
    (data) => data.country === currentCountry,
  );
  return [
    ...(currentCountryContactData ? [currentCountryContactData] : []),
    ...contactData.filter((data) => data.country !== currentCountry),
  ];
};
