import { ReactComponent as AlarmBell } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell.svg';
import { Notification } from '@shared/http/mie/api/notification/notifications.type';

type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

// To extend when notification types will be supported
export const getNotificationLink = (notification: Notification): string => {
  switch (notification) {
    default:
      return '/notifications';
  }
};

// To extend when notification types will be supported
export const getNotificationTitleTranslationKey = (
  notification: Notification,
): GenericTypes.TranslationLabel => {
  switch (notification) {
    default:
      return 'notificationItemTitleGeneric';
  }
};

// To extend when notification types will be supported
export const getNotificationDescriptionTranslationKey = (
  notification: Notification,
): {
  key: GenericTypes.TranslationLabel;
  variables?: { [key: string]: string };
} => {
  switch (notification) {
    default:
      return { key: 'notificationItemDescriptionGeneric' };
  }
};

// To extend when notification types will be supported
export const getIconForNotificationType = (): IconType => {
  return AlarmBell;
};

export const isNotificationRead = (notification: Notification): boolean =>
  notification.status === 'seen';
