import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import 'yup-phone';
import { TFunction } from 'i18next';
import { FieldConfig, FormProps } from '@shared/FormV2';
import { SelectValue } from '@shared/components/Fields';

export type Values = {
  day: SelectValue;
  time: SelectValue;
};

const timeSlotOptions: GenericTypes.TranslationLabel[] = [
  TranslationLabels.requestViewingModalTimeMornings,
  TranslationLabels.requestViewingModalTimeAfternoon,
  TranslationLabels.requestViewingModalTimeAllDay,
];

const dayOptions: GenericTypes.TranslationLabel[] = [
  TranslationLabels.requestViewingModalMonday,
  TranslationLabels.requestViewingModalTuesday,
  TranslationLabels.requestViewingModalWednesday,
  TranslationLabels.requestViewingModalThursday,
  TranslationLabels.requestViewingModalFriday,
];

export const getFields = (t: TFunction): FieldConfig<Values>[] => {
  return [
    {
      componentType: 'select',
      field: {
        name: 'day',
        initialValue: '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.requestViewingModalDayPickerLabel,
        options: dayOptions.map((day) => ({
          label: day,
          value: `${t(day)}`,
        })),
        required: true,
        placeholder: TranslationLabels.requestViewingModalDayPlaceholder,
      },
    },
    {
      componentType: 'select',
      field: {
        name: 'time',
        initialValue: '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.requestViewingModalTimePickerLabel,
        options: timeSlotOptions.map((timeSlot) => ({
          label: timeSlot,
          value: `${t(timeSlot)}`,
        })),
        required: true,
        placeholder: TranslationLabels.requestViewingModalTimePlaceholder,
      },
    },
  ];
};

export const getConfig = (
  handleSubmit: (comment: string) => Promise<void>,
  t: TFunction,
): FormProps<Values>['config'] => ({
  onSubmit: async (values): Promise<void> =>
    handleSubmit(
      `${t(TranslationLabels.requestViewingDayAndTimeSuggestionMessage)} ${
        values.day
      } - ${values.time}`,
    ),
});
