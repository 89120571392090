import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BlockContentProps } from '@sanity/block-content-to-react';
import { renderBlockContent } from '@shared/sanity/client.sanity';
import {
  ADDITIONAL_LANGS,
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  PRODUCTION_LANGS,
  SUPPORTED_COUNTRIES,
  SUPPORTED_LANGS,
} from '@shared/consts';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { TranslationError } from '@shared/errors';
import isEmpty from 'lodash-es/isEmpty';
import { sanityClient, SanityDocumentType } from '../sanity';

export type Translation = {
  key: string;
  options?: Record<string, string>;
};

type TextType = 'simple' | 'rich';
type SimpleValue = { [key: string]: string };
type RichValue = { [key: string]: string | BlockContentProps['blocks'] };
type Translations = {
  country: GenericTypes.Country;
  key: GenericTypes.TranslationLabel;
  textType: TextType;
  value?: SimpleValue;
  valueRich?: RichValue;
}[];
type Resources = Partial<
  Record<
    GenericTypes.Language,
    Partial<Record<GenericTypes.Country, Record<string, string>>>
  >
>;

const RICH_TEXT_PREFIX = 'rich-text:';

const getTranslationValue = (
  language: 'en' | 'native',
  textType: TextType,
  value?: SimpleValue,
  valueRich?: RichValue,
): string | undefined => {
  if (textType === 'rich') {
    return valueRich?.[language]
      ? `${RICH_TEXT_PREFIX}${JSON.stringify(valueRich[language])}`
      : undefined;
  }

  return value?.[language];
};

const getLanguageNamespacesData = (
  resources: Resources,
  lang: GenericTypes.Language,
  translation: Translations[0],
  specifiedNamespace?: GenericTypes.Country,
): Resources => {
  const { country, key, value, valueRich, textType } = translation;
  const translationLanguage = lang !== DEFAULT_LANGUAGE ? 'native' : lang;
  const translationValue = getTranslationValue(
    translationLanguage,
    textType,
    value,
    valueRich,
  );
  const namespace = specifiedNamespace || country;

  return translationValue && country === namespace
    ? {
        [lang]: {
          ...resources?.[lang],
          [namespace]: {
            ...resources?.[lang]?.[namespace],
            [key]: translationValue,
          },
        },
      }
    : {};
};

export const initTranslations = async (): Promise<void> => {
  const [nb, nn] = ADDITIONAL_LANGS;
  const resources = await sanityClient
    .fetch<Translations>(
      `*[_type == $type && country in ['nl', 'pl']]{ country, key, textType, value, valueRich }`,
      { type: SanityDocumentType.TRANSLATION },
    )
    .then((translations) =>
      translations.reduce((acc, translation) => {
        return {
          ...acc,
          ...getLanguageNamespacesData(acc, 'en', translation),
          ...getLanguageNamespacesData(acc, 'nl', translation, 'nl'),
          ...getLanguageNamespacesData(acc, 'pl', translation, 'pl'),
        };
      }, {}),
    )
    .catch((e) => {
      Sentry.captureException(e);

      return {};
    });

  await i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .use({
      type: 'postProcessor',
      name: 'rich-text',
      process: (value: string) => {
        if (value.startsWith(RICH_TEXT_PREFIX)) {
          try {
            const valueWithoutPrefix = value.replace(RICH_TEXT_PREFIX, '');
            return renderBlockContent({
              blocks: JSON.parse(valueWithoutPrefix),
            });
          } catch (err) {
            Sentry.captureException(
              new Error(
                `Invalid rich text translation value: ${value}. Cannot be parsed.`,
              ),
            );
            return undefined;
          }
        }

        return value;
      },
    })
    .init({
      postProcess: 'rich-text',
      ns: SUPPORTED_COUNTRIES,
      defaultNS: DEFAULT_COUNTRY,
      detection: {
        order: ['localStorage', 'navigator', 'htmlTag'],
      },
      fallbackLng: {
        [nb]: ['no', DEFAULT_LANGUAGE],
        [nn]: ['no', DEFAULT_LANGUAGE],
        default: [DEFAULT_LANGUAGE],
      },
      interpolation: { escapeValue: false },
      load: 'languageOnly',
      resources,
      saveMissing: true,
      supportedLngs: [...SUPPORTED_LANGS, ...ADDITIONAL_LANGS],
      nonExplicitSupportedLngs: true,
      missingKeyHandler: (lngs, ns, key, fallbackValue) => {
        if (
          !key ||
          isEmpty(resources) ||
          !PRODUCTION_LANGS.includes(ns as GenericTypes.Language)
        )
          return;

        const error = new TranslationError(
          `Missing translation for ${key} key in "${lngs}" languages`,
        );

        Sentry.captureException(error, {
          extra: {
            lngs,
            context: ns,
            key,
            fallbackValue,
          },
        });
      },
    });
};
