import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo } from 'react';
import { FormV2 } from '@shared/FormV2';
import Grid from '@material-ui/core/Grid';
import {
  getConfig,
  getFields,
  getTextareaField,
  Values,
} from './comment-form.model';
import { CommentSubmitButton } from '../CommentSubmitButton/comment-submit-button.component';
import { FormField } from '../../../FormV2/components/form-field.component';

type Props = {
  onSubmitComment: (content: string) => Promise<void>;
};

export const CommentForm: FC<Props> = ({ onSubmitComment }) => {
  const config = useMemo(() => getConfig(onSubmitComment), [onSubmitComment]);
  const fields = useMemo(() => getFields(<CommentSubmitButton />), []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormV2<Values>
          cancelLabel={
            TranslationLabels.ticketingDetailsEditorCancelButtonLabel
          }
          config={config}
          fieldsConfig={fields}
          onCancel={() => null}
          showButtons={false}
          submitLabel={TranslationLabels.ticketingDetailsEditorSendButtonLabel}
        >
          {(formProps) => (
            <FormField<Values>
              fieldConfig={getTextareaField(
                <CommentSubmitButton isSubmitting={formProps.isSubmitting} />,
              )}
              values={formProps.values}
            />
          )}
        </FormV2>
      </Grid>
    </Grid>
  );
};
