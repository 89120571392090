import { TFunctionKeys } from 'i18next';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation as useTranslationI18n } from 'react-i18next';
import {
  ADDITIONAL_LANGS,
  DEFAULT_LANGUAGE,
  SUPPORTED_COUNTRIES,
  SUPPORTED_LANGS,
  SUPPORTED_LANGS_PER_COUNTRY,
} from '@shared/consts';
import * as Sentry from '@sentry/react';
import { Spinner } from '@shared/components';
import { initTranslations } from '@shared/translations';
import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import { Context } from './context';

const translationKeysEnv = process.env.REACT_APP_SHOW_TRANSLATION_KEYS;

const CIMODE_LANGUAGE: GenericTypes.Language = 'cimode';

export const Provider: FC = ({ children }) => {
  const {
    t: i18nT,
    i18n,
    ready: translationReady,
  } = useTranslationI18n(SUPPORTED_COUNTRIES, { useSuspense: false });
  const country = useCountry();

  const langCode = useMemo(() => {
    if (!translationReady) return DEFAULT_LANGUAGE;

    return (ADDITIONAL_LANGS.includes(
      i18n.languages[0] as GenericTypes.AdditionalLanguage,
    )
      ? i18n.languages[1]
      : i18n.languages[0]) as GenericTypes.Language;
  }, [i18n.languages, translationReady]);

  const supportedLanguages = useMemo(() => {
    const languages = country
      ? SUPPORTED_LANGS_PER_COUNTRY[country]
      : SUPPORTED_LANGS;

    return translationKeysEnv ? [...languages, CIMODE_LANGUAGE] : languages;
  }, [country]);

  const changeLanguage = useCallback(
    async (language: GenericTypes.Language) => {
      if (!translationReady) return;

      await i18n.changeLanguage(language);
    },
    [i18n, translationReady],
  );

  const setLocalization = useCallback(async () => {
    if (!translationReady || !country) return;

    Sentry.setTag('country', country);

    if (!SUPPORTED_LANGS_PER_COUNTRY[country]?.includes(langCode)) {
      await changeLanguage(DEFAULT_LANGUAGE);
    }
  }, [changeLanguage, country, langCode, translationReady]);

  useEffect(() => {
    setLocalization().catch(Sentry.captureException);
    // setLocalization() should be called only after translationCountry change
    // user's country affects supported languages list (only when user is logged in)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    initTranslations().catch(Sentry.captureException);
  }, []);

  const t = useCallback(
    (key: TFunctionKeys, options?) => {
      return i18nT(key, { ...options, ns: country });
    },
    [i18nT, country],
  );

  useEffect(() => {
    if (translationReady) {
      document.title = t(TranslationLabels.pageTitle);
    }
  }, [t, translationReady]);

  return translationReady ? (
    <Context.Provider
      value={{
        langCode,
        supportedLanguages,
        t,
        changeLanguage,
      }}
    >
      {children}
    </Context.Provider>
  ) : (
    <Spinner />
  );
};

Provider.displayName = 'TranslationsProvider';
