import { mieClient } from '@shared/http/mie';
import { RefreshTokenResponse } from '@shared/http/mie/mie.types';
import { AuthTokens } from './auth.types';
import { api } from './auth.repository';

export const getAuthTokens = (): AuthTokens | undefined => {
  const authTokensStr = localStorage.getItem('authTokens');
  if (authTokensStr) {
    return JSON.parse(authTokensStr);
  }
  return undefined;
};

export const logout = (returnTo?: GenericTypes.URL): void => {
  localStorage.removeItem('authTokens');
  api.logOut(returnTo);
};

export const refreshToken = async (): Promise<void> => {
  const authTokens = getAuthTokens();
  if (!authTokens) {
    return;
  }

  const response = await mieClient.get<RefreshTokenResponse>('/auth/refresh', {
    refresh_token: authTokens.refreshToken,
    id_token: authTokens.idToken,
  });

  const newTokens: AuthTokens = {
    accessToken: response.accessToken,
    idToken: response.idToken,
    refreshToken: authTokens.refreshToken,
  };

  window.localStorage.setItem('authTokens', JSON.stringify(newTokens));
};
