import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { ContactData } from '@shared/components/ContactInfo/contact-info.type';

export const useGetDataQuery = (
  countries: GenericTypes.Country[],
): UseQueryResult<ContactData[] | undefined> => {
  return useQuery<ContactData[] | undefined>(
    ['getContactInfoData', countries],
    () => {
      if (!countries) return undefined;
      return sanityClient.fetch(
        `*[_type == $type && content.country in $countries]{
        _id,
        "address": content.address,
        "phone": content.phone,
        "email": content.email,
        "country": content.country
      }`,
        {
          type: SanityDocumentType.SETTINGS,
          countries,
        },
      );
    },
  );
};
