import React, { FC, useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TranslationLabels } from '@generated/translation-labels';
import { Form, Tooltip } from '@shared/components';
import { useTranslation } from '@shared/index';
import {
  Ticket,
  Attachment,
  Status,
  ElementName,
} from '@shared/http/mie/api/ticket/type';
import { useStyles } from './data.styles';
import { RequestDetails } from '../RequestDetails/request-details.component';
import { UpdateButton } from '../UpdateButton/update-button.component';
import { getFields, getConfig } from './data.model';
import { useServiceRequestMutation } from '../../../../shared/http/mie/api/ticket';
import { isChangeUserDetailsRequest } from '../../../ticketing.helper';
import { useAuth } from '../../../../+auth';

type Props = {
  ticket: Ticket;
  attachments: Attachment[];
  ticketStatus?: Status;
  elementNames?: ElementName[];
};

export const Data: FC<Props> = ({
  ticket,
  attachments,
  ticketStatus,
  elementNames,
}) => {
  const classes = useStyles();
  const [isReadonly, setReadonly] = useState(true);
  const { t } = useTranslation();
  const { user } = useAuth();
  const updateServiceRequest = useServiceRequestMutation(
    ticket.ticketType,
    ticket.id,
  );
  const isAuthor =
    user?.email?.toLowerCase() === ticket.reporter.email?.toLowerCase();

  const canUpdate = ticketStatus?.updateEnabled;

  const isUserDetailsRequest = isChangeUserDetailsRequest(ticket);

  const fields = getFields(
    isReadonly,
    classes,
    t,
    isUserDetailsRequest,
    ticket.status,
  );
  const config = getConfig({
    ticket,
    attachments,
    onUpdateTicket: updateServiceRequest.mutate,
    isChangeUserDetailsRequest: isUserDetailsRequest,
  });

  const handleCancel = (): void => {
    setReadonly(true);
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={7}>
        <div className={classes.leftColumn}>
          {isUserDetailsRequest && (
            <Typography className={clsx(classes.summaryText, classes.title)}>
              {t(
                TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
              )}
            </Typography>
          )}
          <Form
            config={config}
            fields={fields}
            onCancel={handleCancel}
            showButtons={!isReadonly && canUpdate}
            handleSuccess={() => setReadonly(true)}
          />
          {isReadonly && canUpdate && (
            <Tooltip
              disabled={isAuthor}
              messageKey={TranslationLabels.commentDisableButtonLabel}
              placement="right"
            >
              <UpdateButton disabled={!isAuthor} setReadonly={setReadonly} />
            </Tooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <RequestDetails
          status={ticketStatus}
          ticket={ticket}
          elementNames={elementNames}
        />
      </Grid>
    </Grid>
  );
};
