import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Comment as CommentType } from '../comments.type';
import { useStyles } from './comment.styles';

type Props = {
  comment: CommentType;
};

export const Comment: FC<Props> = (props) => {
  const { comment } = props;
  const classes = useStyles();

  return (
    <Card
      className={clsx(
        classes.comment,
        comment.isAuthor ? classes.outgoing : classes.incoming,
      )}
      variant="outlined"
    >
      <CardContent className={classes.content}>
        <Typography>{comment.message}</Typography>
      </CardContent>
    </Card>
  );
};
