import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { MenuElement } from '@shared/components/MenuElement/menu-element.component';
import clsx from 'clsx';
import React, { FC, useRef, useState } from 'react';
import { ContactInfo, Icon, AvailableFor } from '@shared/components';
import { ReactComponent as MenuIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/menu/navigation-menu.svg';
import { ReactComponent as CloseIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { useCountry, useOnClickOutside } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { SignOut, useAuth } from '../../../+auth';
import { useStyles } from './menu.styles';
import { useGetElementsQuery } from './menu.hook';

export const Menu: FC = () => {
  const classes = useStyles();
  const { auth } = useAuth();
  const { t } = useTranslation();
  const country = useCountry();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { data: elements, isError } = useGetElementsQuery(country);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleClick = (): void => {
    setOpen(!isOpen);
  };

  if (isError || !elements) {
    return null;
  }

  return (
    <div ref={ref} className={classes.menuContainer}>
      <Button className={classes.button} onClick={handleClick} variant="text">
        <Typography variant="h3" className={classes.text}>
          <strong>{t(TranslationLabels.headerMenuLabel)}</strong>
        </Typography>
        <div className={classes.menuIconWrapper}>
          <Icon
            className={clsx(classes.menuIcon, {
              [classes.menuIconHidden]: isOpen,
            })}
            icon={MenuIcon}
          />
          <Icon
            className={clsx(classes.menuIcon, {
              [classes.menuIconHidden]: !isOpen,
            })}
            icon={CloseIcon}
          />
        </div>
      </Button>
      <Fade in={isOpen}>
        <ul className={classes.menuListContainer}>
          {elements.map(
            ({
              id,
              shortDescriptionKey,
              titleKey,
              route,
              externalWebpageURL,
              availableFor,
            }) => (
              <AvailableFor key={id} availableForRoles={availableFor}>
                <MenuElement
                  {...(externalWebpageURL
                    ? {
                        component: Link,
                        href: externalWebpageURL,
                        rel: 'noopener noreferrer',
                        target: '_blank',
                      }
                    : {
                        to: route,
                      })}
                  onClick={handleClick}
                  shortDescriptionKey={shortDescriptionKey}
                  titleKey={titleKey}
                  data-test={`menu-element:${titleKey}`}
                />
              </AvailableFor>
            ),
          )}
          <SignOut />
          {auth && !auth.isUserInvalid && (
            <li className={classes.contactElement}>
              <div className={classes.contactInfo}>
                <Typography variant="h3">
                  <strong>{t(TranslationLabels.headerMenuContactTitle)}</strong>
                </Typography>
                <Typography>
                  {t(TranslationLabels.headerMenuContactText)}
                </Typography>
                <ContactInfo showOnlyCurrentCountry noAddress />
              </div>
            </li>
          )}
        </ul>
      </Fade>
    </div>
  );
};
