import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import {
  Box,
  Spinner,
  HeaderSection,
  ImagePlaceholder,
} from '@shared/components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import he from 'he';
import { useStyles } from './blog.styles';
import { useGetPostsQuery, useBlogUrlQuery } from './blog.hook';

export const Blog: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const {
    data: posts,
    isLoading: isLoadingPosts,
    isError: isPostsError,
  } = useGetPostsQuery(country);
  const {
    data: blogUrl,
    isLoading: isLoadingBlogUrl,
    isError: isBlogError,
  } = useBlogUrlQuery(country);

  if (isLoadingPosts || isLoadingBlogUrl) {
    return <Spinner />;
  }

  if (isBlogError || isPostsError || !posts) {
    return null;
  }

  return (
    <div>
      <HeaderSection htmlText={t(TranslationLabels.blogSectionTitle)} />
      <Grid container spacing={2}>
        {posts.map(({ id, title, link, imageUrl }) => (
          <Grid item md={4} sm={12} xs={12} key={id}>
            <Box className={classes.box}>
              <div>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="post"
                    className={classes.postImage}
                  />
                ) : (
                  <ImagePlaceholder className={classes.imagePlaceholder} />
                )}
                <Typography className={classes.postText} variant="h2">
                  {he.decode(title)}
                </Typography>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(TranslationLabels.blogReadStoryButton)}
                </Button>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <div className={classes.bottom}>
        <Typography variant="h3" className={classes.bottomText}>
          {t(TranslationLabels.visitOurBlogText)}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          href={blogUrl || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(TranslationLabels.visitOurBlogButton)}
        </Button>
      </div>
    </div>
  );
};
