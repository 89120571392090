import { TranslationLabels } from '@generated/translation-labels';
import { Main, ErrorScreen } from '@shared/components';
import React, { FC } from 'react';
import { useAuth } from '../../../+auth';

export const NotVerifiedEmailError: FC = () => {
  const { logout } = useAuth();

  return (
    <Main>
      <ErrorScreen
        titleKey={TranslationLabels.notVerifiedEmailErrorTitle}
        textKey={TranslationLabels.notVerifiedEmailErrorText}
        button={{
          textKey: TranslationLabels.tryToLogInButtonText,
          onClick: () => logout(),
        }}
      />
    </Main>
  );
};
