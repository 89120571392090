import Typography from '@material-ui/core/Typography';
import { getEnvValue } from '@shared/helpers';
import getYouTubeId from 'get-youtube-id';
import React, { FC, ReactElement } from 'react';
import SanityBlockContent, {
  BlockContentProps,
} from '@sanity/block-content-to-react';
import YouTube from 'react-youtube';
import { useStyles } from './block-content.styles';

type Props = Omit<BlockContentProps, 'dataset' | 'projectId' | 'serializers'>;

const projectId = getEnvValue('SANITY_PROJECT_ID');
const dataset = getEnvValue('SANITY_DATASET');

export const BlockContent: FC<Props> = (props) => {
  const classes = useStyles();
  const serializers: BlockContentProps['serializers'] = {
    types: {
      block: ({ children, node }): ReactElement => {
        switch (node.style) {
          default:
            return (
              <Typography variant="body1" className={classes.typography}>
                {children}
              </Typography>
            );
        }
      },
      youtube: ({ node }) => {
        const { url } = node;
        const id = getYouTubeId(url);

        return id ? (
          <div className={classes.iframeContainer}>
            <YouTube videoId={id} />
          </div>
        ) : null;
      },
    },
  };

  return (
    <SanityBlockContent
      {...props}
      serializers={serializers}
      projectId={projectId}
      dataset={dataset}
    />
  );
};
