import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { useQuery, UseQueryResult } from 'react-query';

const query = `
  *[_type == $type && country == $country && fieldId == string($failureId)][0].suggestion->{
    key
  }.key
`;

export const useSuggestionKeyQuery = (
  country: GenericTypes.Country,
  failureId: string,
): UseQueryResult<GenericTypes.TranslationLabel> => {
  return useQuery<GenericTypes.TranslationLabel>(
    'serviceRequestSuggestionKey',
    () =>
      sanityClient.fetch(query, {
        country,
        failureId,
        type: SanityDocumentType.TICKET_ELEMENT,
      }),
    { staleTime: Infinity },
  );
};
