import React, {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  useMemo,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import { Box, Spinner } from '@shared/components';
import { useTranslation } from '@shared/translations';
import * as Sentry from '@sentry/react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLocation } from 'react-router';
import {
  usePropertyAttachmentsQuery,
  usePropertyObjectAttachmentsQuery,
  useUnitAttachmentsQuery,
  useUnitQuery,
} from '@shared/http/mie';
import { useStyles } from './documents.styles';
import { LinkItem } from './Section/link-item.component';
import { Section } from './Section/section.component';
import { getAttachmentIcon } from './helper';
import { groupAttachments } from './documents.helper';

type Tab = 'contract' | 'rules' | 'instructions' | 'other';

export const Documents: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hash } = useLocation();
  const { id: unitId } = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState<Tab | undefined>(
    hash.substring(1) as Tab,
  );

  const {
    data: unit,
    error: errorOnUnit,
    isLoading: isLoadingUnit,
    isError: isErrorOnUnit,
  } = useUnitQuery(unitId);

  const {
    data: attachmentsPaginated,
    error: errorOnAttachments,
    isError: isErrorOnAttachments,
    isLoading: isLoadingAttachments,
  } = useUnitAttachmentsQuery(
    unitId,
    {
      page: 1,
      pageSize: 100,
    },
    'document',
  );

  const {
    data: propertyAttachmentsPaginated,
    error: errorOnPropertyAttachments,
    isError: isErrorOnPropertyAttachments,
    isLoading: isLoadingPropertyAttachments,
  } = usePropertyAttachmentsQuery(
    unit?.propertyId,
    {
      page: 1,
      pageSize: 100,
    },
    'document',
  );

  const {
    data: propertyObjectAttachmentsPaginated,
    error: errorOnPropertyObjectAttachments,
    isError: isErrorOnPropertyObjectAttachments,
    isLoading: isLoadingPropertyObjectAttachments,
  } = usePropertyObjectAttachmentsQuery(
    unit?.propertyObjectId,
    {
      page: 1,
      pageSize: 100,
    },
    'document',
  );

  const attachmentsCombined = useMemo(
    () => [
      ...(attachmentsPaginated?.results || []),
      ...(propertyAttachmentsPaginated?.results || []),
      ...(propertyObjectAttachmentsPaginated?.results || []),
    ],
    [
      attachmentsPaginated?.results,
      propertyAttachmentsPaginated?.results,
      propertyObjectAttachmentsPaginated?.results,
    ],
  );

  const { houseRules, manuals, contract, other } = groupAttachments(
    attachmentsCombined,
  );

  const isRulesTabVisible = houseRules && houseRules.length > 0;
  const isInstructionsTabVisible = manuals && manuals.length > 0;
  const isContractTabVisible = contract && contract.length > 0;
  const isOtherTabVisible = other && other.length > 0;

  const getDefaultActiveTab = useCallback((): Tab | undefined => {
    if (isContractTabVisible) return 'contract';
    if (isRulesTabVisible) return 'rules';
    if (isInstructionsTabVisible) return 'instructions';
    if (isOtherTabVisible) return 'other';
    return undefined;
  }, [
    isRulesTabVisible,
    isInstructionsTabVisible,
    isContractTabVisible,
    isOtherTabVisible,
  ]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(getDefaultActiveTab());
    }
  }, [activeTab, getDefaultActiveTab]);

  const handleTabChange = useCallback(
    (event: ChangeEvent<unknown>, tab: Tab): void => {
      setActiveTab(tab);
      window.location.hash = `#${tab}`;
    },
    [],
  );

  const isLoading =
    isLoadingAttachments ||
    isLoadingPropertyObjectAttachments ||
    isLoadingPropertyAttachments ||
    isLoadingUnit;

  const isError =
    isErrorOnAttachments ||
    isErrorOnPropertyAttachments ||
    isErrorOnPropertyObjectAttachments ||
    isErrorOnUnit ||
    !attachmentsPaginated ||
    !propertyAttachmentsPaginated ||
    !propertyObjectAttachmentsPaginated;

  const error =
    errorOnAttachments ||
    errorOnPropertyAttachments ||
    errorOnUnit ||
    errorOnPropertyObjectAttachments;

  if (isLoading) return <Spinner />;

  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  if (
    !isRulesTabVisible &&
    !isInstructionsTabVisible &&
    !isOtherTabVisible &&
    !isContractTabVisible
  ) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h2">
        {t(TranslationLabels.apartmentDocumentsTitle)}
      </Typography>
      <Typography>
        {t(TranslationLabels.apartmentDocumentsDescription)}
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className={classes.tabs}
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {isContractTabVisible && (
          <Tab label={t(TranslationLabels.contractTabTitle)} value="contract" />
        )}

        {isRulesTabVisible && (
          <Tab label={t(TranslationLabels.rulesTabTitle)} value="rules" />
        )}
        {isInstructionsTabVisible && (
          <Tab
            label={t(TranslationLabels.instructionsTabTitle)}
            value="instructions"
          />
        )}
        {isOtherTabVisible && (
          <Tab label={t(TranslationLabels.otherTabTitle)} value="other" />
        )}
      </Tabs>
      {isContractTabVisible && activeTab === 'contract' && (
        <Section>
          {contract.map((attachment) => (
            <LinkItem
              key={attachment.id}
              text={attachment.title || attachment.name}
              icon={getAttachmentIcon(attachment.contentType)}
              url={attachment.url}
            />
          ))}
        </Section>
      )}
      {isRulesTabVisible && activeTab === 'rules' && (
        <Section>
          {houseRules.map((attachment) => (
            <LinkItem
              key={attachment.id}
              text={attachment.title || attachment.name}
              icon={getAttachmentIcon(attachment.contentType)}
              url={attachment.url}
            />
          ))}
        </Section>
      )}
      {isInstructionsTabVisible && activeTab === 'instructions' && (
        <Section>
          {manuals.map((attachment) => (
            <LinkItem
              key={attachment.id}
              text={attachment.title || attachment.name}
              icon={getAttachmentIcon(attachment.contentType)}
              url={attachment.url}
            />
          ))}
        </Section>
      )}
      {isOtherTabVisible && activeTab === 'other' && (
        <Section>
          {other.map((attachment) => (
            <LinkItem
              key={attachment.id}
              text={attachment.title || attachment.name}
              icon={getAttachmentIcon(attachment.contentType)}
              url={attachment.url}
            />
          ))}
        </Section>
      )}
    </Box>
  );
};
