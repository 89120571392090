import { mieClient } from '../../mie.client';
import {
  adaptMiePaginated,
  adaptQueryParams,
  createMieOrder,
} from '../../mie.helper';
import { adaptNotification } from './notification.adapter';
import {
  GetNotificationsQueryParams,
  GetNotificationsResponse,
  MarkNotificationAsReadPayload,
  MieNotification,
  Notification,
} from './notifications.type';

export const notificationsApi = {
  getNotifications: async (
    pagination: GenericTypes.Pagination,
    sort?: GenericTypes.Sort<keyof Notification>,
    queryParams?: GetNotificationsQueryParams,
  ): Promise<GenericTypes.Paginated<Notification>> => {
    const response = await mieClient.get<GetNotificationsResponse>(
      '/notifications/',
      {
        page: pagination.page,
        page_size: pagination.pageSize,
        ...(sort && { order: createMieOrder<keyof Notification>(sort) }),
        ...(queryParams && adaptQueryParams(queryParams)),
      },
    );
    return adaptMiePaginated<MieNotification, Notification>(
      response,
      adaptNotification,
    );
  },
  markNotificationAsSeen: async ({
    notificationId,
  }: MarkNotificationAsReadPayload): Promise<MieNotification> => {
    const response = await mieClient.post<null, MieNotification>(
      `/notifications/${notificationId}/seen`,
      null,
    );
    return response;
  },
};
