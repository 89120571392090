import Grid from '@material-ui/core/Grid';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { ContactInfo, Container, Logo } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { Copyright } from '../Copyright/copyright.component';
import { FooterLinks } from '../FooterLinks/footer-links.component';
import { useStyles } from './footer.styles';
import { useGetFooterBlocksQuery } from './footer.hook';

export const Footer: FC = () => {
  const classes = useStyles();
  const { langCode } = useTranslation();
  const country = useCountry();
  const { data: blocks, isError } = useGetFooterBlocksQuery(country, langCode);

  if (isError) {
    return null;
  }

  return (
    <footer>
      <div className={classes.container}>
        <Container>
          <Grid container spacing={4} className={classes.content}>
            <Grid item xs={12} sm={4} md={3}>
              <Logo />
              <ContactInfo showOnlyCurrentCountry />
            </Grid>
            {blocks && blocks.length > 0 && (
              <Grid item xs={12} sm={8} md={9}>
                <FooterLinks blocks={blocks} />
              </Grid>
            )}
          </Grid>
          <Copyright />
        </Container>
      </div>
    </footer>
  );
};
