import clsx from 'clsx';
import React, { FC, ReactNode, useState, KeyboardEvent } from 'react';
import {
  ButtonDirection,
  CarouselButton,
} from '../CarouselButton/carousel-button.component';
import { useStyles } from './carousel.styles';
import { PreviewModal } from '../PreviewModal/preview-modal.component';
import { ButtonProps } from './type';

type Props = {
  length: number;
  children: (props: {
    activeItemIdx: number;
    LeftButton: FC<ButtonProps>;
    RightButton: FC<ButtonProps>;
    openModal: () => void;
  }) => ReactNode;
  modalChildren?: (props: {
    activeItemIdx: number;
    className: string;
  }) => ReactNode;
  className?: string;
};

const DEFAULT_ITEM_IDX = 0;

export function Carousel({
  children,
  modalChildren,
  className,
  length,
}: Props): ReturnType<FC<Props>> {
  const classes = useStyles();
  const [activeItemIdx, setActiveItemIdx] = useState(DEFAULT_ITEM_IDX);
  const [modalOpen, setModalOpen] = useState(false);

  const isButtonDisabled = (direction: ButtonDirection): boolean =>
    (direction === 'left' && activeItemIdx === DEFAULT_ITEM_IDX) ||
    (direction === 'right' && activeItemIdx === length - 1);

  const changeVisibleItem = (direction: ButtonDirection): void => {
    if (isButtonDisabled(direction)) return;
    const newIdx = direction === 'left' ? activeItemIdx - 1 : activeItemIdx + 1;
    setActiveItemIdx(newIdx);
  };

  const createButton = (direction: 'left' | 'right'): FC<ButtonProps> => ({
    className,
    onClick,
    disabled,
  }) => (
    <CarouselButton
      direction={direction}
      onClick={async (ev) => {
        if (onClick) await onClick(ev);
        changeVisibleItem(direction);
      }}
      disabled={disabled || isButtonDisabled(direction)}
      className={className}
    />
  );

  const handleKeyDown = (ev: KeyboardEvent<HTMLDivElement>): void => {
    switch (ev.key) {
      case 'ArrowLeft': {
        changeVisibleItem('left');
        break;
      }
      case 'ArrowRight': {
        changeVisibleItem('right');
        break;
      }
      default:
    }
  };

  const LeftButton = createButton('left');
  const RightButton = createButton('right');

  return (
    <div
      className={clsx(classes.container, className)}
      onKeyDown={handleKeyDown}
      aria-hidden="true"
    >
      {modalChildren && (
        <PreviewModal
          isOpen={modalOpen}
          LeftButton={LeftButton}
          RightButton={RightButton}
          onClose={() => setModalOpen(false)}
        >
          {({ className }) => {
            return modalChildren({
              activeItemIdx,
              className,
            });
          }}
        </PreviewModal>
      )}
      {children({
        activeItemIdx,
        LeftButton,
        RightButton,
        openModal: () => setModalOpen(true),
      })}
    </div>
  );
}
