import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { itemsPathSuffix } from '../../shared';
import { Issues } from './issues.type';

const getAreaQuery = (area: string, path: string): string => `
  ${area}.typeId == $areaId => ${area}.rooms[room->fieldId == $roomId][0]{
    "otherElementRedirectURL": "${path}/" + otherElementId,
    "issues": issues[]{
      ...issue->{
        "key": fieldId,
        "redirectURL": select(
          defined(^.items) => "${path}/" + fieldId + "${itemsPathSuffix}",
          defined(^.otherElementId) => "${path}/" + fieldId + "${itemsPathSuffix}/" + ^.otherElementId,
          "${path}/" + fieldId,
        ),
        "titleKey": title->key,
        externalWebpageUrl,
        roles,
      }
    },
  }
`;

const getQuery = (path: string): string => `
  * [_type == $type && content.country == $country][0]{
    ...content.ticketing.repairMaintenanceRequest.areas{
      ...select(
        ${getAreaQuery('commonV2', path)},
        ${getAreaQuery('outdoorV2', path)},
        ${getAreaQuery('unitV2', path)},
        ${getAreaQuery('privateOutdoorV2', path)},
      )
    }
  }
`;

export const useIssuesQuery = (
  country: GenericTypes.Country,
  areaId: string,
  path: string,
  roomId?: string,
): UseQueryResult<Issues> => {
  return useQuery<Issues>(
    ['serviceRequestIssues', country, areaId, roomId],
    () =>
      sanityClient.fetch(getQuery(path), {
        areaId,
        country,
        roomId,
        type: SanityDocumentType.SETTINGS,
        path,
      }),
    { staleTime: Infinity },
  );
};
