import { TranslationLabels } from '@generated/translation-labels';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import { ClassNameMap } from '@material-ui/styles';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { Box, Spinner } from '@shared/components';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { UseQueryResult } from 'react-query';
import React, { ChangeEvent, FC, MouseEvent, ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@shared/translations';
import { generateUniqId } from '../../helpers';
import { ArrowRightIcon } from '../icons';
import { useStyles } from './list.styles';

export type Model<T> = {
  getContent: (element: T) => string | ReactNode;
  id: keyof T;
  label: string;
  align?: TableCellProps['align'];
  disableSorting?: boolean;
  width?: TableCellProps['width'];
};

export type Props<T> = {
  model: Model<T>[];
  to?: GenericTypes.URL | ((element: T) => void);
  className?: string;
  isWidget?: boolean;
  property?: keyof T;
  defaultPageSize: number;
  pageSizeOptions: number[];
  paginatedQuery: (
    pagination: GenericTypes.Pagination,
    sort?: GenericTypes.Sort<keyof T>,
  ) => UseQueryResult<GenericTypes.Paginated<T>>;
  defaultSortBy: keyof T;
  classes?: ClassNameMap<string>;
  showArrow?: boolean;
};

export function List<T>(props: Props<T>): ReturnType<FC<Props<T>>> {
  const ownClasses = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    className,
    isWidget = false,
    model,
    property,
    to,
    defaultPageSize,
    pageSizeOptions,
    paginatedQuery,
    defaultSortBy,
    classes: overridenClasses,
    showArrow = true,
  } = props;

  const classes = overridenClasses || ownClasses;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [order, setOrder] = useState<GenericTypes.Sort<keyof T>['order']>(
    'asc',
  );
  const [sortBy, setSortBy] = useState<GenericTypes.Sort<keyof T>['sortBy']>(
    defaultSortBy,
  );

  const { isLoading, isError, error, data: paginatedData } = paginatedQuery(
    {
      page: page + 1,
      pageSize,
    },
    { order, sortBy },
  );

  const tableClassNames = [
    classes.table,
    ...(className ? [className] : []),
  ].join(' ');
  const isHead = model.filter(({ label }) => label).length > 0;

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPageSize(Number(event.target.value));
  };

  const handleSort = (id: keyof T) => (): void => {
    const isActive = sortBy === id;

    if (isActive) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
      return;
    }

    setSortBy(id);
    setOrder('asc');
  };

  const handleRowClick = async (element: T): Promise<void> => {
    if (typeof to === 'string') {
      history.push({
        pathname: `${to}${property ? `/${element[property]}` : ''}`,
      });
    } else if (typeof to === 'function') {
      history.push({
        pathname: `${to(element)}${property ? `/${element[property]}` : ''}`,
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={tableClassNames}>
        {isHead && (
          <TableHead>
            <Hidden xsDown>
              <TableRow className={classes.headerRow}>
                <td className={classes.paddingCell} />
                {model.map(({ align, disableSorting, id, label, width }) => (
                  <TableCell
                    align={align}
                    className={classes.headCell}
                    key={generateUniqId()}
                    sortDirection={order}
                    width={width}
                  >
                    {disableSorting ? (
                      t(label)
                    ) : (
                      <TableSortLabel
                        active={sortBy === id}
                        data-property={id}
                        direction={order}
                        onClick={handleSort(id)}
                      >
                        {t(label)}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                {showArrow && (
                  <TableCell
                    className={clsx(classes.headCell, classes.actionCell)}
                    align="right"
                    width="60"
                  />
                )}
                <td className={classes.paddingCell} />
              </TableRow>
            </Hidden>
          </TableHead>
        )}
        <TableBody>
          {paginatedData?.results && paginatedData?.results.length > 0 ? (
            paginatedData?.results.map((element) => (
              <TableRow
                className={clsx(classes.row, {
                  [classes.clickable]: Boolean(to),
                })}
                key={generateUniqId()}
                hover
                onClick={() => {
                  handleRowClick(element);
                }}
              >
                <td className={classes.paddingCell} />
                {model.map(({ align, getContent, label, width }) => (
                  <TableCell
                    align={align}
                    className={classes.cell}
                    key={generateUniqId()}
                    style={{
                      width: `${width}px`,
                    }}
                  >
                    {label && (
                      <Hidden smUp>
                        <Typography className={classes.cellLabel}>
                          <strong>{t(label)}:</strong>
                        </Typography>
                      </Hidden>
                    )}
                    {getContent(element)}
                  </TableCell>
                ))}
                <Hidden xsDown>
                  {showArrow && (
                    <TableCell
                      align="right"
                      className={classes.cell}
                      width={60}
                    >
                      <ArrowRightIcon width={24} height={24} />
                    </TableCell>
                  )}
                </Hidden>
                <td className={classes.paddingCell} />
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                className={classes.emptyCell}
                colSpan={model.length + 2}
              >
                <Typography className={classes.emptyCellText}>
                  <strong>{t(TranslationLabels.listEmptyText)}</strong>
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!isWidget && (
        <Box className={classes.pagination}>
          <TablePagination
            component="div"
            count={paginatedData?.count || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={pageSizeOptions}
            labelRowsPerPage={t(TranslationLabels.rowsPerPageLabel)}
            classes={{
              actions: classes.paginationActions,
              root: classes.paginationRoot,
              selectRoot: classes.paginationActions,
              toolbar: classes.paginationToolbar,
            }}
          />
        </Box>
      )}
    </TableContainer>
  );
}
