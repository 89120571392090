import { createContext } from 'react';
import { initialState } from './reducer';
import { State } from './type';

export type ContextValue = State & {
  authorize: () => Promise<void>;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  authorize: () => Promise.resolve(),
});
