import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    tabs: {
      marginTop: theme.spacing(3),
      '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap',
      },
    },
    indicator: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    sectionDescription: {
      padding: theme.spacing(0, 1, 1, 1),
      width: '100%',
    },
  }),
);
