import React, { FC, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GridSize } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { ReactComponent as DoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as RealEstateDimensionsPlan1Icon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-plan-1.svg';
import { ReactComponent as MoneyWalletIcon } from '@heimstaden/icons-library/img/streamline-regular/money-payments-finance/money/money-wallet.svg';
import { ReactComponent as RealEstateActionHouseHeart } from '@heimstaden/icons-library/img/streamline-regular/real-estate/actions-houses/real-estate-action-house-heart.svg';
import { useTranslation } from '@shared/translations';
import { Box, Icon, HeaderSection, Spinner } from '@shared/components';
import { useAdvertisementPageUrlQuery } from '@shared/sanity';
import {
  useMyAvailableUnitsNearbyQuery,
  useProspectUnitsQuery,
} from '@shared/http/mie';
import { useStyles } from './available-units-nearby.styles';

export const AvailableUnitsNearby: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: myProspectUnitsPaginated } = useProspectUnitsQuery({
    page: 1,
    pageSize: 100, // should be enough to find 3 units nearby
  });
  const myProspectUnitsIds = myProspectUnitsPaginated?.results.map(
    (r) => r.unit.id,
  );
  const {
    isLoading,
    isError,
    error,
    data: availableUnitsNearby,
  } = useMyAvailableUnitsNearbyQuery(myProspectUnitsIds || [], 3);
  const { data: advertisementPageUrl } = useAdvertisementPageUrlQuery();

  const mdGridSize = useMemo((): GridSize => {
    const count = availableUnitsNearby?.length;
    if (!count) return 'auto';
    if (count >= 3) return 4;
    return (12 / count) as GridSize;
  }, [availableUnitsNearby?.length]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !availableUnitsNearby) {
    Sentry.captureException(error);
    return null;
  }

  if (!availableUnitsNearby?.length) return null;

  return (
    <>
      <HeaderSection
        htmlText={t(TranslationLabels.availableUnitsNearbyTitle)}
      />
      <Grid container spacing={2}>
        {availableUnitsNearby.map(
          ({
            id,
            thumbnail,
            name,
            address,
            streetNumber,
            unitNumber,
            city,
            netArea,
            rentalPrice,
            rentalPriceCurrency,
            advertisementUrls,
          }) => (
            <Grid item md={mdGridSize} xs={12} key={id}>
              <Box className={classes.box}>
                <div>
                  {thumbnail ? (
                    <img src={thumbnail} alt="unit" className={classes.image} />
                  ) : (
                    <div className={classes.imagePlaceholder}>
                      <Icon
                        icon={RealEstateActionHouseHeart}
                        className={classes.imagePlaceholderIcon}
                      />
                    </div>
                  )}
                  <Typography variant="h2" className={classes.name}>
                    {name}
                  </Typography>
                  <div className={classes.listItem}>
                    <Icon icon={DoorIcon} className={classes.listItemIcon} />
                    <Typography variant="h3" className={classes.listItemText}>
                      {address} {streetNumber}
                      {unitNumber ? ` ${unitNumber}` : ''}
                      {city.name ? `, ${city.name}` : ''}
                    </Typography>
                  </div>
                  <div className={classes.listItem}>
                    <Icon
                      icon={RealEstateDimensionsPlan1Icon}
                      className={classes.listItemIcon}
                    />
                    <Typography variant="h3" className={classes.listItemText}>
                      {netArea} m<sup>2</sup>
                    </Typography>
                  </div>
                  <div className={classes.listItem}>
                    <Icon
                      icon={MoneyWalletIcon}
                      className={classes.listItemIcon}
                    />
                    <Typography variant="h3" className={classes.listItemText}>
                      {rentalPrice} {rentalPriceCurrency}
                    </Typography>
                  </div>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    // MYH-1562: for now support only OLX integration
                    href={
                      (advertisementUrls || []).filter(
                        (a) => a.integration === 'olx',
                      )[0]?.url
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(TranslationLabels.checkDetailsButton)}
                  </Button>
                </div>
              </Box>
            </Grid>
          ),
        )}
      </Grid>
      {advertisementPageUrl && (
        <div className={classes.bottom}>
          <Typography variant="h3" className={classes.advertisementPageText}>
            {t(TranslationLabels.otherUnitsButtonDescription)}
          </Typography>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            href={advertisementPageUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(TranslationLabels.otherUnitsButton)}
          </Button>
        </div>
      )}
    </>
  );
};
