import { mieClient } from '../../mie.client';
import {
  GeneralRequest,
  ComplaintRequest,
  ContractRequest,
  InvoiceAndAccountRequest,
  RepairAndMaintenanceRequest,
  Ticket,
  GetTicketsResponse,
  MieTicket,
  GetCommentsResponse,
  PostCommentAPIParams,
  MieComment,
  GetServiceRequestByIdResponse,
  Comment,
  PatchServiceRequestAPIParams,
  Attachment,
  PostAttachmentRequestAPIParams,
  GetAttachmentsResponse,
  GetTicketsQueryParams,
  Status,
  ElementName,
} from './type';
import {
  CreateGeneralRequestPayload,
  CreateGeneralRequestResponse,
  CreateComplaintRequestPayload,
  CreateComplaintRequestResponse,
  CreateContractRequestPayload,
  CreateContractRequestResponse,
  CreateInvoiceAndAccountRequestPayload,
  CreateInvoiceAndAccountRequestResponse,
  CreateRepairAndMaintenanceRequestPayload,
  CreateRepairAndMaintenanceRequestResponse,
} from './mie.type';
import {
  adaptGeneralRequest,
  adaptComplaintRequest,
  adaptContractRequest,
  adaptInvoiceAndAccountRequest,
  adaptRepairAndMaintenanceRequest,
} from './service-requests.adapter';
import { adaptMiePaginated, createMieOrder } from '../../mie.helper';
import { adaptComment, adaptTicket } from './tickets.adapter';
import { sanityClient, SanityDocumentType } from '../../../../sanity';

export const serviceRequestsApi = {
  createGeneralRequest: async (
    payload: CreateGeneralRequestPayload,
  ): Promise<GeneralRequest> => {
    const response = await mieClient.post<
      CreateGeneralRequestPayload,
      CreateGeneralRequestResponse
    >('/general-requests/', payload);

    return adaptGeneralRequest(response);
  },

  createComplaintRequest: async (
    payload: CreateComplaintRequestPayload,
  ): Promise<ComplaintRequest> => {
    const response = await mieClient.post<
      CreateComplaintRequestPayload,
      CreateComplaintRequestResponse
    >('/complaints/', payload);

    return adaptComplaintRequest(response);
  },

  createContractRequest: async (
    payload: CreateContractRequestPayload,
  ): Promise<ContractRequest> => {
    const response = await mieClient.post<
      CreateContractRequestPayload,
      CreateContractRequestResponse
    >('/contract-requests/', payload);

    return adaptContractRequest(response);
  },

  createInvoiceAndAccountRequest: async (
    payload: CreateInvoiceAndAccountRequestPayload,
  ): Promise<InvoiceAndAccountRequest> => {
    const response = await mieClient.post<
      CreateInvoiceAndAccountRequestPayload,
      CreateInvoiceAndAccountRequestResponse
    >('/invoice-requests/', payload);

    return adaptInvoiceAndAccountRequest(response);
  },

  createRepairAndMaintenanceRequest: async (
    payload: CreateRepairAndMaintenanceRequestPayload,
  ): Promise<RepairAndMaintenanceRequest> => {
    const response = await mieClient.post<
      CreateRepairAndMaintenanceRequestPayload,
      CreateRepairAndMaintenanceRequestResponse
    >('/service-requests/', payload);

    return adaptRepairAndMaintenanceRequest(response);
  },

  getTickets: async (
    pagination: GenericTypes.Pagination,
    sort?: GenericTypes.Sort<keyof Ticket>,
    queryParams: GetTicketsQueryParams = {},
  ): Promise<GenericTypes.Paginated<Ticket>> => {
    const response = await mieClient.get<GetTicketsResponse>('/tickets/', {
      page: pagination.page,
      page_size: pagination.pageSize,
      ...(sort && { order: createMieOrder<keyof Ticket>(sort) }),
      ...queryParams,
    });

    return adaptMiePaginated<MieTicket, Ticket>(response, adaptTicket);
  },

  getComments: async (
    id: Ticket['id'],
    pagination: GenericTypes.Pagination,
  ): Promise<GenericTypes.Paginated<Comment>> => {
    const response = await mieClient.get<GetCommentsResponse>(
      `/tickets/${id}/comments`,
      {
        order: '-created_at',
        page: pagination.page,
        page_size: pagination.pageSize,
      },
    );
    return adaptMiePaginated<MieComment, Comment>(response, adaptComment);
  },

  postComment: async (
    ticketId: string,
    comment: PostCommentAPIParams,
  ): Promise<Comment> => {
    const response = await mieClient.post<PostCommentAPIParams, MieComment>(
      `/tickets/${ticketId}/comments`,
      comment,
    );
    return adaptComment(response);
  },

  getServiceRequestById: async (id: Ticket['id']): Promise<Ticket> => {
    const response = await mieClient.get<GetServiceRequestByIdResponse>(
      `/service-requests/${id}`,
    );
    return adaptTicket(response);
  },

  getContractRequestById: async (id: Ticket['id']): Promise<Ticket> => {
    const response = await mieClient.get<GetServiceRequestByIdResponse>(
      `/contract-requests/${id}`,
    );
    return adaptTicket(response);
  },

  getInvoiceRequestById: async (id: Ticket['id']): Promise<Ticket> => {
    const response = await mieClient.get<GetServiceRequestByIdResponse>(
      `/invoice-requests/${id}`,
    );
    return adaptTicket(response);
  },

  getGeneralRequestById: async (id: Ticket['id']): Promise<Ticket> => {
    const response = await mieClient.get<GetServiceRequestByIdResponse>(
      `/general-requests/${id}`,
    );
    return adaptTicket(response);
  },

  getComplaintRequestById: async (id: Ticket['id']): Promise<Ticket> => {
    const response = await mieClient.get<GetServiceRequestByIdResponse>(
      `/complaints/${id}`,
    );
    return adaptTicket(response);
  },

  patchServiceRequest: async (
    ticket: PatchServiceRequestAPIParams,
  ): Promise<Ticket> => {
    const response = await mieClient.patch<
      PatchServiceRequestAPIParams,
      MieTicket
    >(`/service-requests/${ticket.uuid}`, ticket);
    return adaptTicket(response);
  },

  patchGeneralRequest: async (
    ticket: PatchServiceRequestAPIParams,
  ): Promise<Ticket> => {
    const response = await mieClient.patch<
      PatchServiceRequestAPIParams,
      MieTicket
    >(`/general-requests/${ticket.uuid}`, ticket);
    return adaptTicket(response);
  },

  patchContractRequest: async (
    ticket: PatchServiceRequestAPIParams,
  ): Promise<Ticket> => {
    const response = await mieClient.patch<
      PatchServiceRequestAPIParams,
      MieTicket
    >(`/contract-requests/${ticket.uuid}`, ticket);
    return adaptTicket(response);
  },

  patchInvoiceRequest: async (
    ticket: PatchServiceRequestAPIParams,
  ): Promise<Ticket> => {
    const response = await mieClient.patch<
      PatchServiceRequestAPIParams,
      MieTicket
    >(`/invoice-requests/${ticket.uuid}`, ticket);
    return adaptTicket(response);
  },

  patchComplaintRequest: async (
    ticket: PatchServiceRequestAPIParams,
  ): Promise<Ticket> => {
    const response = await mieClient.patch<
      PatchServiceRequestAPIParams,
      MieTicket
    >(`/complaints/${ticket.uuid}`, ticket);
    return adaptTicket(response);
  },

  postAttachments: async (
    attachment: PostAttachmentRequestAPIParams,
    ticketId: string,
  ): Promise<Attachment> => {
    const response = await mieClient.post<
      PostAttachmentRequestAPIParams,
      Attachment
    >(`/tickets/${ticketId}/attachments`, attachment);
    return response;
  },
  getAttachments: async (ticketId: string): Promise<Attachment[]> => {
    const response = await mieClient.get<GetAttachmentsResponse>(
      `/tickets/${ticketId}/attachments`,
    );
    return response?.results;
  },

  deleteAttachment: async (
    ticketId: string,
    attachmentId: string,
  ): Promise<void> => {
    await mieClient.delete(`/tickets/${ticketId}/attachments/${attachmentId}`);
  },

  getProspectStatusesConfig: async (
    country: GenericTypes.Country,
  ): Promise<Status[]> => {
    const response = await sanityClient.fetch<{
      statuses: {
        jiraKey: string;
        textKey: GenericTypes.TranslationLabel;
        colorHex: string;
        updateEnabled: boolean;
      }[];
    }>(
      `* [_type == $type && content.country == $country]{
        "statuses": content.prospectsStatuses[]{
          "jiraKey": jiraKey.current,
          "textKey": name->key,
          "colorHex": color.hex,
          updateEnabled,
        }
      }[0]`,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );
    return (response.statuses || []).map(({ textKey, ...rest }) => ({
      text: textKey,
      ...rest,
    }));
  },

  getTicketStatusesConfig: async (
    country: GenericTypes.Country,
  ): Promise<Status[]> => {
    const response = await sanityClient.fetch<{
      statuses: {
        jiraKey: string;
        textKey: GenericTypes.TranslationLabel;
        colorHex: string;
        updateEnabled: boolean;
      }[];
    }>(
      `* [_type == $type && content.country == $country]{
        "statuses": content.ticketStatuses[]{
          "jiraKey": jiraKey.current,
          "textKey": name->key,
          "colorHex": color.hex,
          updateEnabled,
        }
      }[0]`,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );
    return (response.statuses || []).map(({ textKey, ...rest }) => ({
      text: textKey,
      ...rest,
    }));
  },
  getElementNames: (
    ids: (string | undefined)[],
    country: string,
  ): Promise<ElementName[]> => {
    if (!ids.length) {
      return Promise.resolve([]);
    }

    const mappedIds = ids
      .filter((id) => Boolean(id))
      .map((id) => `fieldId == "${id}"`)
      .join(' || ');

    return sanityClient.fetch<
      { id: string; titleKey: GenericTypes.TranslationLabel }[]
    >(
      `
      *[_type == $type && country == $countryCode && (${mappedIds})]{
        "id": fieldId,
        "titleKey": title->key
      }
    `,
      { type: SanityDocumentType.TICKET_ELEMENT, countryCode: country },
    );
  },
};
