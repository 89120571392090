import BlockContent, {
  BlockContentProps,
} from '@sanity/block-content-to-react';
import sanityClient from '@sanity/client';
import sanityBuilder from '@sanity/image-url';
import { Environment } from '@shared/enums';
import getYouTubeId from 'get-youtube-id';
import React from 'react';
import YouTube from 'react-youtube';
import { getEnvValue, isEnv } from '../helpers';

const projectId = getEnvValue('SANITY_PROJECT_ID');
const dataset = getEnvValue('SANITY_DATASET');

const serializers: BlockContentProps['serializers'] = {
  types: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    block: ({ children, node: { style } }: any): JSX.Element => {
      switch (style) {
        default:
          return <div style={{ padding: '8px 0' }}>{children}</div>;
      }
    },
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeId(url);

      return id ? (
        <div className="iframe-container">
          <YouTube videoId={id} />
        </div>
      ) : null;
    },
  },
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a
          href={
            mark.internal ? `${window.location.origin}${mark.href}` : mark.href
          }
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      ) : (
        <a
          href={
            mark.internal ? `${window.location.origin}${mark.href}` : mark.href
          }
        >
          {children}
        </a>
      ),
  },
};

export const client = sanityClient({
  apiVersion: 'v2021-03-25',
  dataset,
  projectId,
  useCdn: isEnv(Environment.PRODUCTION),
});
export const renderBlockContent = (
  props: Omit<BlockContentProps, 'dataset' | 'projectId' | 'serializers'>,
): JSX.Element => (
  <BlockContent
    {...props}
    serializers={serializers}
    projectId={projectId}
    dataset={dataset}
  />
);
export const imageBuilder = sanityBuilder(client);
