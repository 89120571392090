import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { notificationsApi } from './notifications';
import {
  GetNotificationsQueryParams,
  MarkNotificationAsReadPayload,
  MieNotification,
  Notification,
} from './notifications.type';

export const useNotificationsQuery = (
  pagination: GenericTypes.Pagination,
  sort?: GenericTypes.Sort<keyof Notification>,
  queryParams?: GetNotificationsQueryParams,
): UseQueryResult<GenericTypes.Paginated<Notification>> => {
  return useQuery(['notifications', pagination, sort], () =>
    notificationsApi.getNotifications(pagination, sort, queryParams),
  );
};

export const useMarkOneAsReadMutation = (): UseMutationResult<
  MieNotification,
  AxiosError,
  MarkNotificationAsReadPayload
> => {
  const queryClient = useQueryClient();
  return useMutation(notificationsApi.markNotificationAsSeen, {
    onSuccess: () => queryClient.invalidateQueries(['notifications']),
  });
};
