import React, { FC, ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import { Spinner } from '@shared/components';
import { useAvailableFor } from '@shared/hooks';
import { UserGlobalRole, UserUnitRole } from '@shared/enums';

type Props = {
  children: NonNullable<ReactElement>;
  availableForRoles: (UserGlobalRole | UserUnitRole)[];
  currentUnitId?: string; // needed only if unit-depended roles provided
  fallback?: ReactElement;
  showLoader?: boolean;
};

export const AvailableForInternal: FC<Props> = (props) => {
  const {
    children,
    fallback,
    availableForRoles,
    currentUnitId,
    showLoader = false,
  } = props;

  const { isAvailable, isLoading, isError, error } = useAvailableFor({
    availableForRoles,
    currentUnitId,
  });

  if (isLoading) {
    return showLoader ? <Spinner /> : null;
  }

  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  return isAvailable ? children : fallback || null;
};
