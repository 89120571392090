import { PaginatedMie } from './mie.types';

export const adaptMiePaginated = <TApi, T>(
  data: PaginatedMie<TApi>,
  adaptResult: (data: TApi) => T,
): GenericTypes.Paginated<T> => {
  return {
    count: data.count,
    next: data.next,
    previous: data.previous,
    results: data.results.map(adaptResult),
  };
};

export const createMieOrder = <SortBy extends string>(
  sort: GenericTypes.Sort<SortBy>,
): string => {
  let sortParam: string = sort.sortBy;
  if (sort.order === 'desc') {
    sortParam = `-${sortParam}`;
  }
  return sortParam;
};

export const arrayToQueryParam = (
  arrayQueryParam: (string | number)[],
): string => arrayQueryParam.join(',');

type QueryParams = {
  [key: string]: string | number | string[] | number[];
};

type MieQueryParams = {
  [key: string]: string | number;
};

export const adaptQueryParams = (queryParams: QueryParams): MieQueryParams => {
  return Object.fromEntries(
    Object.entries(queryParams).map(([key, value]) => [
      key,
      Array.isArray(value) ? value.join(',') : value,
    ]),
  );
};
