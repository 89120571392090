import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Element } from '@shared/components/Menu/menu.type';

export const useGetElementsQuery = (
  country: GenericTypes.Country,
): UseQueryResult<Element[] | undefined> => {
  return useQuery<Element[] | undefined>(['getMenuElements', country], () => {
    if (!country) return undefined;
    return sanityClient
      .fetch<{ elements: Element[] }>(
        `* [(_type == $type && content.country == $country)][0]{
        "elements": [
          ...content.header.menuElements[]->{
            "id": _id,
            "route": route,
            "titleKey": menuTitle->key,
            "shortDescriptionKey": menuText->key,
            "externalWebpageURL": externalWebpageUrl,
            "availableFor": roles
          }
        ]
      }`,
        {
          country,
          type: SanityDocumentType.SETTINGS,
        },
      )
      .then((response) => response.elements);
  });
};
