import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Request } from './requests.type';
import { PATHS } from '../shared/routing/paths.const';

export const useGetRequestsQuery = (
  country: GenericTypes.Country,
): UseQueryResult<Request[]> => {
  return useQuery<Request[]>(['getTicketingRequests', country], async () => {
    if (!country) return [];

    const query = `
      * [_type == $type && content.country == $country][0]{
        "requests": [
          content.ticketing.repairMaintenanceRequest{
            "descriptionKey": text->key,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "repairMaintenanceRequest",
            "redirectURL": "${PATHS.SERVICE_REQUEST_AREAS}",
            "titleKey": title->key,
          },
          content.ticketing.customerAccountRequest{
            "descriptionKey": text->key,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "customerAccountRequest",
            "redirectURL": "${PATHS.MY_ACCOUNT}",
            "titleKey": title->key,
          },
          content.ticketing.customerServiceRequest{
            "descriptionKey": text->key,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "customerServiceRequest",
            "redirectURL": "${PATHS.CUSTOMER_SERVICE}",
            "titleKey": title->key,
          },
        ]
      }
    `;

    const response = await sanityClient.fetch<{ requests: Request[] }>(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });

    return response.requests;
  });
};
