import { DEFAULT_COUNTRY } from '@shared/consts';
import { SUPPORTED_COUNTRIES_ALL_VERSIONS } from '@shared/index';
import { useAuth } from '../../+auth';

export const useCountry = (): GenericTypes.Country => {
  const { auth } = useAuth();
  const countryDomain = window.location.hostname.split('.').pop();
  const checkDomainType = (): GenericTypes.Country =>
    SUPPORTED_COUNTRIES_ALL_VERSIONS.find(
      (element) => element === countryDomain,
    )
      ? (countryDomain as GenericTypes.Country)
      : DEFAULT_COUNTRY;

  return auth?.country || checkDomainType();
};
