import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      border: `1px solid ${palette.lightGray}`,
      boxShadow: '0px 6px 12px rgb(0 0 0 / 12%)',
      borderRadius: theme.spacing(1),
      overflowX: 'clip',
    },
    headCell: {
      borderBottom: `2px solid ${palette.altoD6} !important`,
      paddingBottom: 0,
      paddingTop: 0,
      height: 48,
      fontSize: theme.typography.pxToRem(14),
      color: palette.lighterGray,
      fontWeight: 700,

      '& svg': {
        width: '1em',
        height: '1em',
        color: 'inherit',
      },
    },
    row: {
      backgroundColor: palette.white,
      padding: theme.spacing(2),
      height: 56,
    },
    clickable: {
      cursor: 'pointer',
    },
    headerRow: {
      padding: theme.spacing(2, 4),
    },
    cell: {
      padding: theme.spacing(0, 2),
      borderBottom: `1px solid ${palette.wildSand}`,
    },
    actionCell: {
      '& i': {
        display: 'block',
      },
    },
    emptyCell: {
      backgroundColor: palette.athensGray,
      borderBottom: 'unset',
      width: '100%',
    },
    emptyCellText: {
      display: 'flex',
      justifyContent: 'center',
    },
    paddingCell: {
      width: 16,
      padding: 0,
      border: 'none',
    },
    pagination: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      width: '100%',
      borderStyle: 'none',
      borderRadius: 0,
      height: '50px',
    },
    additionalFiler: {
      padding: theme.spacing(0, 1),
    },
    paginationActions: {
      '& svg': {
        color: 'inherit',
        height: '1em',
        width: '1em',
      },
    },

    [theme.breakpoints.down('xs')]: {
      row: {
        padding: 0,
        marginBottom: theme.spacing(2),
      },
      cellLabel: {
        display: 'inline-block',
        marginRight: theme.spacing(1),
      },
      cell: {
        alignItems: 'center',
        borderBottom: `1px solid ${palette.wildSand}`,
        borderLeft: `1px solid ${palette.wildSand}`,
        borderRight: `1px solid ${palette.wildSand}`,
        flexDirection: 'row !important',
        textAlign: 'left !important',
        width: '100% !important',
      },
      pagination: {
        flexDirection: 'column-reverse',
      },
      paginationRoot: {
        width: '100%',
      },
      paginationToolbar: {
        '& > p:first-of-type': {
          display: 'none',
        },
      },
    },
  }),
);
