import { HttpClient, createInstance } from '@shared/http';
import { getEnvValue } from '../../helpers';

const mieCatalogInstance = createInstance(
  {
    baseURL: `${getEnvValue('MIE_API_URL')}/catalog`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  true,
);

mieCatalogInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Basic YWRtaW5AYWRtaW4uY29tOlp6ISYtVUYjOC0jQkp4Kzc=`; // it is publicly visible and that's ok for /catalog
  return config;
});

export const mieCatalogClient = new HttpClient(mieCatalogInstance);
