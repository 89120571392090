import { TranslationLabels } from '@generated/translation-labels';
import { MieComplaintRequestType } from '@shared/http/mie/api/ticket/mie.type';
import { FieldConfig } from '@shared/FormV2/types';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { AttachmentValue } from '../../../shared/components/Fields';

export type Values = {
  summary: string;
  description: string;
  attachment: AttachmentValue[];
  visibility: 'public' | 'private';
  type: MieComplaintRequestType[];
};

export const getFields = (
  country: GenericTypes.Country,
): FieldConfig<Values>[] => {
  const complaintOptions: {
    label: GenericTypes.TranslationLabel;
    name: MieComplaintRequestType;
    country: string[];
  }[] = [
    {
      label: TranslationLabels.ticketingNeighborComplaintLabel,
      name: 'neighbour_complaint',
      country: ['pl', 'nl'],
    },
    {
      label: TranslationLabels.ticketingCleaningCommonAreaComplaintLabel,
      name: 'cleaning_common_area_complaint',
      country: ['pl', 'nl'],
    },
    {
      label: TranslationLabels.ticketingGreeneryComplaintLabel,
      name: 'greenery_complaint',
      country: ['pl', 'nl'],
    },
    {
      label: TranslationLabels.ticketingCustomerServiceComplaint,
      name: 'customer_service_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingCustomerServiceComplaintLabel,
      name: 'heimstaden_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingConstructionWorknoiseComplaintLabel,
      name: 'construction_work_noise_complaint',
      country: ['pl', 'nl'],
    },
    {
      label: TranslationLabels.ticketingPlSmokeOdorComplaintLabel,
      name: 'smoke_odor_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingGarbageComplaintLabel,
      name: 'garbage_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingPlBreakingHouseRulesComplaintLabel,
      name: 'breaking_house_rules_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingPlPetsComplaintLabel,
      name: 'pets_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingPlGeneralNoiseIssueComplaintLabel,
      name: 'general_noise_issue_complaint',
      country: ['pl'],
    },
    {
      label: TranslationLabels.ticketingOtherComplaintLabel,
      name: 'other',
      country: ['pl', 'nl'],
    },
  ];

  return [
    {
      componentType: 'input',
      field: {
        name: 'summary',
        initialValue: '',
      },
      props: {
        type: 'text',
        label: TranslationLabels.ticketingCustomerAccountSummaryLabel,
        required: true,
      },
    },
    {
      componentType: 'checkboxes',
      field: {
        name: 'type',
        initialValue: '',
      },
      props: {
        label: TranslationLabels.ticketingCustomerAccountCategoryLabel,
        options: complaintOptions
          .filter((option) => option.country.includes(country))
          .map(({ label, name }) => ({
            label,
            name,
          })),
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'description',
        initialValue: '',
      },
      props: {
        type: 'textarea',
        label: TranslationLabels.ticketingCustomerAccountDescriptionLabel,
        required: true,
      },
    },

    {
      componentType: 'attachment',
      field: {
        name: 'attachment',
        initialValue: [],
      },
      props: {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        // onPreviewClick$: ticketingClient.getAttachment$, // todo
      },
    },
    {
      componentType: 'switch',
      field: {
        name: 'visibility',
        initialValue: ServiceRequestVisibilityEnum.PUBLIC,
      },
      props: {
        label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
        checkedLabelKey:
          TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
        checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
        uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
        checkedValueDescriptionKey:
          TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
        helperTextKey:
          TranslationLabels.ticketingServiceRequestVisibilityHelperText,
      },
    },
  ];
};
