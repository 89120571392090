import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    comment: {
      border: 'none',
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '90%',
    },
    content: {
      padding: theme.spacing(2),
      '&:last-child': {
        padding: theme.spacing(2),
      },
    },
    incoming: {
      backgroundColor: fade(palette.black, 0.05),
      marginLeft: 0,
      marginRight: 'auto',
    },
    outgoing: {
      backgroundColor: fade(palette.internationalOrange, 0.1),
      marginLeft: 'auto',
      marginRight: 0,
    },
  }),
);
