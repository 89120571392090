import React, { FC } from 'react';
import { TranslationLabels } from '@generated/translation-labels';
import { useTranslation } from '@shared/translations';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './comments-title.styles';

type Props = {
  lettingAgent: {
    firstName: string;
    lastName: string;
    imageUrl?: GenericTypes.URL;
  };
};

export const CommentsTitle: FC<Props> = ({
  lettingAgent: { firstName, lastName, imageUrl },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item>
      <Typography className={classes.title} display="inline" variant="h2">
        {t(TranslationLabels.prospectCommentsDialogTitle)}
      </Typography>
      {imageUrl && (
        <img src={imageUrl} alt="letting agent" className={classes.avatar} />
      )}
      <Typography
        className={classes.title}
        color="primary"
        display="inline"
        variant="h2"
      >
        {`${firstName} ${lastName}`}
      </Typography>
    </Grid>
  );
};
