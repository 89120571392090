import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '@shared/translations';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useBreakpoint } from '@shared/hooks';
import { useLocation } from 'react-router';
import { useServiceRequestQuery } from '@shared/http/mie/api/ticket';
import { Spinner } from '@shared/components';
import { TicketType } from '@shared/http/mie/api/ticket/type';
import { paths } from '../../routing/paths-v2.const';
import { HomeButton } from '../HomeButton/home-button.component';
import { Content } from '../Content/content.component';
import { ReactComponent as SuccessImage } from '../../../assets/success.svg';
import { Stepper as StepperConfig } from '../../../types';

type Props = {
  stepperConfig?: StepperConfig;
};

export const Success: FC<Props> = ({ stepperConfig }) => {
  const { t } = useTranslation();
  const { isDown: isDownLg } = useBreakpoint('lg');
  const [ticketNumber, setTicketNumber] = useState('');

  const {
    state: { ticketId, type },
  } = useLocation<{ ticketId: string; type: TicketType }>();

  const { data, isSuccess, isLoading } = useServiceRequestQuery(ticketId, type);

  useEffect(() => {
    if (isSuccess) {
      setTicketNumber(data?.referenceNumber as string);
    }
  }, [data, isSuccess]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Content
      introProps={{
        titleKey: TranslationLabels.ticketingServiceRequestConfirmationHeader,
        descriptionKey:
          TranslationLabels.ticketingServiceRequestConfirmationFollowText,
        infoKey: TranslationLabels.ticketingServiceRequestConfirmationInfo,
        options: { ticketNumber },
        childrenRightColumn: isDownLg ? undefined : <SuccessImage />,
      }}
      isFetching={false}
      stepperConfig={stepperConfig}
      allStepsFinished
      type="columns"
    >
      <Grid container spacing={2}>
        <Grid item>
          <HomeButton />
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to={paths.LIST}
            variant="contained"
            color="primary"
            size="large"
          >
            {t(
              TranslationLabels.ticketingServiceRequestConfirmationButtonListLabel,
            )}
          </Button>
        </Grid>
      </Grid>
    </Content>
  );
};
