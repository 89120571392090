export const palette = {
  alabaster: '#F7F7F7',
  alto: '#DEDEDE',
  altoD3: '#D3D3D3',
  altoD6: '#D6D6D6',
  altoDD: '#DDDDDD',
  athensGray: '#F5F5F7',
  black: '#000000',
  blueRibbon: '#0050FF',
  cerise: '#C724B1',
  lightGray: '#C2C1C1',
  lighterGray: '#706F6F',
  doveGray: '#6A6A6A',
  dustyGray: '#989898',
  facebook: '#3B5998',
  greenHaze: '#009544',
  instagram: '#C13584',
  internationalOrange: '#FF5000',
  iron: '#E2E2E4',
  japaneseLaurel: '#008D0E',
  linkedin: '#0077B5',
  monza: '#DD0000',
  orangePeel: '#FF9800',
  pomegranate: '#F44336',
  scorpion: '#5C5C5C',
  shark: '#1D1D1F',
  silver: '#C6C6C6',
  white: '#FFFFFF',
  wildSand: '#F5F5F5',

  // new pallete colors
  darkGray100: '#333132',
  blue60: '#6696FF',
  blue10: '#E5EDFF',
  green10: '#E5F7EC',
  green80: '#33BF65',
  green100: '#00AF3F',
  orange10: '#FFEDE5',
  orange5: '#FFF6F2',
};
