import { Routes } from '@shared/routing';
import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  MyAccountTypes,
  InvoiceCustomerAccountItems,
  InvoiceCustomerAccountSubItems,
  InvoiceCustomerAccountForm,
  InvoiceCustomerAccountSuccess,
} from '../../MyAccount';
import {
  CustomerServiceTypes,
  GeneralRequestForm,
  IssueComplaintForm,
  CustomerServiceSuccess,
} from '../../CustomerService';
import { Requests } from '../../Requests/requests.component';
import {
  ServiceRequestAreas,
  ServiceRequestFailures,
  ServiceRequestForm,
  ServiceRequestSuccess,
  ServiceRequestItems,
  ServiceRequestIssues,
  ServiceRequestRooms,
  ServiceRequestSuggestion,
} from '../../ServiceRequest';
import { PATHS } from './paths.const';

export const routes: Routes = [
  {
    component: Requests,
    exact: true,
    path: PATHS.INDEX,
  },
  {
    component: ServiceRequestAreas,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREAS,
    step: 'localization',
  },
  {
    component: ServiceRequestRooms,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOMS,
    step: 'category',
  },
  {
    component: ServiceRequestForm,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_FORM,
    step: 'additional_info',
  },
  {
    component: ServiceRequestSuccess,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_SUCCESS,
  },
  {
    component: ServiceRequestIssues,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUES,
    step: 'category',
  },
  {
    component: ServiceRequestItems,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEMS,
    step: 'category',
  },
  {
    component: ServiceRequestForm,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FORM,
    step: 'additional_info',
  },
  {
    component: ServiceRequestSuccess,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_SUCCESS,
  },
  {
    component: ServiceRequestFailures,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURES,
    step: 'category',
  },
  {
    component: ServiceRequestSuggestion,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURE_SUGGESTION,
    step: 'additional_info',
  },
  {
    component: ServiceRequestForm,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURE_FORM,
    step: 'additional_info',
  },
  {
    component: ServiceRequestSuccess,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURE_SUCCESS,
  },
  {
    component: ServiceRequestItems,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEMS,
    step: 'category',
  },
  {
    component: ServiceRequestForm,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEM_FORM,
    step: 'additional_info',
  },
  {
    component: ServiceRequestSuccess,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEM_SUCCESS,
  },
  {
    component: ServiceRequestFailures,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEM_FAILURES,
    step: 'category',
  },
  {
    component: ServiceRequestSuggestion,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEM_FAILURE_SUGGESTION,
    step: 'additional_info',
  },
  {
    component: ServiceRequestForm,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEM_FAILURE_FORM,
    step: 'additional_info',
  },
  {
    component: ServiceRequestSuccess,
    exact: true,
    path: PATHS.SERVICE_REQUEST_AREA_ITEM_FAILURE_SUCCESS,
  },
  {
    component: MyAccountTypes,
    exact: true,
    path: PATHS.MY_ACCOUNT,
    step: 'category',
  },
  {
    component: InvoiceCustomerAccountItems,
    exact: true,
    path: PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS,
    step: 'category',
  },
  {
    component: InvoiceCustomerAccountSubItems,
    exact: true,
    path: PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_SUB_ITEMS,
    step: 'category',
  },
  {
    component: InvoiceCustomerAccountForm,
    exact: true,
    path: PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_FORM,
    step: 'additional_info',
  },
  {
    component: InvoiceCustomerAccountSuccess,
    exact: true,
    path: PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_SUCCESS,
  },
  {
    component: CustomerServiceTypes,
    exact: true,
    path: PATHS.CUSTOMER_SERVICE,
    step: 'category',
  },
  {
    component: IssueComplaintForm,
    exact: true,
    path: PATHS.CUSTOMER_SERVICE_ISSUE_COMPLAINT,
    step: 'additional_info',
  },
  {
    component: CustomerServiceSuccess,
    exact: true,
    path: PATHS.CUSTOMER_SERVICE_ISSUE_COMPLAINT_SUCCESS,
  },
  {
    component: GeneralRequestForm,
    exact: true,
    path: PATHS.CUSTOMER_SERVICE_GENERAL_REQUEST,
    step: 'additional_info',
  },
  {
    component: CustomerServiceSuccess,
    exact: true,
    path: PATHS.CUSTOMER_SERVICE_GENERAL_REQUEST_SUCCESS,
  },
  {
    path: '*',
    component: () => <Redirect to={PATHS.INDEX} />,
  },
];
