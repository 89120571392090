import { AvailableFor, Container, Logo } from '@shared/components';
import React, { FC } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { UserGlobalRole } from '@shared/enums';
import { HeaderSearchBox } from '../HeaderSearchBox/header-search-box.component';
import { LanguageSwitcher } from '../LanguageSwitcher/language-switcher.component';
import { Menu } from '../Menu/menu.component';
import { useStyles } from './header.styles';
import { useAuth } from '../../../+auth';

export const Header: FC = () => {
  const classes = useStyles();
  const { auth } = useAuth();

  return (
    <header className={classes.header}>
      <Container>
        <div className={classes.wrapper}>
          <Logo />
          {auth?.isUserVerified && !auth?.isUserInvalid && (
            <>
              <AvailableFor availableForRoles={[UserGlobalRole.CUSTOMER]}>
                <Hidden smDown>
                  <HeaderSearchBox />
                </Hidden>
              </AvailableFor>
              <div className={classes.itemsWrapper}>
                <Menu />
                <LanguageSwitcher />
              </div>
            </>
          )}
        </div>
      </Container>
    </header>
  );
};
