import { getEnvValue } from '../../helpers';
import { HttpClient } from '../http.client';
import { createInstance } from '../http.instance';
import { getAuthTokens } from '../../../+auth/auth.helpers';
import { RefreshTokenResponse } from './mie.types';
import { AuthTokens } from '../../../+auth/auth.types';

const mieInstance = createInstance(
  {
    baseURL: getEnvValue('MIE_API_URL'),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  true,
);

mieInstance.interceptors.request.use((config) => {
  const authTokens = getAuthTokens();

  if (authTokens) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${authTokens.accessToken}`;
  }

  return config;
});

mieInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    if (error.response?.status === 401 && !originalRequest._renewed) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._renewed = true; // try to refresh the token only at first 401 occurrence

      const authTokens = getAuthTokens();
      if (!authTokens) {
        return Promise.reject(error);
      }

      const response = await mieInstance.get<RefreshTokenResponse>(
        '/auth/refresh',
        {
          params: {
            refresh_token: authTokens.refreshToken,
            id_token: authTokens.idToken,
          },
        },
      );

      const newTokens: AuthTokens = {
        accessToken: response.data.accessToken,
        idToken: response.data.idToken,
        refreshToken: authTokens.refreshToken,
      };

      window.localStorage.setItem('authTokens', JSON.stringify(newTokens));

      return mieInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export const mieClient = new HttpClient(mieInstance);
