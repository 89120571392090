import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { roomsPathSuffix } from '../../shared';
import { Area } from './areas.type';

const areaCommonQuery = `
  "buttonLabelKey": buttonLabel->key,
  "id": typeId,
  "imageURL": image{asset->{url}}.asset.url,
  "textKey": text->key,
  "titleKey": title->key,
`;
const getQuery = (path: string): string => `
  * [_type == $type && content.country == $country][0]{
    ...content.ticketing.repairMaintenanceRequest{
      "areas": [
        areas.unitV2{
          ${areaCommonQuery}
          "redirectURL": "${path}/" + string(typeId) + "${roomsPathSuffix}",
        },
        areas.commonV2{
          ${areaCommonQuery}
          "redirectURL": "${path}/" + string(typeId) + "${roomsPathSuffix}",
        },
        areas.outdoorV2{
          ${areaCommonQuery}
          "redirectURL": "${path}/" + string(typeId) + "${roomsPathSuffix}",
        },
        areas.privateOutdoorV2{
          ${areaCommonQuery}
          "redirectURL": "${path}/" + string(typeId) + "${roomsPathSuffix}",
        },
      ]
    }
  }
`;

export const useAreasQuery = (
  country: GenericTypes.Country,
  path: string,
): UseQueryResult<{ areas: Area[] }> => {
  return useQuery<{ areas: Area[] }>(
    ['serviceRequestAreas', country],
    () =>
      sanityClient.fetch(getQuery(path), {
        country,
        type: SanityDocumentType.SETTINGS,
        path,
      }),
    { staleTime: Infinity },
  );
};
