import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    image: {
      width: '100%',
      height: '220px',
      objectFit: 'cover',
      borderRadius: theme.spacing(1),
    },
    imagePlaceholder: {
      width: '100%',
      height: '220px',
      borderRadius: theme.spacing(1),
      background: palette.wildSand,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imagePlaceholderIcon: {
      width: '112px',
      height: '112px',
      color: palette.lightGray,
    },
    listItemIcon: {
      width: '24px',
      height: '24px',
      color: palette.internationalOrange,
      marginRight: theme.spacing(2),
    },
    name: {
      margin: theme.spacing(3, 0, 2, 0),
    },
    listItem: {
      margin: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center',
    },
    listItemText: {
      margin: 0,
    },
    buttonWrapper: {
      textAlign: 'right',
      marginTop: theme.spacing(4),
    },
    bottom: {
      textAlign: 'right',
      margin: theme.spacing(4, 0, 3, 0),
    },
    advertisementPageText: {
      marginBottom: theme.spacing(2),
    },
  }),
);
