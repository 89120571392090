import { format, formatDistanceToNow, parseISO } from 'date-fns';
import * as timeago from 'timeago.js';

// Import locales to be used in timeago.js
// English is already imported by default
import localeDA from 'timeago.js/lib/lang/da';
import localeNO from 'timeago.js/lib/lang/nb_NO';

// Register locales to be used
timeago.register('da', localeDA);
timeago.register('no', localeNO);

export const formatDate = (date: string): string =>
  format(new Date(date), 'PPPp');
export const getDayNameOfTheWeek = (date?: string): string => {
  const d = date ? new Date(date) : new Date();

  return format(d, 'iiii');
};
export const getDateDistance = (date: string): string =>
  formatDistanceToNow(new Date(date), {
    addSuffix: true,
    includeSeconds: true,
  });

export const formatStandardDate = (dateISO: string): string =>
  format(parseISO(dateISO), 'dd.MM.yyyy');

export const formatStandardDateTime = (dateISO: GenericTypes.DateISO): string =>
  format(parseISO(dateISO), 'dd MMMM yyyy, HH:mm');

export const formatDayMonthYearDate = (
  dateISO: GenericTypes.DateISO,
): string => {
  return format(parseISO(dateISO), 'dd-MM-yyyy');
};

export const formatPaymentDate = (date: string): string =>
  format(new Date(date), 'do, MMMM');

export const dateIsoToTimeAgo = (
  date: GenericTypes.DateISO,
  locale: GenericTypes.Language = 'en',
): string => {
  return timeago.format(date, locale);
};

export const getDateTime = (date: Date | string): string =>
  format(new Date(date), 'H:mm'); // 15:52
