import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Spinner, HeaderSection } from '@shared/components';
import { useCountry } from '@shared/hooks';
import { getEnvValue } from '@shared/helpers';
import { palette } from '@shared/consts';
import { useTranslation } from '@shared/translations';
import { useStyles } from './instagram.styles';
import { SocialMediaCircle } from './social-media-circle.component';
import { ImageLink } from './image-link.component';
import { ReactComponent as InstagramIcon } from './assets/instagram-icon.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook-icon.svg';
import { ReactComponent as LinkedinIcon } from './assets/linkedin-icon.svg';
import { useGetMediaListQuery } from './instagram.hook';

export const Instagram: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const {
    data: mediaList,
    isLoading: isFetchingMediaList,
    isError,
  } = useGetMediaListQuery(country);
  const instagramUrl = getEnvValue(`INSTAGRAM_URL_${country.toUpperCase()}`);
  const facebookUrl = getEnvValue(`FACEBOOK_URL_${country.toUpperCase()}`);
  const linkedinUrl = getEnvValue(`LINKEDIN_URL_${country.toUpperCase()}`);

  if (isFetchingMediaList) {
    return <Spinner />;
  }

  if (isError || !mediaList || !mediaList.length) {
    return null;
  }

  return (
    <div>
      <HeaderSection htmlText={t(TranslationLabels.instagramSectionTitle)}>
        <div className={classes.socialMedia}>
          <SocialMediaCircle
            icon={InstagramIcon}
            colorHex={palette.instagram}
            href={instagramUrl}
          />
          <SocialMediaCircle
            icon={FacebookIcon}
            colorHex={palette.facebook}
            href={facebookUrl}
          />
          <SocialMediaCircle
            icon={LinkedinIcon}
            colorHex={palette.linkedin}
            href={linkedinUrl}
          />
        </div>
      </HeaderSection>
      <Grid container spacing={2}>
        {mediaList.map(
          ({ id, mediaType, mediaUrl, permalink, thumbnailUrl }) => (
            <Grid item md={3} xs={3} key={id}>
              <ImageLink
                href={permalink}
                imageUrl={mediaType === 'VIDEO' ? thumbnailUrl || '' : mediaUrl}
              />
            </Grid>
          ),
        )}
      </Grid>
    </div>
  );
};
