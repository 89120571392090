import { TranslationLabels } from '@generated/translation-labels';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { UseQueryResult } from 'react-query';
import clsx from 'clsx';
import React, {
  ChangeEvent,
  createRef,
  FC,
  Key,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ArrowThickCircleRight2Icon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-thick-circle-right-2.svg';
import { Spinner } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { useCountry, useDebounce, useOnClickOutside } from '../../hooks';
import { Icon } from '../icons';
import { useStyles } from './search-box.styles';

type CallbackResponse<T> = (T & { key: Key })[];

type Props<T> = {
  callbackQuery: (
    country: GenericTypes.Country,
    isTenant: boolean,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ) => UseQueryResult<CallbackResponse<T>>;
  getElementTemplate: (
    className: string,
    data: T,
    handleClick: () => void,
  ) => ReactNode;
  placeholderKey: string;
  iconTemplate?: JSX.Element;
};

export function SearchBox<T>(props: Props<T>): ReturnType<FC<Props<T>>> {
  const classes = useStyles();
  const inputRef = createRef<HTMLInputElement>();
  const {
    callbackQuery,
    getElementTemplate,
    iconTemplate,
    placeholderKey,
  } = props;
  const { t, langCode } = useTranslation();
  const country = useCountry();
  const ref = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);
  const debouncedQuery = useDebounce(query);
  const sanityLangCode = useMemo<GenericTypes.SanityLanguageKey>(
    () => (langCode === 'en' ? 'en' : 'native'),
    [langCode],
  );
  const isTenant = true;
  const { data: suggestions, isLoading, isError } = callbackQuery(
    country,
    isTenant,
    sanityLangCode,
    debouncedQuery,
  );
  useEffect(() => {
    if (debouncedQuery) {
      setOpen(true);
    }
  }, [debouncedQuery]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setOpen(false);
    setQuery(event.currentTarget.value);
  };
  const handleSuggestionClick = (): void => {
    setQuery('');
    setOpen(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [debouncedQuery, inputRef]);

  useOnClickOutside(ref, () => {
    setOpen(false);
    setQuery('');
  });

  if (isError) {
    return null;
  }

  return (
    <div className={classes.searchBox}>
      <TextField
        disabled={isLoading}
        onChange={handleChange}
        value={query}
        placeholder={t(placeholderKey)}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.input,
          },
        }}
        inputRef={inputRef}
        className={classes.inputWrapper}
      />
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <Spinner size={24} />
        </div>
      ) : (
        <IconButton className={classes.button}>
          {iconTemplate || (
            <Icon
              className={classes.icon}
              icon={ArrowThickCircleRight2Icon}
              height={32}
              width={32}
            />
          )}
        </IconButton>
      )}
      {isOpen && (
        <div ref={ref} className={classes.resultsContainer}>
          <ul className={classes.results}>
            {suggestions && suggestions.length > 0 ? (
              suggestions.map((suggestion) => (
                <li key={suggestion.key}>
                  {getElementTemplate(
                    classes.element,
                    suggestion,
                    handleSuggestionClick,
                  )}
                </li>
              ))
            ) : (
              <li>
                <Typography className={clsx(classes.element, classes.notFound)}>
                  {t(TranslationLabels.searchElementNotFound)}
                </Typography>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
