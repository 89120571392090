import { TranslationLabels } from '@generated/translation-labels';
import * as Sentry from '@sentry/react';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { PLACEHOLDER } from '@shared/consts';
import { Box, Spinner } from '@shared/components';
import { formatDayMonthYearDate } from '@shared/helpers';
import { useTranslation } from '@shared/translations';
import { useUnitQuery } from '@shared/http/mie';
import { useParams } from 'react-router-dom';
import { useStyles } from './details.styles';
import { Item } from './item.component';

export const Details: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data: propertyUnit, isError, error, isLoading } = useUnitQuery(id);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !propertyUnit) {
    Sentry.captureException(error);
    return null;
  }

  const {
    address,
    city,
    postalCode,
    leaseStartDate,
    leaseEndDate,
    unitNumber,
    streetNumber,
  } = propertyUnit;

  return (
    <Box className={classes.container}>
      <Typography variant="h2">
        {t(TranslationLabels.yourApartmentTitle)}
      </Typography>
      <Typography>{t(TranslationLabels.yourApartmentText)}</Typography>
      <Item
        label={t(TranslationLabels.yourApartmentAddressLabel)}
        text={`${address} ${streetNumber} ${unitNumber}`}
      />
      <Item
        label={t(TranslationLabels.yourApartmentPostalCodeLabel)}
        text={`${postalCode} ${city.name}`}
      />
      <Item
        label={t(TranslationLabels.yourApartmentLeaseStartDateLabel)}
        text={
          leaseStartDate ? formatDayMonthYearDate(leaseStartDate) : PLACEHOLDER
        }
      />
      {leaseEndDate && (
        <Item
          label={t(TranslationLabels.yourApartmentLeaseEndDateLabel)}
          text={formatDayMonthYearDate(leaseEndDate)}
        />
      )}
    </Box>
  );
};
