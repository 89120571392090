import clsx from 'clsx';
import React, { FC, SVGProps } from 'react';
import { useStyles } from './icon.styles';

type Props = Pick<
  SVGProps<SVGSVGElement>,
  'className' | 'height' | 'width' | 'color'
> & {
  icon: FC<SVGProps<SVGSVGElement>>;
  strokeWidth?: number;
  stroke?: string;
};

export const Icon: FC<Props> = ({
  className,
  icon: Icon,
  strokeWidth = 1,
  stroke = 'currentColor',
  ...props
}) => {
  const classes = useStyles({ strokeWidth });

  return (
    <Icon
      {...props}
      strokeWidth={strokeWidth}
      className={clsx(classes.icon, className)}
      // Due to an issue with blank icons: https://github.com/webalys-hq/streamlinehq-npm/issues/44
      stroke={stroke}
    />
  );
};
