import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginBottom: 0,
      fontWeight: 'bold',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%',
      margin: theme.spacing(0, 1),
      verticalAlign: 'bottom',
    },
  }),
);
