import { useQuery, UseQueryResult } from 'react-query';
import { catalogApi } from '@shared/http/mie';
import * as Sentry from '@sentry/react';
import { UnitNearby } from '../../../../../+prospects/types';

export const useMyAvailableUnitsNearbyQuery = (
  unitsIds: string[],
  limit: number,
): UseQueryResult<UnitNearby[]> => {
  return useQuery<UnitNearby[]>(
    ['availableUnitsNearby', unitsIds, limit],
    async () => {
      if (!unitsIds || unitsIds.length === 0) {
        return [];
      }

      let foundUnitsCount = 0;
      let unitIdsIdx = 0;
      const results: UnitNearby[] = [];

      do {
        try {
          const id = unitsIds[unitIdsIdx];
          // eslint-disable-next-line no-await-in-loop
          const response = await catalogApi.getAvailableUnitsNearby(id, {
            page: 1,
            // pageSize: limit - foundUnitsCount,
            pageSize: 10,
          });
          // MYH-1562: for now support only OLX integration
          response.results.forEach((r) => {
            if (
              (r.advertisementUrls || []).filter(
                (a) => a.integration === 'olx',
              )[0]?.url
            ) {
              results.push(r);
            }
          });
          foundUnitsCount += results.length;
        } catch (err) {
          Sentry.captureException(err);
        }

        unitIdsIdx += 1;
      } while (foundUnitsCount < limit + 1 && unitIdsIdx < unitsIds.length);

      return results.slice(0, limit);
    },
    { keepPreviousData: true },
  );
};
