import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import i18next from 'i18next';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { name, version } from '../package.json';
import { App } from './app/app.component';
import { getEnvValue, theme } from './app/shared';
import './index.css';
import './animated-routes.css';
import { AuthProvider } from './app/+auth';

const environment = getEnvValue('ENV_NAME');
const sentryDsn = getEnvValue('SENTRY_DSN');
const appVersion = getEnvValue('VERSION');

const history = createBrowserHistory();

// eslint-disable-next-line no-console
console.info(`App version: ${appVersion}`);

if (environment && sentryDsn) {
  const tracesSampleRate = getEnvValue('SENTRY_TRACES_SAMPLE_RATE');

  Sentry.init({
    dsn: sentryDsn,
    environment,
    integrations: [new Integrations.BrowserTracing()],
    release: `${name}@${version}`,
    tracesSampleRate: tracesSampleRate ? Number(tracesSampleRate) : undefined,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </Router>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
