import { MieNotification, Notification } from './notifications.type';

export const adaptNotification = (
  notification: MieNotification,
): Notification => ({
  id: notification.uuid,
  name: notification.name,
  body: notification.body,
  status: notification.status,
  sentAt: notification.sentAt,
  recipientId: notification.recipientUuid,
});
