import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialogWindow: {
      padding: theme.spacing(5),
    },
    dialogTtle: {
      padding: theme.spacing(1),
    },
    dialogContent: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    dialogActions: {
      padding: 0,
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    updateButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginTop: theme.spacing(1),
      },
    },
    acceptButton: {
      border: 0,
      '&:disabled': {
        color: palette.white,
        backgroundColor: palette.altoD6,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1),
      },
    },
    field: {
      marginTop: theme.spacing(2),
    },
  }),
);
