import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { useCountry } from '@shared/hooks';

export const useAdvertisementPageUrlQuery = (): UseQueryResult<
  GenericTypes.URL | undefined
> => {
  const country = useCountry();
  return useQuery<GenericTypes.URL | undefined>(
    ['advertisementPageUrl', country],
    async () => {
      const result = await sanityClient.fetch<{ url: GenericTypes.URL | null }>(
        `
        * [_type == $type && content.country == $country]{
          "url": content.advertisementPageUrl,
        }[0]
        `,
        {
          country,
          type: SanityDocumentType.SETTINGS,
        },
      );

      return result?.url || undefined;
    },
    { keepPreviousData: true, staleTime: Infinity },
  );
};
