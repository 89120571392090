import React, { FC, useCallback } from 'react';
import { Comments } from '@shared/components/Comments/comments.component';
import * as Sentry from '@sentry/react';
import { Comment } from '@shared/http/mie/api/ticket/type';
import {
  useProspectCommentMutation,
  useProspectCommentsQuery,
} from '@shared/http/mie/api/units/units.hook';
import { Comment as CommentType } from '../../../../shared/components/Comments/comments.type';
import { useProfileQuery } from '../../../../shared/http/mie/api/auth/auth.hook';
import { CommentsTitle } from './comments-title.component';

type Props = {
  prospectId: string;
  lettingAgent: {
    id: string;
    firstName: string;
    lastName: string;
    imageUrl?: GenericTypes.URL;
  };
};

export const ProspectComments: FC<Props> = ({ prospectId, lettingAgent }) => {
  const postComment = useProspectCommentMutation(prospectId);
  const { data: profileData } = useProfileQuery();
  const handleSubmit = useCallback(
    async (content: string): Promise<void> => {
      try {
        await postComment.mutate({
          comment: { content },
          prospectId,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [prospectId, postComment],
  );

  const transformComment = useCallback(
    (commentItem: Comment): CommentType => ({
      date: commentItem.date,
      message: commentItem.message,
      isAuthor: profileData?.uuid === commentItem.author,
    }),
    [profileData?.uuid],
  );

  return (
    <Comments<Comment>
      entityId={prospectId}
      onSubmitComment={handleSubmit}
      useCommentsQuery={useProspectCommentsQuery}
      transformComment={transformComment}
      titleComponent={<CommentsTitle lettingAgent={lettingAgent} />}
      small
    />
  );
};
