import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import { useTranslation } from '@shared/translations';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const HomeButton: FC = () => {
  const { t } = useTranslation();

  return (
    <Button
      component={Link}
      to="/"
      variant="outlined"
      color="primary"
      size="large"
    >
      {t(TranslationLabels.ticketingServiceRequestConfirmationButtonHomeLabel)}
    </Button>
  );
};
