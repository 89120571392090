import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import * as Sentry from '@sentry/react';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Content, Elements } from '../../shared';
import { RouteParams } from '../service-request.type';
import { useIssuesQuery } from './issues.repository';
import { stepper } from '../stepper.config';

export const Issues: FC = () => {
  const country = useCountry();
  const { areaId, roomId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const { isFetching, isError, error, data: issuesData } = useIssuesQuery(
    country,
    areaId,
    pathname,
    roomId,
  );

  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestItemsText,
        titleKey: TranslationLabels.ticketingServiceRequestItemsTitle,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
    >
      <Elements
        data={issuesData?.issues}
        otherElementRedirectURL={issuesData?.otherElementRedirectURL}
        dataTestPrefix="issues"
      />
    </Content>
  );
};
