import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import React, { FC, useMemo } from 'react';
import { Content, Elements } from '../../shared';
import { transformTypes } from './types.helper';
import { ReactComponent as AreasImage } from '../../assets/areas.svg';
import { stepper } from '../stepper.config';
import { useGetTicketingMyAccountTypesQuery } from './types.hook';

export const Types: FC = () => {
  const country = useCountry();
  const { data: types, isLoading } = useGetTicketingMyAccountTypesQuery(
    country,
  );
  const transformedTypes = useMemo(() => (types ? transformTypes(types) : {}), [
    types,
  ]);

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
        childrenRightColumn: <AreasImage />,
      }}
      isFetching={isLoading}
      type="columns"
      stepperConfig={stepper}
    >
      <Elements data={transformedTypes} dataTestPrefix="types" />
    </Content>
  );
};
