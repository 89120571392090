import { Values } from '../../../../../+profile/shared/components/Details/details.model';
import { AttachmentValue } from '../../../../components/Fields';
import { CreateGeneralRequestPayload } from './mie.type';
import {
  MieComment,
  MieTicket,
  PatchServiceRequestAPIParams,
  Ticket,
  Comment,
} from './type';

export const adaptTicket = (ticket: MieTicket): Ticket => {
  return {
    id: ticket.uuid,
    description: ticket.description,
    summary: ticket.summary,
    createdDate: ticket.createdAt,
    status: ticket.status,
    ticketType: ticket.ticketType,
    issueType: ticket.issueType,
    roomWithIssue: ticket.roomWithIssue,
    issueSubtype: ticket.issueSubtype,
    issueLocation: ticket.issueLocation,
    visibility: ticket.visibility,
    address: ticket.addressLine,
    apartmentNumber: ticket.apartmentNumber,
    referenceNumber: ticket.referenceNumber,
    entityType: ticket.entityType,
    damagedItem: ticket.damagedItem,
    reporter: ticket.reporter,
  };
};

export const transformTicketForUpdate = (
  ticket: Partial<Ticket>,
): PatchServiceRequestAPIParams => {
  return {
    uuid: ticket.id,
    description: ticket.description,
    summary: ticket.summary,
    visibility: ticket.visibility,
  };
};

export const transformAttachmentsForUpdate = (
  attachment: AttachmentValue,
): FormData => {
  const data = new FormData();

  if (attachment.value?.type !== 'application/pdf') {
    data.append('attachment_category', 'image');
    data.append('attachment_type', 'gallery');
  } else {
    data.append('attachment_category', 'document');
    data.append('attachment_type', 'pdf');
  }

  if (attachment.value) {
    data.append('files', attachment.value);
  }
  return data;
};

export const adaptComment = (comment: MieComment): Comment => {
  return {
    id: comment.uuid,
    date: comment.createdAt,
    message: comment.content,
    visibility: comment.visibility,
    author: comment.author.uuid,
  };
};

export const transformProfileToGeneralRequest = (
  profile: Values,
  userId: string,
): CreateGeneralRequestPayload => {
  return {
    summary: '-',
    description: JSON.stringify({
      phone: profile.phoneNumber,
      email: profile.email,
    }),
    entity_uuid: userId,
    entity_type: 'user',
    visibility: 'public',
  };
};
