import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';
import background from './assets/background.png';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginBottom: theme.spacing(14),
    },
    background: {
      position: 'absolute',
      width: '100%',
      top: theme.spacing(26.5),
      bottom: theme.spacing(-9.5),
      background: `url(${background}), ${palette.orange5}`,
      opacity: 0.05,
      borderRadius: theme.spacing(1),
    },
    box: {
      position: 'relative',
      border: `${palette.internationalOrange} solid 3px`,
      borderRadius: 8,
      padding: theme.spacing(5, 6, 6),
      margin: theme.spacing(0, 13.25),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 7),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 1),
        padding: theme.spacing(5, 2, 6),
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        textAlign: 'center',
        '& svg': {
          marginRight: 0,
        },
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(32),
      color: palette.internationalOrange,
      fontWeight: 'bold',
    },
    subTitle: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      color: palette.darkGray100,
    },
    infoSection: {
      margin: theme.spacing(6, 0, 6),
    },
    infoSectionWrapper: {
      padding: theme.spacing(0, 13),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 7),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
    infoSectionTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    infoSectionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 'bold',
    },
    infoSectionDescription: {
      fontSize: theme.typography.pxToRem(18),
      color: palette.darkGray100,
      '& ul': {
        listStyle: 'inside',
      },
    },
    icon: {
      '& path': {
        stroke: palette.internationalOrange,
      },
      marginRight: theme.spacing(2),
    },
    line: {
      backgroundColor: palette.lightGray,
      height: 2,
      border: 0,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    dashedLine: {
      border: 0,
      borderTop: `${palette.lightGray} dashed 2px`,
    },
  }),
);
