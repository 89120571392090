import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { TICKETING_PATHS } from '../../shared';
import { Types } from './type';

export const useGetTypesQuery = (
  country: GenericTypes.Country,
): UseQueryResult<Types | undefined> => {
  return useQuery<Types | undefined>(['getTicketingTypes', country], () => {
    if (!country) return undefined;
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.customerServiceRequest.types{
          generalRequest{
            "redirectURL": "${TICKETING_PATHS.CUSTOMER_SERVICE_GENERAL_REQUEST}",
            "titleKey": title->key,
          },
          issueComplaint{
            "redirectURL": "${TICKETING_PATHS.CUSTOMER_SERVICE_ISSUE_COMPLAINT}",
            "titleKey": title->key,
          }
        }
      }
    `;

    return sanityClient.fetch<Types>(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });
  });
};
