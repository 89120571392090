import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType, SanityImage } from '@shared/sanity';

export const useGetImageQuery = (
  route: string,
  country: GenericTypes.Country,
): UseQueryResult<SanityImage | undefined> => {
  return useQuery<SanityImage | undefined>(
    ['getPageIntroImage', route, country],
    () => {
      if (!route || !country) return undefined;
      return sanityClient
        .fetch<{ image: SanityImage }>(
          `* [_type == $type && country == $country && route == $route][0]{
          image
        }`,
          { route, country, type: SanityDocumentType.PAGE },
        )
        .then((result) => result.image);
    },
  );
};
