import React, { FC, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import MUIBox from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './section.styles';

type Props = {
  title?: string;
  children: ReactNode;
};

export const Section: FC<Props> = ({ title = '', children }) => {
  const classes = useStyles();

  return (
    <MUIBox className={classes.section}>
      <Typography className={classes.label}>{title}</Typography>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </MUIBox>
  );
};
