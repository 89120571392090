/* eslint-disable camelcase */
import { NotificationPermissions } from '../../../../../+profile/types';
import {
  NotificationConfiguration,
  NotificationConfigurationAPIParams,
  PatchProfileAPIParams,
  PatchProfileParams,
} from './auth.type';

export const transformProfile = (
  profile: PatchProfileParams,
): PatchProfileAPIParams => ({
  email: profile.email,
  first_name: profile.firstName,
  last_name: profile.lastName,
  phone_number: profile.phoneNumber,
  personal_details_confirmed: profile.personalDetailsConfirmed,
});

export const adaptNotificationConfiguration = (
  notificationConfiguration: NotificationConfiguration,
): NotificationPermissions => ({
  sms: notificationConfiguration.isSmsEnabled,
  email: notificationConfiguration.isEmailEnabled,
});

export const transformNotificationPermissions = (
  notificationPermissions: NotificationPermissions,
): NotificationConfigurationAPIParams => ({
  is_sms_enabled: notificationPermissions.sms,
  is_email_enabled: notificationPermissions.email,
});
