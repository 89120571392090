import { TFunction } from 'i18next';
import { TranslationLabels } from '@generated/translation-labels';

type Unit = {
  address?: string;
  streetNumber?: string;
  unitNumber?: string;
};

export const renderUnitAddress = (unit: Unit, t: TFunction): string => {
  const unitNumber = unit.unitNumber
    ? `${t(TranslationLabels.apartmentNumberPrefix)} ${unit.unitNumber}`
    : undefined;

  return [unit.address, unit.streetNumber, unitNumber]
    .filter((i) => i)
    .join(' ');
};
