import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';

export const useGetAppLogoQuery = (
  country: GenericTypes.Country,
): UseQueryResult<GenericTypes.URL | undefined> => {
  return useQuery<GenericTypes.URL | undefined>(['getAppLogo', country], () => {
    if (!country) return undefined;
    return sanityClient
      .fetch<{ appLogoUrl: GenericTypes.URL }>(
        `
      *[_type == $type && content.country == $country]{
        "appLogoUrl": content.appLogo.asset->url,
      }[0]
      `,
        {
          type: SanityDocumentType.SETTINGS,
          country,
        },
      )
      .then((response) => response.appLogoUrl);
  });
};
