import * as Sentry from '@sentry/react';
import { Spinner } from '@shared/components';
import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { api } from '../auth.repository';
import { Context } from './context';
import { initialState, reducer } from './reducer';
import { Auth0IdToken } from '../auth.types';
import { getAuthTokens } from '../auth.helpers';
import { useAuth } from '../hooks';

export const Provider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isFetching, setFetching] = useState(true);
  const { logout } = useAuth();

  const authorize = useCallback(async () => {
    try {
      setFetching(true);

      const authTokens = getAuthTokens();
      if (!authTokens) return;

      // auth info from ID token
      const idToken = jwtDecode<Auth0IdToken>(authTokens.idToken);
      const isUserVerified = Boolean(
        idToken['http://user.namespace/email_verified'] ||
          idToken['http://user.namespace/phone_number_verified'],
      );
      const country = idToken[
        'http://user.namespace/country'
      ].toLowerCase() as GenericTypes.Country;
      const isUserInvalid = Boolean(
        idToken['http://user.namespace/invalid_user'],
      );

      let user;
      if (isUserVerified && !isUserInvalid) {
        try {
          user = await api.getMe();
        } catch (err) {
          if (err.status === 401) logout(); // clear auth0 session
        }
      }

      dispatch({
        type: 'AUTH_INIT',
        payload: {
          user,
          auth: {
            country,
            isUserVerified,
            isUserInvalid: Boolean(
              idToken['http://user.namespace/invalid_user'],
            ),
          },
        },
      });
      Sentry.setUser(user || null);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setFetching(false);
    }
  }, [logout]);

  useEffect(() => {
    (async () => {
      await authorize();
    })();
  }, [authorize]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Context.Provider
      value={{
        ...state,
        authorize,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'AuthProvider';
