import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../hooks';

interface Props {
  children?: ReactNode;
}

const GuestGuard: FC<Props> = ({ children }) => {
  const { user } = useAuth();

  if (user) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};
export default GuestGuard;

GuestGuard.propTypes = {
  children: PropTypes.node,
};
GuestGuard.defaultProps = {
  children: undefined,
};
