import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';
import background from './bg.svg';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      background: `url(${background}) center bottom`,
      backgroundRepeat: 'repeat-x',
    },
    container: {
      maxWidth: '1440px',
      margin: theme.spacing(0, 'auto', 10, 'auto'),
      padding: theme.spacing(0, 25),
      background:
        'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%)',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 'auto', 10, 'auto'),

        padding: theme.spacing(0, 10),
      },
    },
    content: {
      paddingRight: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    headline: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      fontSize: theme.typography.pxToRem(40),
      lineHeight: 'inherit',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(32),
      },
    },
    paragraph: {
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    buttonWrapper: {
      margin: theme.spacing(5, 0, 0, 0),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginRight: 0,
      },
    },
    contactInfo: {
      minWidth: '250px',
      border: `1px solid ${palette.altoD6}`,
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        minWidth: 'auto',
        marginTop: theme.spacing(2),
      },
    },
  }),
);
