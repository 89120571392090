import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormV2 } from '@shared/FormV2';
import { useTranslation } from '@shared/translations';
import { Dialog } from '@shared/Dialog';
import { DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import {
  useProfileQuery,
  useProfileMutation,
} from '@shared/http/mie/api/auth/auth.hook';
import * as Sentry from '@sentry/react';
import { getConfig, getFields, Values } from './details-confirmation.model';
import { useStyles } from './details-confirmation-dialog.styles';
import { FormField } from '../../FormV2/components/form-field.component';

export const DetailsConfirmationDialog: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isError, error, data: profileData } = useProfileQuery();
  const [isOpen, setOpen] = useState(!profileData?.personalDetailsConfirmed);
  const updateProfile = useProfileMutation();
  const [isFormReadonly, setIsFormReadonly] = useState(true);

  const formConfig = useMemo(() => getConfig(updateProfile.mutateAsync), [
    updateProfile,
  ]);
  const fieldsConfig = useMemo(
    () => getFields(classes, profileData, isFormReadonly),
    [classes, profileData, isFormReadonly],
  );

  useEffect(() => {
    setOpen(!profileData?.personalDetailsConfirmed);
  }, [profileData]);

  if (isError || !profileData) {
    Sentry.captureException(error);
    return null;
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => null} fullWidth>
      <DialogTitle className={classes.dialogTtle}>
        <Typography variant="h2">
          <strong>
            {t(TranslationLabels.confirmProfileDetailsDialogTitle)}
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          {t(TranslationLabels.confirmProfileDetailsDialogDescription)}
        </Typography>
        <FormV2<Values>
          config={formConfig}
          fieldsConfig={fieldsConfig}
          showButtons={false}
        >
          {(formProps) => {
            return (
              <>
                <div>
                  {fieldsConfig.map((fieldConfig) => (
                    <FormField<Values>
                      key={fieldConfig.field.name}
                      fieldConfig={fieldConfig}
                      values={formProps.values}
                    />
                  ))}
                </div>
                <DialogActions className={classes.dialogActions}>
                  <Button
                    onClick={() => setIsFormReadonly(false)}
                    variant="outlined"
                    color="primary"
                    disabled={!isFormReadonly}
                    className={classes.updateButton}
                  >
                    {t(TranslationLabels.profileDetailsButtonLabel)}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    onClick={() => formProps.handleSubmit()}
                    className={classes.acceptButton}
                  >
                    {t(
                      TranslationLabels.confirmProfileDetailsAcceptButtonLabel,
                    )}
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </FormV2>
      </DialogContent>
    </Dialog>
  );
};
