import { useQuery, UseQueryResult } from 'react-query';
import { instagramListMediaClient } from '@shared/http';
import * as Sentry from '@sentry/react';
import { Media } from './instagram.type';

export const useGetMediaListQuery = (
  country: GenericTypes.Country,
): UseQueryResult<Media[] | undefined> => {
  return useQuery<Media[] | undefined>(
    ['getInstagramMediaList', country],
    async () => {
      if (!country) return undefined;

      const response = await instagramListMediaClient(country).get<Media[]>('');
      if (!Array.isArray(response)) {
        Sentry.captureException(response);
        return [];
      }
      return response;
    },
  );
};
