import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
    fluidContainer: {
      marginTop: 'auto',
      position: 'relative',
    },
    background: {
      position: 'absolute',
      bottom: theme.spacing(-2),
      left: 0,
      right: 0,
      zIndex: -1,
    },
  }),
);
