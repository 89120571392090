import {
  MieProspectPublicUnit,
  MieProspectUnit,
  MieUnit,
  ProspectPublicUnit,
  ProspectUnit,
  MieAttachment,
} from './units.type';
import { Unit, Attachment } from '../../../../../+apartment/apartment.type';

export const adaptUnit = (unit: MieUnit): Unit => {
  return {
    id: unit.uuid,
    name: unit.name,
    leaseStartDate: unit.leaseStartDate,
    leaseEndDate: unit.leaseEndDate,
    address: unit.addressLine,
    postalCode: unit.postalCode,
    images: unit.images,
    thumbnail: unit.thumbnail,
    unitNumber: unit.unitNumber,
    city: unit.city,
    myRoles: unit.myRoles,
    streetNumber: unit.streetNumber,
    propertyObjectId: unit.propertyObjectUuid,
    propertyId: unit.propertyUuid,
    ...(unit.contractPdf && { contractPdf: { url: unit.contractPdf?.url } }),
  };
};

export const adaptAttachment = (attachment: MieAttachment): Attachment => {
  return {
    id: attachment.uuid,
    url: attachment.url,
    title: attachment.title,
    name: attachment.name,
    type: attachment.type,
    contentType: attachment.contentType,
  };
};

export const adaptProspectUnit = (prospect: MieProspectUnit): ProspectUnit => {
  return {
    id: prospect.uuid,
    status: prospect.status,
    unit: {
      id: prospect.unit.uuid,
      address: prospect.unit.addressLine,
      streetNumber: prospect.unit.streetNumber,
      unitNumber: prospect.unit.unitNumber,
      city: prospect.unit.city,
    },
    lettingAgent: {
      id: prospect.lettingAgent.uuid,
      firstName: prospect.lettingAgent.firstName,
      lastName: prospect.lettingAgent.lastName,
      imageUrl: prospect.lettingAgent.profilePicture,
      email: prospect.lettingAgent.email,
    },
    agreedDatetimeForViewing: prospect.agreedDatetimeForViewing,
    prospectVerifications: prospect.prospectVerifications.map((v) => ({
      id: v.uuid,
      url: v.verificationUrl,
    })),
  };
};

export const adaptProspectPublicUnit = (
  publicProspect: MieProspectPublicUnit,
): ProspectPublicUnit => {
  return {
    id: publicProspect.uuid,
    address: publicProspect.unit.addressLine,
    streetNumber: publicProspect.unit.streetNumber,
    unitNumber: publicProspect.unit.unitNumber,
    city: publicProspect.unit.city,
    thumbnail: publicProspect.unit.thumbnail,
  };
};
