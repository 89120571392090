import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '@shared/translations';
import { Container } from '@shared/components';
import { paths } from '../../routing/paths-v2.const';
import { ListView } from '../ListView/list-view.component';
import { useStyles } from './list-widget.styles';
import { TICKETING_PATHS } from '../../index';

type Props = {
  limit: number;
};

export const ListWidget: FC<Props> = ({ limit }) => {
  const { pathname } = useLocation();
  const isIndexRoute = pathname === TICKETING_PATHS.INDEX;
  const classes = useStyles({ isIndexRoute });
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {t(TranslationLabels.ticketingListWidgetTitle)}
        </Typography>
        <Typography variant="h3" className={classes.subtitle}>
          {t(TranslationLabels.ticketingListWidgetText)}
        </Typography>
        <ListView limit={limit} />
        <div className={classes.buttonsRow}>
          <Button
            className={classes.allRequests}
            color="primary"
            component={Link}
            to={paths.LIST}
            variant="contained"
          >
            {t(TranslationLabels.ticketingListWidgetButtonLabel)}
          </Button>
        </div>
      </Container>
    </div>
  );
};
ListWidget.propTypes = {
  limit: PropTypes.number.isRequired,
};
