import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import {
  Icon,
  List as GenericList,
  Model,
  Status,
  useTranslation,
} from '@shared/index';
import { Ticket } from '@shared/http/mie/api/ticket/type';
import { TranslationLabels } from '@generated/translation-labels';
import {
  useTicketsQuery,
  useTicketStatusesQuery,
} from '@shared/http/mie/api/ticket';
import { paths } from '../../routing/paths-v2.const';
import { useStyles } from './list-view.styles';
import { isChangeUserDetailsRequest } from '../../../ticketing.helper';

type Props = {
  limit?: number;
};

const property = 'id';

export const ListView: FC<Props> = ({ limit }) => {
  const classes = useStyles();
  const { data: ticketStatuses } = useTicketStatusesQuery();
  const { t } = useTranslation();

  // TODO add proper icon after MYH-1023 completed
  // const getIcon = (
  //   data: ServiceRequest,
  // ): void => {
  //   }
  // };

  const model: Model<Ticket>[] = [
    {
      id: 'referenceNumber',
      label: '',
      getContent: ({ referenceNumber }): ReactNode =>
        referenceNumber && (
          <Typography component="strong">{referenceNumber}</Typography>
        ),
      width: '20%',
    },
    {
      id: 'summary',
      label: '',
      getContent: (ticket): ReactNode => {
        const { summary } = ticket;
        const title = isChangeUserDetailsRequest(ticket)
          ? t(
              TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
            )
          : summary;
        return (
          title && (
            <Typography
              component="strong"
              className={clsx(classes.elementIcon, classes.ellipsis)}
            >
              {title}
            </Typography>
          )
        );
      },
      width: '50%',
    },
    {
      id: 'status',
      getContent: ({ status }): ReactNode => {
        const icon = undefined;
        const ticketStatus = ticketStatuses?.find(
          (ticketStatus) => ticketStatus.jiraKey === status,
        );

        return (
          <div className={classes.requestElements}>
            {icon && (
              <Icon
                className={classes.elementIcon}
                icon={icon}
                height={24}
                width={24}
              />
            )}
            {ticketStatus && (
              <div className={classes.statusContainer}>
                <Status className={classes.status} status={ticketStatus} />
              </div>
            )}
          </div>
        );
      },
      label: '',
    },
  ];

  return (
    <GenericList<Ticket>
      isWidget={!!limit}
      model={model}
      property={property}
      to={(element) => `${paths.DETAILS}/${element.ticketType}`}
      defaultPageSize={10}
      pageSizeOptions={[2, 5, 10, 25]}
      defaultSortBy="createdDate"
      paginatedQuery={useTicketsQuery}
    />
  );
};
