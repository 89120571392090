import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    imagesItem: {
      marginBottom: theme.spacing(2),
    },
    container: {
      height: '100%',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    carousel: {
      height: '256px',
      borderRadius: theme.spacing(0, 0, 1, 1),
      margin: theme.spacing(0, 0, -3, -3),
      width: `calc(100% + 2 * ${theme.spacing(3)}px)`,
    },
    carouselButtonsContainer: {
      height: '100%',
      position: 'absolute',
    },
    button: {
      position: 'absolute',
    },
  }),
);
