import React, { FC, MouseEvent } from 'react';
import { ContactInfo } from '@shared/components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '@shared/translations';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './error-screen.styles';

type Button = {
  textKey: GenericTypes.TranslationLabel;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  href?: string;
};

type Props = {
  titleKey: GenericTypes.TranslationLabel;
  textKey: GenericTypes.TranslationLabel;
  button?: Button;
  secondaryButton?: Button;
};

export const ErrorScreen: FC<Props> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { titleKey, textKey, button, secondaryButton } = props;

  return (
    <div className={classes.wrapper}>
      <Grid container justify="space-between" className={classes.container}>
        <Grid item xs={12} md className={classes.content}>
          <Typography className={classes.headline}>
            <strong>{t(titleKey)}</strong>
          </Typography>
          <Typography className={classes.paragraph}>{t(textKey)}</Typography>
          {(button || secondaryButton) && (
            <div className={classes.buttonWrapper}>
              {secondaryButton && (
                <Button
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={secondaryButton.onClick}
                  href={secondaryButton.href}
                >
                  {t(secondaryButton.textKey)}
                </Button>
              )}
              {button && (
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={button.onClick}
                  href={button.href}
                >
                  {t(button.textKey)}
                </Button>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md="auto">
          <ContactInfo
            isDense
            isTitle
            noAddress
            className={classes.contactInfo}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorScreen;
