import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: 64,
      padding: theme.spacing(0, 2),
      background: palette.athensGray,
      borderRadius: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'left',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    text: {
      flexGrow: 1,
    },
    icon: {
      flexShrink: 0,
      marginLeft: theme.spacing(2),
    },
    spinner: {
      marginLeft: theme.spacing(2),
    },
  }),
);
