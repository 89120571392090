import { FC, SVGProps } from 'react';
import { ReactComponent as OfficeFilePdf1 } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/office-files/office-file-pdf-1.svg';
import { ReactComponent as CommonFileEmpty } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-empty.svg';
import { Attachment } from '../../../apartment.type';

export const getAttachmentIcon = (
  contentType: Attachment['contentType'],
): FC<SVGProps<SVGSVGElement>> => {
  switch (contentType) {
    case 'application/pdf': {
      return OfficeFilePdf1;
    }
    default: {
      return CommonFileEmpty;
    }
  }
};
