import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    link: {
      display: 'block',
      padding: 0,
      textAlign: 'left',
      width: '100%',

      '&:disabled': {
        borderRadius: theme.spacing(1),
      },
    },
    box: {
      cursor: 'pointer',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    text: {
      lineHeight: theme.typography.pxToRem(20),
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '100%',
    },
    icon: {
      width: '32px',
      height: 'auto',
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
  }),
);
