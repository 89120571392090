import { Container, Instagram, AvailableFor } from '@shared/components';
import { Blog } from '@shared/components/Blog/blog.component';
import { Faq } from '@shared/components/Faq/faq.component';
import { useCountry } from '@shared/hooks';
import { UserGlobalRole } from '@shared/enums';
import React, { FC, ReactNode, useMemo } from 'react';
import background from '../../../../assets/content-boxes_bcg.svg';
import { Footer } from '../Footer/footer.component';
import { Header } from '../Header/header.component';
import { useStyles } from './main.styles';
import { AvailableUnitsNearby } from '../../../+prospects';

type Props = {
  children: ReactNode;
};

export const Main: FC<Props> = ({ children }) => {
  const classes = useStyles();
  const country = useCountry();

  const isFAQAvailable = useMemo(() => country !== 'is' && country !== 'nl', [
    country,
  ]);

  return (
    <main className={classes.main}>
      <Header />
      {children}
      <AvailableFor availableForRoles={[UserGlobalRole.PROSPECT]}>
        <Container>
          <AvailableUnitsNearby />
        </Container>
      </AvailableFor>
      {isFAQAvailable && (
        <Container>
          <Faq />
        </Container>
      )}
      {country !== 'pl' && (
        <Container>
          <Blog />
        </Container>
      )}
      {country !== 'pl' && (
        <div className={classes.fluidContainer}>
          <Container>
            <Instagram />
          </Container>
          <img src={background} alt="" className={classes.background} />
        </div>
      )}
      <Footer />
    </main>
  );
};
