import { getEnvValue } from '@shared/helpers';
import { mieClient } from '@shared/http/mie';
import { GetAuthTokensResponse, GetMeResponse } from './auth.types';

export const api = {
  getAuthTokens: (code: string): Promise<GetAuthTokensResponse> => {
    return mieClient.get<GetAuthTokensResponse>('/auth/auth0/token', {
      code,
      redirect_uri: `${window.location.origin}/authorize`,
    });
  },
  logOut: (returnTo?: GenericTypes.URL): void => {
    const redirectUri = returnTo || `${window.location.origin}/login`;

    // This has to be done this way - it cannot be XHR request
    // because of redirection and CORS issues.
    // Implemented the same way in the official Auth0 SDK.
    window.location.href = `${getEnvValue(
      'MIE_API_URL',
    )}/auth/auth0/logout?redirect_uri=${redirectUri}`;
  },
  getMe: (): Promise<GetMeResponse> => {
    return mieClient.get<GetMeResponse>('/auth/me');
  },
};
