import { NotificationPermissions } from '../../../../../+profile/types';
import { mieClient } from '../../mie.client';
import {
  adaptNotificationConfiguration,
  transformNotificationPermissions,
} from './auth.adapter';
import {
  NotificationConfiguration,
  NotificationConfigurationAPIParams,
  PatchProfileAPIParams,
  Profile,
} from './auth.type';

export const authAPI = {
  getMyProfile: async (): Promise<Profile> => {
    return mieClient.get<Profile>('/auth/me');
  },
  patchMyProfile: async (profile: PatchProfileAPIParams): Promise<Profile> => {
    return mieClient.patch<PatchProfileAPIParams, Profile>('/auth/me', profile);
  },
  getNotificationConfiguration: async (): Promise<NotificationPermissions> => {
    const response = await mieClient.get<NotificationConfiguration>(
      '/auth/me/notification-configuration',
    );
    return adaptNotificationConfiguration(response);
  },
  patchNotificationConfiguration: async (
    notificationPermissions: NotificationPermissions,
  ): Promise<NotificationConfiguration> => {
    return mieClient.patch<
      NotificationConfigurationAPIParams,
      NotificationConfiguration
    >(
      '/auth/me/notification-configuration',
      transformNotificationPermissions(notificationPermissions),
    );
  },
};
