import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search-circle.svg';
import { SearchBox } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { Icon } from '../icons';
import { Suggestion } from './header-search-box.type';
import { useGetSuggestionsQuery } from './header-search-box.hook';

export const HeaderSearchBox: FC = () => {
  const { t } = useTranslation();
  const getElementTemplate = (
    className: string,
    { externalWebpageURL, route, titleKey }: Suggestion,
    handleClick: () => void,
  ): ReactNode => (
    <Button
      {...(externalWebpageURL
        ? {
            href: externalWebpageURL,
            rel: 'noopener noreferrer',
            target: '_blank',
          }
        : { component: Link, to: route })}
      className={className}
      onClick={handleClick}
    >
      <Typography>
        {Array.isArray(titleKey)
          ? titleKey.map((key) => t(key)).join(' -> ')
          : t(titleKey)}
      </Typography>
    </Button>
  );
  const iconTemplate = <Icon icon={SearchCircleIcon} height={32} width={32} />;

  return (
    <SearchBox<Suggestion>
      callbackQuery={useGetSuggestionsQuery}
      getElementTemplate={getElementTemplate}
      placeholderKey={TranslationLabels.headerSearchInput}
      iconTemplate={iconTemplate}
    />
  );
};
