export const failuresPathSuffix = '/failures';
export const itemsPathSuffix = '/items';
export const issuesPathSuffix = '/issues';
export const roomsPathSuffix = '/rooms';
export const suggestionPathSuffix = '/suggestion';

const prefix = '/ticketing';
const customerServicePrefix = `${prefix}/:unitId/customer-service`;
const myAccountPrefix = `${prefix}/:unitId/my-account`;
const serviceRequestAreasPrefix = `${prefix}/:unitId/service-request/areas`;
const serviceRequestAreaRoomsPrefix = `${serviceRequestAreasPrefix}/:areaId${roomsPathSuffix}`;
const serviceRequestAreaRoomIssuesPrefix = `${serviceRequestAreaRoomsPrefix}/:roomId${issuesPathSuffix}`;
const serviceRequestAreaRoomIssueItemsPrefix = `${serviceRequestAreaRoomIssuesPrefix}/:issueId${itemsPathSuffix}`;
const serviceRequestAreaRoomIssueItemFailuresPrefix = `${serviceRequestAreaRoomIssueItemsPrefix}/:itemId${failuresPathSuffix}`;
const serviceRequestAreaItemsPrefix = `${serviceRequestAreasPrefix}/:areaId${itemsPathSuffix}`;
const serviceRequestAreaItemFailuresPrefix = `${serviceRequestAreaItemsPrefix}/:itemId${failuresPathSuffix}`;

export const PATHS = {
  INDEX: prefix,
  CUSTOMER_SERVICE: customerServicePrefix,
  CUSTOMER_SERVICE_GENERAL_REQUEST: `${customerServicePrefix}/general-request`,
  CUSTOMER_SERVICE_GENERAL_REQUEST_SUCCESS: `${customerServicePrefix}/general-request/success`,
  CUSTOMER_SERVICE_ISSUE_COMPLAINT: `${customerServicePrefix}/issue-complaint`,
  CUSTOMER_SERVICE_ISSUE_COMPLAINT_SUCCESS: `${customerServicePrefix}/issue-complaint/success`,
  MY_ACCOUNT: myAccountPrefix,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS: `${myAccountPrefix}/invoice-and-customer-account`,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_SUB_ITEMS: `${myAccountPrefix}/invoice-and-customer-account/:itemId`,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_FORM: `${myAccountPrefix}/invoice-and-customer-account/:itemId/:subItemId`,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_SUCCESS: `${myAccountPrefix}/invoice-and-customer-account/:itemId/:subItemId/success`,
  SERVICE_REQUEST_AREAS: serviceRequestAreasPrefix,
  SERVICE_REQUEST_AREA_ROOMS: serviceRequestAreaRoomsPrefix,
  SERVICE_REQUEST_AREA_ROOM_FORM: `${serviceRequestAreaRoomsPrefix}/:roomId`,
  SERVICE_REQUEST_AREA_ROOM_SUCCESS: `${serviceRequestAreaRoomsPrefix}/:roomId/success`,
  SERVICE_REQUEST_AREA_ROOM_FORM_SUMMARY: `${serviceRequestAreaRoomsPrefix}/:roomId/summary`,
  SERVICE_REQUEST_AREA_ROOM_ISSUES: serviceRequestAreaRoomIssuesPrefix,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEMS: serviceRequestAreaRoomIssueItemsPrefix,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FORM: `${serviceRequestAreaRoomIssueItemsPrefix}/:itemId`,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_SUCCESS: `${serviceRequestAreaRoomIssueItemsPrefix}/:itemId/success`,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURES: serviceRequestAreaRoomIssueItemFailuresPrefix,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURE_SUGGESTION: `${serviceRequestAreaRoomIssueItemFailuresPrefix}/:failureId${suggestionPathSuffix}`,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURE_FORM: `${serviceRequestAreaRoomIssueItemFailuresPrefix}/:failureId`,
  SERVICE_REQUEST_AREA_ROOM_ISSUE_ITEM_FAILURE_SUCCESS: `${serviceRequestAreaRoomIssueItemFailuresPrefix}/:failureId/success`,
  SERVICE_REQUEST_AREA_ITEMS: serviceRequestAreaItemsPrefix,
  SERVICE_REQUEST_AREA_ITEM_FORM: `${serviceRequestAreaItemsPrefix}/:itemId`,
  SERVICE_REQUEST_AREA_ITEM_SUCCESS: `${serviceRequestAreaItemsPrefix}/:itemId/success`,
  SERVICE_REQUEST_AREA_ITEM_FAILURES: serviceRequestAreaItemFailuresPrefix,
  SERVICE_REQUEST_AREA_ITEM_FAILURE_SUGGESTION: `${serviceRequestAreaItemFailuresPrefix}/:failureId${suggestionPathSuffix}`,
  SERVICE_REQUEST_AREA_ITEM_FAILURE_FORM: `${serviceRequestAreaItemFailuresPrefix}/:failureId`,
  SERVICE_REQUEST_AREA_ITEM_FAILURE_SUCCESS: `${serviceRequestAreaItemFailuresPrefix}/:failureId/success`,
};
