import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    contact: {
      margin: theme.spacing(4, 0, 2),
    },
    dense: {
      margin: theme.spacing(0),
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
    },
    icon: {
      marginRight: theme.spacing(2),
      flexShrink: 0,
    },
    addressLabel: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    contactInfo: {
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      maxWidth: '420px',
    },
    contactElement: {
      '& + $contactElement': {
        marginTop: theme.spacing(3),
      },
    },
    contactInfoTitle: {
      fontWeight: theme.typography.fontWeightBold,
      color: palette.internationalOrange,
      marginBottom: theme.spacing(2),
    },
    countryName: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(1),
    },
  }),
);
