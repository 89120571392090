import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';
import { Theme } from '@material-ui/core/styles';

export type Props = {
  type: 'info' | 'error' | 'warning' | 'success';
};

const getColor = (type: Props['type']): string => {
  if (type === 'error') return palette.monza;
  if (type === 'warning') return palette.internationalOrange;
  if (type === 'success') return palette.green80;
  return palette.blue60;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: palette.white,
      border: ({ type }) => `2px solid ${getColor(type)}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 5),
    },
    text: {
      color: palette.darkGray100,
    },
    icon: {
      '& path': {
        stroke: ({ type }) => getColor(type),
      },
      marginRight: theme.spacing(2),
    },
  }),
);
