import React, { FC, MouseEvent, SVGProps } from 'react';
import { FormikProps } from 'formik';
import { CancelButton } from '@shared/FormV2/components/cancel-button.component';
import { SubmitButton } from '@shared/FormV2/components/submit-button.component';
import isEqual from 'lodash-es/isEqual';
import { useStyles } from './form-buttons.styles';

type Props<TFormValues> = {
  formProps: FormikProps<TFormValues>;
  submitLabel?: GenericTypes.TranslationLabel;
  submitIcon?: FC<SVGProps<SVGSVGElement>>;
  cancelLabel?: GenericTypes.TranslationLabel;
  onCancel?: () => void;
  submitDisabled?: boolean;
};

export function FormButtons<TFormValues>({
  formProps,
  submitLabel,
  cancelLabel,
  submitIcon,
  onCancel,
  submitDisabled,
}: Props<TFormValues>): ReturnType<FC<Props<TFormValues>>> {
  const classes = useStyles();
  const handleCancel = () => (event: MouseEvent<HTMLButtonElement>): void => {
    formProps.handleReset(event);

    if (onCancel) onCancel();
  };

  return (
    <div className={classes.buttonsRow}>
      {onCancel && (
        <CancelButton
          label={cancelLabel}
          disabled={formProps.isSubmitting}
          onClick={handleCancel()}
        />
      )}
      <SubmitButton
        disabled={
          formProps.isSubmitting ||
          isEqual(formProps.initialValues, formProps.values) ||
          !!submitDisabled
        }
        label={submitLabel}
        isSubmitting={formProps.isSubmitting}
        icon={submitIcon}
      />
    </div>
  );
}
