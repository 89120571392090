import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { Content, Elements } from '../../shared';
import { ReactComponent as AreasImage } from '../../assets/areas.svg';
import { stepper } from '../stepper.config';
import { useGetTypesQuery } from './types.hook';

export const Types: FC = () => {
  const country = useCountry();
  const { data: types, isLoading } = useGetTypesQuery(country);

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
        childrenRightColumn: <AreasImage />,
      }}
      isFetching={isLoading}
      type="columns"
      stepperConfig={stepper}
    >
      <Elements data={types} dataTestPrefix="types" />
    </Content>
  );
};
