import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, MouseEvent, useState } from 'react';
import { ReactComponent as SmileySadUnhappyIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-unhappy.svg';
import { ReactComponent as SmileySad1Icon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-sad-1.svg';
import { ReactComponent as SmileyMadIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-mad.svg';
import { ReactComponent as SmileyIndiferent1Icon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-indiferent-1.svg';
import { ReactComponent as SmileyCheerfulIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-cheeky.svg';
import { ReactComponent as SmileyHappy1Icon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-happy-1.svg';
import { ReactComponent as SmileyPrankIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-prank.svg';
import { ReactComponent as CoffeeCupHotIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/coffee/coffee-cup-hot.svg';
import { FormV2 } from '@shared/FormV2';
import { useStyles } from './feedback.styles';
import { getConfig, getFields, Values } from './feedback.model';
import background from '../../../assets/feedback_bcg.svg';
import { Box, generateUniqId, Icon, useTranslation } from '../../../../shared';
import { useAuth } from '../../../../+auth';

export const Feedback: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [emotion, setEmotion] = useState(0);
  const [isConfirmation, setConfirmation] = useState(false);
  const emotions = [
    SmileyMadIcon,
    SmileySad1Icon,
    SmileySadUnhappyIcon,
    SmileyIndiferent1Icon,
    SmileyCheerfulIcon,
    SmileyHappy1Icon,
    SmileyPrankIcon,
  ];
  const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    const { emotion } = event.currentTarget.dataset;

    if (!emotion) {
      return;
    }

    setEmotion(Number(emotion));
  };
  const getFieldLabel = (): string => {
    switch (emotion) {
      case 1:
      case 2:
      case 3:
        return TranslationLabels.profileFeedbackLabelNegative;
      case 4:
        return TranslationLabels.profileFeedbackLabelNeutral;
      default:
        return TranslationLabels.profileFeedbackLabelPositive;
    }
  };
  const getConfirmationText = (): string => {
    switch (emotion) {
      case 1:
      case 2:
      case 3:
        return TranslationLabels.profileFeedbackConfirmationNegative;
      case 4:
        return TranslationLabels.profileFeedbackConfirmationNeutral;
      default:
        return TranslationLabels.profileFeedbackConfirmationPositive;
    }
  };
  const customerId = user?.email || user?.phoneNumber || '';
  const config = getConfig({ customerId, emotion });
  const fields = getFields(getFieldLabel());
  const handleSuccess = (): void => {
    setConfirmation(true);
  };

  return (
    <Box className={classes.box}>
      {!isConfirmation ? (
        <>
          <Typography variant="h2">
            {t(TranslationLabels.profileFeedbackTitle)}
          </Typography>
          <Typography>{t(TranslationLabels.profileFeedbackText)}</Typography>
          <div className={classes.emotionsWrapper}>
            <div className={classes.emotions}>
              {emotions.map((icon, index) => (
                <div
                  className={classes.emotion}
                  key={generateUniqId()}
                  aria-hidden
                  role="button"
                  onClick={handleClick}
                  tabIndex={index}
                  data-emotion={index + 1}
                >
                  <Icon
                    className={clsx(classes.icon, {
                      [classes.iconSelected]: emotion === index + 1,
                    })}
                    height={64}
                    icon={icon}
                    width={64}
                  />
                </div>
              ))}
            </div>
            {emotion !== 0 && (
              <FormV2<Values>
                className={classes.form}
                config={config}
                fieldsConfig={fields}
                handleSuccess={handleSuccess}
                showButtons={false}
                showInlineButtons
              />
            )}
          </div>
          <img src={background} alt="" className={classes.background} />
        </>
      ) : (
        <>
          <Typography variant="h2">
            {t(TranslationLabels.profileFeedbackConfirmationTitle)}
          </Typography>
          <Typography>{t(getConfirmationText())}</Typography>
          {emotion > 4 && (
            <Icon
              className={classes.confirmationIcon}
              height={104}
              icon={CoffeeCupHotIcon}
              width={104}
            />
          )}
        </>
      )}
    </Box>
  );
};
