import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import 'yup-phone';
import { InputValue, AttachmentValue } from '@shared/components/Fields';
import { FieldConfig, FormProps } from '@shared/FormV2';
import { Profile } from '@shared/http/mie/api/auth/auth.type';
import { CreateGeneralRequestPayload } from '../../../../shared/http/mie/api/ticket/mie.type';
import { transformProfileToGeneralRequest } from '../../../../shared/http/mie/api/ticket/tickets.adapter';
import { GeneralRequest } from '../../../../shared/http/mie/api/ticket/type';

export type Values = {
  firstName: InputValue;
  lastName: InputValue;
  phoneNumber: InputValue;
  email: InputValue;
  attachments: AttachmentValue[];
};

export const getFields = (
  user?: Profile,
  readonly = false,
): FieldConfig<Values>[] => {
  return [
    {
      componentType: 'input',
      field: {
        name: 'firstName',
        initialValue: user?.firstName || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.profileFormFirstName,
        readonly: true,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'lastName',
        initialValue: user?.lastName || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.profileFormLastName,
        readonly: true,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'phoneNumber',
        initialValue: user?.phoneNumber || '',
        validationSchema: Yup.string()
          .phone('IN', false, TranslationLabels.formErrorsPhone)
          .required(TranslationLabels.formErrorsRequired),
      },
      props: {
        helperTextKey: TranslationLabels.profileFormMobilePhoneHelperText,
        label: TranslationLabels.profileFormMobilePhone,
        readonly,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'email',
        initialValue: user?.email || '',
        validationSchema: Yup.string()
          .email(TranslationLabels.formErrorsEmail)
          .required(TranslationLabels.formErrorsRequired),
      },
      props: {
        label: TranslationLabels.profileFormEmail,
        readonly,
        required: true,
        type: 'text',
      },
    },
    // Uncomment when NL will be ready to handle change name requests
    // {
    //   componentType: 'attachment',
    //   field: {
    //     name: 'attachments',
    //     initialValue: [],
    //     validationSchema: Yup.array().when(['firstName', 'lastName'], {
    //       is: (firstName: string, lastName: string) => {
    //         if (!user) {
    //           return true;
    //         }

    //         const {
    //           firstName: profileFirstName,
    //           lastName: profileLastName,
    //         } = user;
    //         return (
    //           firstName !== profileFirstName || lastName !== profileLastName
    //         );
    //       },
    //       then: Yup.array()
    //         .of(
    //           Yup.object().shape({
    //             name: Yup.string().required(),
    //             value: Yup.string().optional(),
    //           }),
    //         )
    //         .min(1, TranslationLabels.formErrorsRequired),
    //       otherwise: Yup.array().nullable(),
    //     }),
    //   },
    //   props: {
    //     accept: 'image/*,.pdf',
    //     isMultiple: true,
    //     label: TranslationLabels.profileFormCopyOfId,
    //     readonly,
    //   },
    // },
  ];
};

export const getConfig = (
  onUpdateProfile: (
    request: CreateGeneralRequestPayload,
  ) => Promise<GeneralRequest>,
  userId?: string,
): FormProps<Values>['config'] => ({
  enableReinitialize: true,
  onSubmit: async (values): Promise<void> => {
    if (userId) {
      await onUpdateProfile(transformProfileToGeneralRequest(values, userId));
    }
  },
});
