import { TranslationLabels } from '@generated/translation-labels';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Spinner } from '@shared/components';
import {
  COUNTRY_TRANSLATION_LABELS,
  SUPPORTED_COUNTRIES_ALL_VERSIONS,
} from '@shared/consts';
import clsx from 'clsx';
import { useCountry } from '@shared/hooks';
import React, { FC, Fragment, useMemo } from 'react';
import { ReactComponent as PhoneIcon } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/phone/phone.svg';
import { ReactComponent as EmailActionUnreadIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/email-actions/email-action-unread.svg';
import { useTranslation } from '@shared/translations';
import { generateUniqId } from '../../helpers';
import { Icon } from '../icons';
import { useStyles } from './contact-info.styles';
import { getContactDataInOrder } from './contact-info.helper';
import { useGetDataQuery } from './contact-info.hook';

type Props = {
  showOnlyCurrentCountry?: boolean;
  isDense?: boolean;
  isTitle?: boolean;
  noAddress?: boolean;
  noEmail?: boolean;
  className?: string;
};

export const ContactInfo: FC<Props> = (props) => {
  const {
    showOnlyCurrentCountry,
    isDense,
    isTitle,
    noAddress,
    noEmail,
    className,
  } = props;
  const currentCountry = useCountry();
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetDataQuery(
    showOnlyCurrentCountry
      ? [currentCountry]
      : SUPPORTED_COUNTRIES_ALL_VERSIONS,
  );
  const orderedData = useMemo(
    () => getContactDataInOrder(data || [], currentCountry),
    [data, currentCountry],
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return null;
  }

  return (
    <div
      {...(isTitle && {
        className: clsx(classes.contactInfo, className),
      })}
    >
      {!showOnlyCurrentCountry && (
        <Typography variant="h3" className={classes.contactInfoTitle}>
          {t(TranslationLabels.contactInfoTitle)}
        </Typography>
      )}
      {orderedData.map(({ _id, address, country, email, phone }) => (
        <Fragment key={_id}>
          {(email || phone) && (
            <div className={classes.contactElement}>
              {isTitle && (
                <Typography className={classes.countryName} variant="h4">
                  {t(COUNTRY_TRANSLATION_LABELS[country])}
                </Typography>
              )}
              <div className={isDense ? classes.dense : classes.contact}>
                {phone && (
                  <Typography className={classes.text}>
                    <Icon
                      className={classes.icon}
                      icon={PhoneIcon}
                      height={24}
                      width={24}
                    />
                    <Link href={`tel:${phone}`}>{phone}</Link>
                  </Typography>
                )}
                {email && !noEmail && (
                  <Typography className={classes.text}>
                    <Icon
                      className={classes.icon}
                      icon={EmailActionUnreadIcon}
                      height={24}
                      width={24}
                    />
                    <Link href={`mailto:${email}`}>{email}</Link>
                  </Typography>
                )}
              </div>
            </div>
          )}
          {address && !noAddress && (
            <div>
              <Typography component="strong" className={classes.addressLabel}>
                {t(TranslationLabels.footerAddressLabel)}:
              </Typography>
              {address.split('\n').map((text: string) => (
                <Typography key={`text-${generateUniqId()}`}>{text}</Typography>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

ContactInfo.defaultProps = {
  isDense: false,
  noAddress: false,
  noEmail: false,
};
