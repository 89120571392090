import { Route, useRouteMatch, Link } from 'react-router-dom';
import React, { ReactElement, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { UseInfiniteQueryResult } from 'react-query';
import { Comment as CommentType, WithId } from './comments.type';
import { CommentsButton } from './comments-button.component';
import { CommentsModal } from './comments-modal.component';

type Props<MieCommentGeneric> = {
  entityId: string;
  titleComponent?: ReactNode;
  titleKey?: GenericTypes.TranslationLabel;
  useCommentsQuery: (
    id: string,
    pagination: GenericTypes.Pagination,
  ) => UseInfiniteQueryResult<
    GenericTypes.Paginated<MieCommentGeneric>,
    unknown
  >;
  transformComment: (comment: MieCommentGeneric) => CommentType;
  onSubmitComment: (content: string) => Promise<void>;
  small?: boolean;
};

export const Comments = <T extends WithId>(
  props: Props<T>,
): ReactElement | null => {
  const {
    entityId,
    titleComponent,
    titleKey,
    useCommentsQuery,
    transformComment,
    onSubmitComment,
    small,
  } = props;

  const { url } = useRouteMatch();

  const {
    isLoading,
    data: commentsData,
    fetchNextPage,
    hasNextPage,
    error: commentsError,
  } = useCommentsQuery(entityId, {
    page: 1,
    pageSize: 25,
  });

  const allComments = commentsData?.pages
    .flatMap((page) => page.results.flatMap(transformComment))
    .reverse();

  if (commentsError) {
    Sentry.captureException(commentsError);
    return null;
  }

  return (
    <>
      <Link
        to={{
          pathname: `${url}/modal/messages/${entityId}`,
        }}
      >
        <CommentsButton<T> small={small} commentsData={commentsData} />
      </Link>
      <Route
        path={`${url}/modal/messages/${entityId}`}
        render={() => (
          <CommentsModal
            onSubmitComment={onSubmitComment}
            titleComponent={titleComponent}
            titleKey={titleKey}
            allComments={allComments}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
          />
        )}
      />
    </>
  );
};
