import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormProps, FormV2 } from '@shared/FormV2';
import { useCountry } from '@shared/hooks';
import { useDuplicatedTicketQuery } from '@shared/http/mie/api/ticket/service-requests.hook';
import { TicketType } from '@shared/http/mie/api/ticket/type';
import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from '@shared/translations';
import { useQueryClient } from 'react-query';
import { Alert } from '@shared/components';
import { Link, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useStyles } from './request-creator.styles';
import { TicketParams } from './request.creator.type';
import { paths } from '../../routing/paths-v2.const';
import { FallbackMessage } from '../FallbackMessage/fallback-message.component';

type Props<T> = {
  formProps: FormProps<T>;
  children?: (node: ReactElement) => ReactElement;
  fullWidth?: boolean;
  ticketParams?: TicketParams;
  ticketType: TicketType;
  ticketId?: string;
};

export const RequestCreator = <T extends unknown>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const country = useCountry();
  const [isSuccess, setSuccess] = useState(false);
  const location = useLocation();
  const {
    children,
    formProps,
    fullWidth,
    ticketParams,
    ticketType,
    ticketId,
  } = props;
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { t } = useTranslation();

  // entity_uuid and ticket_subtype will be used for looking for duplicates within Invoice and Contract requests - MYH-1695

  const queryParams = {
    room_with_issue: ticketParams?.roomId,
    issue_subtype: ticketParams?.failureId,
    issue_type: ticketParams?.issueId,
    issue_location: ticketParams?.areaId,
    // entity_uuid: ticketParams?.unitId,
    // ticket_subtype: ticketParams?.ticketSubtype,
  };

  const { data: duplicatedTicket, isError } = useDuplicatedTicketQuery(
    queryParams,
  );

  const handleSuccess = (): void => {
    setSuccess(true);
  };
  const form = useMemo(
    () => (
      <Grid item xs={12} md={fullWidth ? 12 : 6}>
        <FormV2<T>
          {...formProps}
          handleSuccess={handleSuccess}
          submitLabel={TranslationLabels.ticketingButtonSubmitLabel}
          submitDisabled={country === 'nl'}
        />
      </Grid>
    ),
    [formProps, fullWidth, country],
  );

  if (isSuccess) {
    queryClient.invalidateQueries(['duplicatedTickets']);

    return (
      <Redirect
        to={{
          pathname: `${location.pathname}/success`,
          state: { ticketId, type: ticketType },
        }}
      />
    );
  }

  if (ticketType === 'complaint' || ticketType === 'general_request') {
    return children ? children(form) : form;
  }

  if (isError) {
    return (
      <FallbackMessage messageKey={TranslationLabels.formGlobalErrorMessage} />
    );
  }

  if (duplicatedTicket && ticketType === 'service_request') {
    return (
      <Alert xs={12} className={classes.alert}>
        <Typography>
          {t(TranslationLabels.cannotCreateServiceRequest)}
          :&nbsp;
          <Link
            className={classes.link}
            to={`${paths.DETAILS}/${ticketType}/${duplicatedTicket.id}`}
          >
            <strong>{duplicatedTicket.referenceNumber}</strong>
          </Link>
        </Typography>
      </Alert>
    );
  }

  return children ? children(form) : form;
};
