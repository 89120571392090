import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Content, Elements } from '../../shared';
import { RouteParams } from '../service-request.type';
import { useRoomsQuery } from './rooms.repository';
import { stepper } from '../stepper.config';
import { MaintenanceBox } from './maintenance-box.component';

export const Rooms: FC = () => {
  const country = useCountry();
  const { areaId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const { isFetching, isError, error, data: roomsData } = useRoomsQuery(
    country,
    areaId,
    pathname,
  );
  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestRoomsText,
        titleKey: TranslationLabels.ticketingServiceRequestRoomsTitle,
        childrenRightColumn: country === 'nl' ? <MaintenanceBox /> : undefined,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
      type={country === 'nl' ? 'columns' : 'rows'}
    >
      <Elements data={roomsData?.rooms} dataTestPrefix="rooms" />
    </Content>
  );
};
