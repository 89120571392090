import { useCallback } from 'react';
import { useUnitQuery } from '@shared/http/mie';

interface UseContractReturnType {
  fetchContract: (args: FetchContractArguments) => Promise<void>;
  isFetching: boolean;
  isContractDisabled: boolean;
}

interface FetchContractArguments {
  openInNewTab: boolean;
}

export const useContract = (unitId: string): UseContractReturnType => {
  const { data: propertyUnit, isLoading } = useUnitQuery(unitId);

  const fileUrl = propertyUnit?.contractPdf?.url;

  const fetchContract = useCallback(
    async ({ openInNewTab }: FetchContractArguments): Promise<void> => {
      if (!fileUrl) {
        return;
      }

      if (openInNewTab) {
        window.open(fileUrl);
      } else {
        window.location.replace(fileUrl);
      }
    },
    [fileUrl],
  );

  return { fetchContract, isFetching: isLoading, isContractDisabled: !fileUrl };
};
