import { FC, SVGProps } from 'react';

export const icons: {
  [key in GenericTypes.Country]: Record<number, FC<SVGProps<SVGSVGElement>>>;
} = {
  dk: {},
  no: {},
  is: {},
  nl: {},
  se: {},
  pl: {},
  de: {},
};
