import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    section: {
      margin: theme.spacing(5, 0),
    },
    label: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
  }),
);
