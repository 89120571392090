export enum DocumentType {
  PAGE = 'page',
  SETTINGS = 'settings',
  TICKET_ELEMENT = 'ticketElement',
  TRANSLATION = 'translation',
  PDF = 'pdf',
  PROPERTY = 'property',
}

export enum PDF_CATEGORY {
  PROPERTY = 'property',
  MOVE_OUT_CHECKLIST = 'moveout-checklist',
}
