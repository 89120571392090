import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { NotificationPermissions } from '../../../../../+profile/types';
import { authAPI } from './auth';
import { transformProfile } from './auth.adapter';
import {
  NotificationConfiguration,
  PatchProfileParams,
  Profile,
} from './auth.type';

export const useProfileQuery = (): UseQueryResult<Profile> => {
  return useQuery<Profile>(['profile'], authAPI.getMyProfile);
};

export const useProfileMutation = (): UseMutationResult<
  Profile,
  Error,
  PatchProfileParams
> => {
  const queryClient = useQueryClient();
  return useMutation<Profile, Error, PatchProfileParams>(
    (profile: PatchProfileParams) => {
      return authAPI.patchMyProfile(transformProfile(profile));
    },
    { onSuccess: () => queryClient.invalidateQueries(['profile']) },
  );
};

export const useNotificationConfigurationQuery = (): UseQueryResult<NotificationPermissions> => {
  return useQuery<NotificationPermissions>(
    ['notificationPermissions'],
    authAPI.getNotificationConfiguration,
  );
};

export const useNotificationConfigurationMutation = (): UseMutationResult<
  NotificationConfiguration,
  Error,
  NotificationPermissions
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (notificationPermissions: NotificationPermissions) => {
      const responseFromApi = await authAPI.patchNotificationConfiguration(
        notificationPermissions,
      );
      return responseFromApi;
    },
    { onSuccess: () => queryClient.invalidateQueries(['profile']) },
  );
};
