import * as Sentry from '@sentry/react';
import { SENTRY_LOCATION_TAG } from '@shared/consts';
import React, { Fragment, ReactNode } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AvailableFor } from '@shared/components';
import { NOT_FOUND_ERROR_PATH } from '@shared/routing/routes.const';
import { Routes } from './routes.type';

export const renderRoutes = (routes: Routes = []): ReactNode =>
  routes.map((props) => {
    const {
      component,
      exact,
      fallback,
      guard,
      layout,
      path,
      routes,
      isDisabled,
      availableForRoles,
    } = props;

    if (isDisabled) return null;

    const Guard = guard || Fragment;
    const Layout = layout || Fragment;
    const Component = component || Fragment;

    return (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={(props) => (
          <AvailableFor
            availableForRoles={availableForRoles}
            fallback={<Redirect to={NOT_FOUND_ERROR_PATH} />}
          >
            <Sentry.ErrorBoundary
              fallback={fallback}
              beforeCapture={(scope) => {
                scope.setTag(SENTRY_LOCATION_TAG, 'routing');
              }}
              showDialog
            >
              <Guard>
                <Layout>
                  {routes ? (
                    <Switch>{renderRoutes(routes)}</Switch>
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            </Sentry.ErrorBoundary>
          </AvailableFor>
        )}
      />
    );
  });
