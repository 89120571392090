import {
  Layout,
  LayoutFallback,
  LayoutChildrenFallback,
} from '@shared/components';
import { UserGlobalRole } from '@shared/enums';
import { AuthGuard, authRoutes, GuestGuard } from '../../+auth';
import { profileRoutes } from '../../+profile';
import { apartmentRoutes } from '../../+apartment';
import { paths } from '../../+ticketing/shared/routing/paths-v2.const';
import { TICKETING_PATHS } from '../../+ticketing';
import { routes as prospectsRoutes } from '../../+prospects';
import { routes as termsRoutes } from '../../+terms';
import { Routes } from './routes.type';
import { safeLazyLoad } from './safeLazyLoad';
import { notificationRoutes } from '../../+notification';

export const NOT_FOUND_ERROR_PATH = '/not-found-404';

export const getRoutes = (): Routes => [
  {
    exact: true,
    guard: GuestGuard,
    path: `${authRoutes.LOGIN}`,
    component: safeLazyLoad(
      () => import('../../+auth/components/SignIn/sign-in.component'),
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${authRoutes.LOGIN_PROSPECT}`,
    component: safeLazyLoad(
      () =>
        import(
          '../../+auth/components/SignInProspect/sign-in-prospect.component'
        ),
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${authRoutes.ERROR}`,
    component: safeLazyLoad(
      () => import('../../+auth/components/Error/error.component'),
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/authorize',
    component: safeLazyLoad(
      () => import('../../+auth/components/authorize.component'),
    ),
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: Layout,
    fallback: LayoutFallback,
    routes: [
      {
        exact: true,
        path: '/',
        component: safeLazyLoad(
          () => import('../../+dashboard/dashboard.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: profileRoutes.PROFILE,
        component: safeLazyLoad(
          () => import('../../+profile/profile.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: apartmentRoutes.LIST,
        component: safeLazyLoad(
          () => import('../../+apartment/List/list.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        exact: true,
        path: `${apartmentRoutes.APARTMENT}/:id/contract`,
        component: safeLazyLoad(
          () => import('../../+apartment/contract-view.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        exact: true,
        path: `${apartmentRoutes.APARTMENT}/:id`,
        component: safeLazyLoad(
          () => import('../../+apartment/apartment.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        exact: true,
        path: paths.LIST,
        component: safeLazyLoad(
          () => import('../../+ticketing/List/list.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        exact: true,
        path: paths.DETAILS,
        component: safeLazyLoad(
          () => import('../../+ticketing/Details/details.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        path: `${paths.DETAILS}/:type/:ticketId`,
        component: safeLazyLoad(
          () => import('../../+ticketing/Details/details.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        path: TICKETING_PATHS.INDEX,
        component: safeLazyLoad(
          () => import('../../+ticketing/ticketing.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.CUSTOMER],
      },
      {
        path: prospectsRoutes.PROSPECTS,
        component: safeLazyLoad(
          () => import('../../+prospects/prospects.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.PROSPECT],
      },
      {
        path: termsRoutes.TERMS,
        component: safeLazyLoad(
          () => import('../../+terms/terms-and-conditions.component'),
        ),
        fallback: LayoutChildrenFallback,
        availableForRoles: [UserGlobalRole.PROSPECT],
      },
      {
        exact: true,
        path: `${notificationRoutes.LIST}`,
        component: safeLazyLoad(
          () =>
            import('../../+notification/NotificationsList/notifications-list'),
        ),
        fallback: LayoutChildrenFallback,
      },
      // '404 not found' page must be the last item
      {
        path: '*',
        component: safeLazyLoad(
          () =>
            import('../../shared/components/errors/not-found-error.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
    ],
  },
];
