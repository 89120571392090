import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { routes } from '../routes.const';
import { useAuth } from '../hooks';
import { ErrorType } from '../components/Error/types';

type Props = {
  children?: ReactNode;
};

const AuthGuard: FC<Props> = ({ children }) => {
  const { user, auth, logout } = useAuth();
  const { pathname, search } = useLocation();

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN,
          state: { from: pathname + search },
        }}
      />
    );
  }

  if (auth && auth.isUserInvalid) {
    logout(
      `${window.location.origin}${routes.ERROR}?${qs.stringify({
        type: ErrorType.invalidCustomer,
      })}`,
    );
  }

  return <>{children}</>;
};
export default AuthGuard;

AuthGuard.propTypes = {
  children: PropTypes.node,
};
AuthGuard.defaultProps = {
  children: undefined,
};
