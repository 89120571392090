import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Spinner } from '@shared/components';
import { Intro } from '../Intro';
import { BackButton } from '../BackButton/back-button.component';
import { ViewProps } from './types';

export const ContentColumns: FC<ViewProps> = ({
  introProps,
  isFetching,
  children,
}) => {
  const childrenRightColumn = introProps?.childrenRightColumn;

  return (
    <>
      <Grid container spacing={10}>
        <Grid item sm={12} md={childrenRightColumn ? 7 : 12}>
          {isFetching ? (
            <Spinner />
          ) : (
            <>
              {introProps && (
                <Intro
                  descriptionKey={introProps.descriptionKey}
                  titleKey={introProps.titleKey}
                  infoKey={introProps.infoKey}
                  options={introProps.options}
                />
              )}
              {children}
            </>
          )}
        </Grid>
        {childrenRightColumn && (
          <Grid item sm={12} md={5}>
            {childrenRightColumn}
          </Grid>
        )}
      </Grid>
      {!isFetching && <BackButton />}
    </>
  );
};
