import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../index';

export const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      borderRadius: theme.spacing(1),
      display: 'inline-block',
      padding: theme.spacing(1, 2),
      textTransform: 'uppercase',
      color: palette.darkGray100,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      verticalAlign: 'top',
    },
  }),
);
