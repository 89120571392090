import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Block } from '@shared/components/Footer/footer.type';
import { DEFAULT_LANGUAGE } from '@shared/consts';

export const useGetFooterBlocksQuery = (
  country: GenericTypes.Country,
  lang: GenericTypes.Language,
): UseQueryResult<Block[] | undefined> => {
  return useQuery<Block[] | undefined>(['footerBlocks', country, lang], () => {
    if (!country || !lang) return undefined;
    return sanityClient
      .fetch<{ blocks: Block[] }>(
        `*[_type == $settingsType && content.country == $country][0]{
        "blocks": [
          ...content.footer.blocks[]{
            "key": _key,
            "titleKey": title->key,
            "links": [
              ...links[]{
                ...select(
                  _type == 'reference' => {
                    ...*[_type == $pageType && _id == ^._ref][0]{
                      "titleKey": menuTitle->key,
                      route
                    },
                  },
                  {
                    "titleKey": title->key,
                    "url": url[$lang],
                  }
                ),
                "key": _key,
              },
            ]
          }
        ]
      }`,
        {
          country,
          lang: lang !== DEFAULT_LANGUAGE ? 'native' : lang,
          pageType: SanityDocumentType.PAGE,
          settingsType: SanityDocumentType.SETTINGS,
        },
      )
      .then((response) => response.blocks);
  });
};
