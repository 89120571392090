import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { failuresPathSuffix } from '../../shared';
import { Items } from './items.type';

const getAreaQuery = (area: string, path: string): string => `
  ${area}.typeId == $areaId => ${area}.rooms[room->fieldId == $roomId][0]{
    ...issues[issue->fieldId == $issueId][0]{
      "otherElementRedirectURL": "${path}/" + otherElementId,
      "items": items[]{
        ...item->{
          "key": fieldId,
          "redirectURL": select(
            defined(^.failures) => "${path}/" + fieldId + "${failuresPathSuffix}",
            defined(^.otherElementId) => "${path}/" + fieldId + "${failuresPathSuffix}/" + ^.otherElementId,
            "${path}/" + fieldId,
          ),
          "titleKey": title->key,
          externalWebpageUrl,
          roles,
        }
      }
    }
  }
`;

const getQuery = (path: string): string => `
  * [_type == $type && content.country == $country][0]{
    ...content.ticketing.repairMaintenanceRequest.areas{
      ...select(
        ${getAreaQuery('commonV2', path)},
        ${getAreaQuery('outdoorV2', path)},
        ${getAreaQuery('unitV2', path)},
        ${getAreaQuery('privateOutdoorV2', path)},
      )
    }
  }
`;

export const useItemsQuery = (
  country: GenericTypes.Country,
  areaId: string,
  path: string,
  issueId: string,
  roomId?: string,
): UseQueryResult<Items> => {
  return useQuery<Items>(
    ['serviceRequestItems', country, areaId, roomId, issueId],
    () =>
      sanityClient.fetch(getQuery(path), {
        areaId,
        country,
        roomId,
        issueId,
        type: SanityDocumentType.SETTINGS,
        path,
      }),
    {
      staleTime: Infinity,
    },
  );
};
