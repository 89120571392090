import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(9),
    },
    title: {
      fontSize: '3.5rem',
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    infoBox: {
      margin: theme.spacing(3, 0, 4, 0),
    },
    lettingAgentWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    rowMainText: {
      fontSize: theme.typography.pxToRem(14),
    },
    rowSubText: {
      fontSize: theme.typography.pxToRem(12),
      color: palette.lighterGray,
    },
    statusLabel: {
      fontSize: theme.typography.pxToRem(14),
      display: 'inline',
    },
    simplRentTableWrapper: {
      whiteSpace: 'nowrap',
    },
    simplRentTableButton: {
      width: '32px',
      height: '32px',
      background: palette.internationalOrange,
      borderRadius: theme.spacing(1),
      color: palette.white,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
    },
    simplRentTableIcon: {
      '& path': {
        strokeWidth: 2,
      },
    },
  }),
);
