import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: theme.typography.pxToRem(58),
      lineHeight: theme.typography.pxToRem(60),
      fontWeight: 'bold',
      margin: theme.spacing(2, 5, 2, 0),
    },
    description: {
      fontSize: theme.typography.pxToRem(20),
      margin: theme.spacing(2, 5, 2, 0),
      fontWeight: 'bold',
    },
    content: {
      fontSize: theme.typography.pxToRem(20),
      margin: theme.spacing(2, 5, 2, 0),
    },
    [theme.breakpoints.down('sm')]: {
      imageGrid: {
        display: 'none',
      },
    },
  }),
);
