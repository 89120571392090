import React, { FC, useCallback } from 'react';
import { TranslationLabels } from '../../../../../generated/translation-labels';
import { Comments } from '../../../../shared/components/Comments/comments.component';
import { Comment as CommentType } from '../../../../shared/components/Comments/comments.type';
import { HttpStatus } from '../../../../shared/http/http.type';
import { useProfileQuery } from '../../../../shared/http/mie/api/auth/auth.hook';
import { CommentsTitle } from '../RequestDetails/comments-title.component';
import {
  useCommentMutation,
  useCommentsQuery,
} from '../../../../shared/http/mie/api/ticket';
import { Comment } from '../../../../shared/http/mie/api/ticket/type';
import { useStyles } from './ticket-comments.styles';

type Props = {
  ticketId: string;
  referenceNumber: string;
};

export const TicketComments: FC<Props> = ({ ticketId, referenceNumber }) => {
  const postComment = useCommentMutation(ticketId);
  const classes = useStyles();
  const { data: profileData } = useProfileQuery();

  const handleSubmit = useCallback(
    async (content: string): Promise<void> => {
      try {
        await postComment.mutate({
          comment: { content },
          ticketId,
        });
      } catch (e) {
        if (e.status === HttpStatus.METHOD_NOT_ALLOWED) {
          throw new Error(
            TranslationLabels.commentingClosedServiceRequestsNotAllowedErrorMessage,
          );
        }

        throw e;
      }
    },
    [ticketId, postComment],
  );

  const transformComment = useCallback(
    (commentItem: Comment): CommentType => ({
      date: commentItem.date,
      message: commentItem.message,
      isAuthor: profileData?.uuid === commentItem.author,
    }),
    [profileData?.uuid],
  );

  return (
    <div className={classes.button}>
      <Comments<Comment>
        entityId={ticketId}
        onSubmitComment={handleSubmit}
        useCommentsQuery={useCommentsQuery}
        transformComment={transformComment}
        titleComponent={<CommentsTitle referenceNo={referenceNumber} />}
      />
    </div>
  );
};
