import {
  CreateComplaintRequestResponse,
  CreateContractRequestResponse,
  CreateGeneralRequestResponse,
  CreateInvoiceAndAccountRequestResponse,
  CreateRepairAndMaintenanceRequestResponse,
  MieRequestResponse,
} from './mie.type';
import {
  GeneralRequest,
  ComplaintRequest,
  ContractRequest,
  InvoiceAndAccountRequest,
  RepairAndMaintenanceRequest,
  Request,
} from './type';

const adaptRequest = (response: MieRequestResponse): Request => {
  return {
    id: response.uuid,
    summary: response.summary,
    description: response.description,
    entityId: response.entity_uuid,
    entityType: response.entity_type,
    visibility: response.visibility,
    status: response.status,
    createdAt: response.created_at,
  };
};

export const adaptGeneralRequest = (
  response: CreateGeneralRequestResponse,
): GeneralRequest => {
  return {
    ...adaptRequest(response),
  };
};

export const adaptComplaintRequest = (
  response: CreateComplaintRequestResponse,
): ComplaintRequest => {
  return {
    ...adaptRequest(response),
    type: response.complaint_type,
  };
};

export const adaptContractRequest = (
  response: CreateContractRequestResponse,
): ContractRequest => {
  return {
    ...adaptRequest(response),
    type: response.ticket_subtype,
  };
};

export const adaptInvoiceAndAccountRequest = (
  response: CreateInvoiceAndAccountRequestResponse,
): InvoiceAndAccountRequest => {
  return {
    ...adaptRequest(response),
    type: response.ticket_subtype,
  };
};

export const adaptRepairAndMaintenanceRequest = (
  response: CreateRepairAndMaintenanceRequestResponse,
): RepairAndMaintenanceRequest => {
  return {
    ...adaptRequest(response),
    type: response.issue_type,
    subtype: response.issue_subtype,
    location: response.issue_location,
    room: response.room_with_issue,
    damagedItem: response.damaged_item,
  };
};
