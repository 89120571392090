import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      position: 'absolute',
      width: '100%',
      height: '256px',
      borderRadius: theme.spacing(0, 0, 1, 1),
      backgroundSize: 'cover',
      backgroundPosition: '0 50%',
      transition: theme.transitions.create('opacity'),
      cursor: 'pointer',
    },
  }),
);
