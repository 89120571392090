import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Types } from './type';
import { TICKETING_PATHS } from '../../shared';

export const useGetTicketingMyAccountTypesQuery = (
  country: GenericTypes.Country,
): UseQueryResult<Types | undefined> => {
  return useQuery<Types | undefined>(
    ['getTicketingMyAccountTypes', country],
    () => {
      if (!country) return undefined;

      const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.customerAccountRequest.types{
          invoiceAndCustomerAccount{
            "redirectURL": "${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}",
            "titleKey": title->key,
            "externalWebpageURL": externalWebpageUrl,
          },
          terminateContract{
            "externalWebpageURL": externalWebpageUrl,
            "titleKey": title->key,
          },
        }
      }
    `;

      return sanityClient.fetch<Types>(query, {
        country,
        type: SanityDocumentType.SETTINGS,
      });
    },
  );
};
