import React, { FC, useCallback, useState, useEffect } from 'react';
import { TranslationLabels } from '@generated/translation-labels';
import Grid from '@material-ui/core/Grid';
import * as Sentry from '@sentry/react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Dialog } from '@shared/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Icon } from '@shared/components';
import { FormV2 } from '@shared/FormV2';
import { ReactComponent as AddSquareIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-square.svg';
import { ReactComponent as MoodHappyIcon } from '@heimstaden/icons-library/img/streamline-regular/social-medias-rewards-rating/mood/mood-happy.svg';
import { useTranslation } from '@shared/translations';
import {
  useProspectCommentMutation,
  useProspectViewingMutation,
} from '@shared/http/mie/api/units/units.hook';
import { FormField } from '@shared/FormV2/components/form-field.component';
import { getConfig, getFields, Values } from './request-viewing.model';
import { useStyles } from './request-viewing.styles';
import { renderApartmentNo } from '../../../../+ticketing/ticketing.helper';

type Props = {
  prospectId: string;
  unit: {
    address: string;
    streetNumber: string;
    unitNumber: string;
  };
  lettingAgentEmail: string;
};

export const RequestViewing: FC<Props> = ({
  prospectId,
  unit,
  lettingAgentEmail,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const postComment = useProspectCommentMutation(prospectId);
  const postViewing = useProspectViewingMutation();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const handleSubmit = useCallback(
    async (content: string): Promise<void> => {
      try {
        await postComment.mutate({
          comment: { content },
          prospectId,
        });
        await postViewing.mutate({ prospectId });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [prospectId, postComment, postViewing],
  );
  const fieldsConfig = getFields(t);
  const formConfig = getConfig(handleSubmit, t);

  useEffect(() => {
    if (isOpen) {
      setSubmitted(false);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        variant="contained"
        className={classes.openButton}
        onClick={() => setOpen(true)}
      >
        <Icon
          icon={AddSquareIcon}
          height={22}
          width={22}
          className={classes.openIcon}
        />
        {t(TranslationLabels.requestViewingModalRequestViewing)}
      </Button>
      <Dialog
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        fullWidth
        showCloseIcon
      >
        {isSubmitted ? (
          <div className={classes.content}>
            <Icon
              icon={MoodHappyIcon}
              height={112}
              width={112}
              className={classes.successIcon}
            />
            <Typography className={classes.title} variant="h2">
              {t(TranslationLabels.requestViewingModalSuccessTitle)}
            </Typography>
            <Typography>
              {t(TranslationLabels.requestViewingModalSuccessDescription, {
                email: lettingAgentEmail,
              })}
            </Typography>
            <DialogActions>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => setOpen(false)}
              >
                {t(TranslationLabels.requestViewingModalSuccessClose)}
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div className={classes.content}>
            <Typography className={classes.title} variant="h2">
              {t(TranslationLabels.requestViewingModalTitle, {
                address: `${unit?.address} ${unit?.streetNumber} `,
              })}
              <span>{renderApartmentNo(t, unit?.unitNumber)}</span>
            </Typography>
            <Typography className={classes.formDescription}>
              {t(TranslationLabels.requestViewingModalSubtitle)}
            </Typography>
            <FormV2<Values>
              config={formConfig}
              fieldsConfig={fieldsConfig}
              showButtons={false}
              handleSuccess={() => setSubmitted(true)}
              onCancel={() => setOpen(false)}
            >
              {(formProps) => {
                return (
                  <>
                    <Grid container spacing={2}>
                      {fieldsConfig.map((fieldConfig) => (
                        <Grid key={fieldConfig.field.name} item xs={6}>
                          <FormField<Values>
                            fieldConfig={fieldConfig}
                            values={formProps.values}
                          />
                        </Grid>
                      ))}
                      <hr className={classes.line} />
                    </Grid>
                    <div className={classes.formButtonsRow}>
                      <Button
                        onClick={() => setOpen(false)}
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                      >
                        {t(TranslationLabels.requestViewingModalLater)}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={formProps.isSubmitting}
                        onClick={() => formProps.handleSubmit()}
                        className={classes.button}
                      >
                        {t(TranslationLabels.requestViewingModalRequestViewing)}
                      </Button>
                    </div>
                  </>
                );
              }}
            </FormV2>
          </div>
        )}
      </Dialog>
    </>
  );
};
