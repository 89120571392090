import Typography from '@material-ui/core/Typography';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from '@shared/translations';
import { useStyles } from './status.styles';

export type Status = {
  text: GenericTypes.TranslationLabel;
  colorHex: string;
};

type Props = {
  status: Status;
  className?: string;
};

export function Status({
  className,
  status: { text, colorHex },
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography
      className={clsx(classes.status, className)}
      style={{ backgroundColor: colorHex }}
    >
      <strong>{t(text)}</strong>
    </Typography>
  );
}
