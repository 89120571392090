import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import 'yup-phone';
import { ClassNameMap } from '@material-ui/styles';
import { InputValue } from '@shared/components/Fields';
import { FieldConfig, FormProps } from '@shared/FormV2';
import {
  Profile,
  PatchProfileParams,
} from '@shared/http/mie/api/auth/auth.type';

export type Values = {
  firstName: InputValue;
  lastName: InputValue;
  phoneNumber: InputValue;
  email: InputValue;
};

export const getFields = (
  classes: ClassNameMap,
  user?: Profile,
  isFormReadonly = true,
): FieldConfig<Values>[] => {
  return [
    {
      componentType: 'input',
      field: {
        name: 'firstName',
        initialValue: user?.firstName || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.profileFormFirstName,
        readonly: isFormReadonly ? Boolean(user?.firstName) : false,
        required: true,
        type: 'text',
        placeholder: TranslationLabels.profileFormFirstNamePlaceholder,
        className: classes.field,
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'lastName',
        initialValue: user?.lastName || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.profileFormLastName,
        readonly: isFormReadonly ? Boolean(user?.lastName) : false,
        required: true,
        type: 'text',
        placeholder: TranslationLabels.profileFormLastNamePlaceholder,
        className: classes.field,
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'phoneNumber',
        initialValue: user?.phoneNumber || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        helperTextKey: TranslationLabels.profileFormMobilePhoneHelperText,
        label: TranslationLabels.profileFormMobilePhone,
        readonly: Boolean(user?.phoneNumber),
        required: true,
        type: 'text',
        placeholder: TranslationLabels.profileFormPhonePlaceholder,
        className: classes.field,
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'email',
        initialValue: user?.email || '',
        validationSchema: Yup.string()
          .email(TranslationLabels.formErrorsEmail)
          .required(TranslationLabels.formErrorsRequired),
      },
      props: {
        label: TranslationLabels.profileFormEmail,
        readonly: Boolean(user?.email),
        required: true,
        type: 'text',
        placeholder: TranslationLabels.profileFormEmailPlaceholder,
        className: classes.field,
      },
    },
  ];
};

export const getConfig = (
  onUpdateProfile: (request: PatchProfileParams) => Promise<Profile>,
): FormProps<Values>['config'] => ({
  onSubmit: async (values): Promise<void> => {
    try {
      await onUpdateProfile({ ...values, personalDetailsConfirmed: true });
    } catch (e) {
      // todo: MYH-1497
      // use new error translation with email & phone arguments
      throw new Error(TranslationLabels.formGlobalErrorMessage);
    }
  },
  validateOnMount: true,
});
