import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      padding: theme.spacing(2, 0, 1),
    },
    button: {
      backgroundColor: palette.white,
      zIndex: 1,
      height: 46,
    },
    closeIcon: {
      marginLeft: theme.spacing(2),
    },
    content: {
      minHeight: 200,
      padding: theme.spacing(2, 0, 0),
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    titleIcon: {
      color: palette.internationalOrange,
      marginRight: theme.spacing(1),
    },
    spinner: {
      marginBottom: theme.spacing(2),
      height: `${theme.spacing(8)}px !important`,
    },
    infiniteScroll: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    smallButton: {
      height: theme.spacing(4),
    },
    smallIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
);
