import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import { useAnimatedRoutes } from '@shared/animated-routes';
import { generatePath } from 'react-router';
import { AvailableFor, Icon, Tooltip } from '@shared/components';
import { useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import React, { FC, ReactElement, ReactNode, useMemo } from 'react';
import { icons } from '../../consts';
import { FallbackMessage } from '../FallbackMessage/fallback-message.component';
import { transform } from './elements.helper';
import { useStyles } from './elements.styles';
import { Data } from './elements.type';
import { RouteParams } from '../../../ServiceRequest/service-request.type';

type Props = {
  data: Record<string, Data> | Data[] | undefined;
  otherElementRedirectURL?: string;
  dataTestPrefix?: string;
};

export const Elements: FC<Props> = (props) => {
  const { data, otherElementRedirectURL, dataTestPrefix } = props;
  const classes = useStyles();
  const country = useCountry();
  const { goTo } = useAnimatedRoutes();
  const { unitId } = useParams<RouteParams>();
  const { t } = useTranslation();
  const elements = useMemo(() => data && transform(data), [data]);
  const validElements = elements?.filter((element) => element.titleKey);

  if (!otherElementRedirectURL && (!elements || elements.length === 0)) {
    return (
      <FallbackMessage
        messageKey={TranslationLabels.ticketingNoElementsMessage}
      />
    );
  }

  const getElement = <T extends Data>(element: T): ReactNode | void => {
    const { externalWebpageURL, key, redirectURL, roles, titleKey } = element;
    const getButtonTemplate = (isDisabled = false): ReactElement => {
      const additionalProps = externalWebpageURL
        ? {
            href: externalWebpageURL,
            rel: 'noopener noreferrer',
            target: '_blank',
          }
        : {
            onClick: () =>
              goTo(
                generatePath(redirectURL, {
                  unitId,
                }),
              ),
          };
      const isKeyNumeric = !Number.isNaN(Number(key));

      return (
        <Button
          {...(!isDisabled ? additionalProps : {})}
          className={classes.element}
          classes={{ label: classes.buttonLabel }}
          disabled={isDisabled}
          key={key}
          variant="contained"
          data-test={`${dataTestPrefix}:${key}`}
        >
          {/* TODO: Find a better solution to display icons */}
          {isKeyNumeric && icons[country][+key] && (
            <Icon
              className={clsx(classes.icon, classes.elementIcon)}
              icon={icons[country][+key]}
              height={40}
              width={40}
            />
          )}
          {t(titleKey)}
        </Button>
      );
    };

    return (
      <AvailableFor
        fallback={<Tooltip>{getButtonTemplate(true)}</Tooltip>}
        key={key}
        availableForRoles={roles}
        currentUnitId={unitId}
      >
        {getButtonTemplate()}
      </AvailableFor>
    );
  };

  return (
    <div className={classes.list} data-test={`elements-${dataTestPrefix}`}>
      {(validElements || []).map((element) => getElement(element))}
      {otherElementRedirectURL &&
        getElement({
          key: otherElementRedirectURL,
          redirectURL: otherElementRedirectURL,
          titleKey: TranslationLabels.ticketingServiceRequestOtherLabel,
        })}
    </div>
  );
};
