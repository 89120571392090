import { TranslationLabels } from '@generated/translation-labels';
import { IntroProps } from '../../../shared/components/Intro';

export const getIntroProps = (
  isChangeCustomerInfoRequest: boolean,
): IntroProps => {
  if (isChangeCustomerInfoRequest) {
    return {
      titleKey:
        TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
      descriptionKey:
        TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsDescription,
    };
  }

  return {
    titleKey: TranslationLabels.ticketingGeneralTitle,
    descriptionKey: TranslationLabels.ticketingGeneralText,
  };
};
