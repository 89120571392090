import { useUnitQuery } from '@shared/http/mie';
import { useProfileQuery } from '@shared/http/mie/api/auth/auth.hook';
import * as Sentry from '@sentry/react';
import { UserGlobalRole, UserUnitRole } from '@shared/enums';

export const useAvailableFor = ({
  availableForRoles,
  currentUnitId,
}: {
  availableForRoles: (UserGlobalRole | UserUnitRole)[];
  currentUnitId?: string;
}): {
  isAvailable: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
} => {
  const {
    data: unit,
    isLoading: isLoadingUnit,
    isError: isErrorUnit,
    error: errorUnit,
  } = useUnitQuery(currentUnitId);

  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
  } = useProfileQuery();

  if (isLoadingUnit || isLoadingProfile) {
    return {
      isLoading: true,
      isError: false,
      isAvailable: false,
    };
  }

  if (isErrorUnit || isErrorProfile) {
    Sentry.captureException(errorUnit || errorProfile);
    return {
      isLoading: false,
      isError: true,
      isAvailable: false,
      error: errorUnit || errorProfile,
    };
  }

  if (
    availableForRoles.some(
      (role) =>
        !currentUnitId &&
        Object.values(UserUnitRole).includes(role as UserUnitRole),
    )
  ) {
    // eslint-disable-next-line no-console
    console.error('Missing "currentUnitId" in AvailableFor component');
    Sentry.captureException(
      'Missing "currentUnitId" in AvailableFor component',
    );
    return {
      isLoading: false,
      isError: true,
      isAvailable: false,
      error: 'Missing "currentUnitId" in AvailableFor component',
    };
  }

  const globalRoles = profile?.roles || [];
  const unitRoles = unit?.myRoles || [];

  const isCustomer = globalRoles.some((r) => r === 'Tenant');
  const isProspect = globalRoles.some((r) => r === 'Prospect');
  const isTenant = unitRoles.some((r) => r === 'Tenant' || r === 'Co-Tenant');
  const isResident = unitRoles.some((r) => r === 'Resident');

  return {
    isLoading: false,
    isError: false,
    isAvailable:
      (availableForRoles.includes(UserGlobalRole.CUSTOMER) && isCustomer) ||
      (availableForRoles.includes(UserGlobalRole.PROSPECT) && isProspect) ||
      (availableForRoles.includes(UserUnitRole.TENANT) && isTenant) ||
      (availableForRoles.includes(UserUnitRole.RESIDENT) && isResident),
  };
};
