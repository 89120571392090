import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Icon, Spinner } from '@shared/components';
import { ReactComponent as MessagesBubbleDoubleIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-double.svg';
import { Dialog } from '@shared/Dialog';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { Comment as CommentType, WithId } from './comments.type';
import { useStyles } from './comments.styles';
import { Comment } from './Comment/comment.component';
import { CommentForm } from './CommentForm/comment-form.component';

type Props<MieCommentGeneric> = {
  titleComponent?: ReactNode;
  titleKey?: GenericTypes.TranslationLabel;
  onSubmitComment: (content: string) => Promise<void>;
  allComments: CommentType[] | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<
    InfiniteQueryObserverResult<
      GenericTypes.Paginated<MieCommentGeneric>,
      unknown
    >
  >;
  hasNextPage?: boolean;
  isLoading: boolean;
};

export const CommentsModal = <T extends WithId>(
  props: Props<T>,
): ReactElement | null => {
  const {
    titleComponent,
    titleKey,
    onSubmitComment,
    fetchNextPage,
    hasNextPage,
    allComments,
    isLoading,
  } = props;
  const history = useHistory();

  const classes = useStyles();
  const commentsSectionEndRef = useRef<HTMLDivElement>(null);
  const scrollToLastComment = useCallback(() => {
    commentsSectionEndRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    scrollToLastComment();
  }, [scrollToLastComment]);

  return (
    <Dialog
      fullWidth
      isOpen
      titleComponent={titleComponent}
      titleKey={titleKey}
      titleIcon={
        <Icon
          className={classes.titleIcon}
          icon={MessagesBubbleDoubleIcon}
          height={32}
          width={32}
        />
      }
      onClose={() => history.goBack()}
      onEnter={scrollToLastComment}
      showCloseIcon
    >
      <DialogContent className={classes.content} dividers id="scrollableDiv">
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={allComments?.length || 0}
          next={fetchNextPage}
          hasMore={Boolean(hasNextPage)}
          loader={<Spinner className={classes.spinner} />}
          inverse
          scrollableTarget="scrollableDiv"
        >
          {isLoading && <Spinner className={classes.spinner} />}
          <div ref={commentsSectionEndRef} />
          <div>
            {allComments?.map((comment) => (
              <Comment comment={comment} key={`comment-${comment.date}`} />
            ))}
          </div>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions className={classes.actions} disableSpacing>
        <CommentForm onSubmitComment={onSubmitComment} />
      </DialogActions>
    </Dialog>
  );
};
