import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      padding: theme.spacing(4, 1, 1, 1),
    },
    title: {
      fontWeight: 'bold',
    },
    formDescription: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    button: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(3),
    },
    openButton: {
      fontSize: theme.typography.pxToRem(14),
      backgroundColor: palette.orange10,
      color: palette.internationalOrange,
    },
    openIcon: {
      marginRight: theme.spacing(1),
      '& path': {
        strokeWidth: 2,
      },
    },
    successIcon: {
      paddingBottom: theme.spacing(1),
      '& path': {
        stroke: palette.internationalOrange,
        strokeWidth: 0.5,
      },
    },
    line: {
      backgroundColor: palette.lightGray,
      height: 1,
      border: 0,
      width: '100%',
      margin: theme.spacing(1),
    },
    selectField: {
      color: palette.lightGray,
    },
    formButtonsRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      marginTop: theme.spacing(2),
      '& button': {
        marginRight: theme.spacing(1),
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  }),
);
