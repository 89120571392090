import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import { getFirstError, SelectOption } from '@shared/components';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from '@shared/translations';
import { SelectProps, SelectValue } from './types';
import { useStyles } from './select-field.styles';

type Props<TFormValues> = FormikFieldProps<SelectValue, TFormValues> & {
  props: SelectProps;
};

export function SelectFieldV2<TFormValues>({
  meta,
  form,
  field,
  props,
}: Props<TFormValues>): ReturnType<FC<Props<TFormValues>>> {
  const classes = useStyles();
  const { t } = useTranslation();

  const { touched, error, value } = meta;
  const { setFieldValue } = form;
  const { name, onBlur } = field;
  const {
    options,
    disabled,
    required,
    readonly,
    label,
    className,
    isMultiple,
  } = props;

  const labelId = `${name}-label`;
  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setFieldValue(name, event.target.value);
  };
  const errorMessage = touched && getFirstError(t, error);
  const isError = Boolean(errorMessage);
  const getOption = (
    selectedValue: SelectOption['value'],
  ): SelectOption | undefined =>
    (options || [])
      .map(({ label, ...rest }) => ({ ...rest, label: t(label) }))
      .find(({ value }) => selectedValue === value);
  const readonlyValue = value ? getOption(value) : undefined;

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={required && !readonly}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly className={className} value={readonlyValue?.label} />
      ) : (
        <>
          <Select
            data-test={`select-field:${name}`}
            {...(isMultiple && {
              multiple: true,
              renderValue: (selected) => (
                <div className={classes.chips}>
                  {(selected as SelectOption['value'][]).map(
                    (selectedValue: SelectOption['value']) => {
                      const option = getOption(selectedValue);

                      return option ? (
                        <Chip
                          className={classes.chip}
                          key={selectedValue}
                          label={option.label}
                        />
                      ) : null;
                    },
                  )}
                </div>
              ),
            })}
            classes={{
              icon: classes.icon,
            }}
            error={isError}
            id={name}
            input={<OutlinedInput id={name} />}
            labelId={labelId}
            onBlur={onBlur}
            onChange={handleChange}
            value={value}
          >
            {(options || []).map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
          {errorMessage && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
}
