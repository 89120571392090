import { PaginatedMie, MieUnitUserRole } from '@shared/http/mie';
import { MieComment, PostCommentAPIParams } from '../ticket/type';

export type MieUnit = {
  uuid: string;
  name: string;
  leaseStartDate: GenericTypes.Date;
  leaseEndDate: GenericTypes.Date;
  city: {
    name: string;
  };
  addressLine: string;
  streetNumber: string;
  postalCode: string;
  thumbnail: GenericTypes.URL;
  images: GenericTypes.URL[];
  unitNumber: string;
  myRoles: MieUnitUserRole[];
  contractPdf?: {
    url: GenericTypes.URL;
  };
  propertyObjectUuid: string;
  propertyUuid: string;
};

export type GetMyUnitsResponse = PaginatedMie<MieUnit>;
export type GetUnitByIdResponse = MieUnit;

// -----------------------------------------------------------------------------

export enum ProspectStatus {
  PROSPECT_REGISTERED = 'prospect_registered',
  PROSPECT_CONFIRMED = 'prospect_confirmed_by_user',
  PROSPECT_CANCELED = 'prospect_cancelled',
  PROSPECT_REJECTED = 'prospect_rejected',
  VIEWING_REQUESTED = 'viewing_requested',
  VIEWING_DATE_CONFIRMED = 'viewing_date_confirmed',
  VIEWED = 'viewed',
  VIEWING_CANCELED = 'viewing_canceled',
  VIEWING_FAILED = 'viewing_failed',
}

type MieProspectVerification = {
  uuid: string;
  verificationUrl: GenericTypes.URL;
};

type ProspectVerification = {
  id: string;
  url: GenericTypes.URL;
};

export type MieProspectUnit = {
  uuid: string;
  unit: {
    uuid: string;
    addressLine: string;
    streetNumber: string;
    unitNumber: string;
    city: {
      name: string;
    };
  };
  status: ProspectStatus;
  lettingAgent: {
    uuid: string;
    firstName: string;
    lastName: string;
    profilePicture?: GenericTypes.URL;
    email: string;
  };
  agreedDatetimeForViewing?: GenericTypes.Date;
  prospectVerifications: MieProspectVerification[];
};

export type ProspectUnit = {
  id: string;
  status: ProspectStatus;
  unit: {
    id: string;
    address: string;
    streetNumber: string;
    unitNumber: string;
    city: {
      name: string;
    };
  };
  lettingAgent: {
    id: string;
    firstName: string;
    lastName: string;
    imageUrl?: GenericTypes.URL;
    email: string;
  };
  agreedDatetimeForViewing?: GenericTypes.Date;
  prospectVerifications: ProspectVerification[];
};

export type MieProspectPublicUnit = {
  uuid: string;
  unit: {
    addressLine: string;
    streetNumber: string;
    unitNumber: string;
    city: {
      name: string;
    };
    thumbnail: string;
  };
};

export type ProspectPublicUnit = {
  id: string;
  address: string;
  streetNumber: string;
  unitNumber: string;
  city: {
    name: string;
  };
  thumbnail: string;
};

export type MieAttachmentType =
  | 'house_rules'
  | 'manuals'
  | 'contract_pdf'
  | 'pdf'
  | 'floor_plan'
  | 'other';

export type MieAttachment = {
  uuid: string;
  url: GenericTypes.URL;
  title: string;
  name: string;
  type: MieAttachmentType;
  contentType: 'application/pdf';
};

export type GetProspectUnitsResponse = PaginatedMie<MieProspectUnit>;

export type GetUnitAttachmentsResponse = PaginatedMie<MieAttachment>;

export type GetPropertyAttachmentsResponse = PaginatedMie<MieAttachment>;

export type GetProspectCommentsResponse = PaginatedMie<MieComment>;

export type PostProspectCommentMutationParams = {
  comment: PostCommentAPIParams;
  prospectId: string;
};

export type PostProspectViewingAPIParams = undefined;

export type PostProspectViewingMutationParams = {
  prospectId: string;
};
