import React, { FC, useCallback, useEffect, useState } from 'react';
import { useCountry } from '@shared/hooks';
import { useSnackbar } from 'notistack';
import { MaintenanceSnackbar } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { api } from '../maintenance.repository';
import { useAuth } from '../../../+auth';
import { Context, initialContextValue } from './context';

export const Provider: FC = ({ children }) => {
  const { user, logout } = useAuth();
  const country = useCountry();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t, langCode } = useTranslation();
  const [isNotified, setNotified] = useState(false);
  const [maintenance, setMaintenance] = useState(initialContextValue);

  const getData$ = useCallback(async () => {
    const response = await api.getStatus(country);

    setMaintenance(response);
  }, [country]);

  const handleInteractionMode = useCallback(() => {
    if (maintenance.enabled && !maintenance.interactionEnabled && user) {
      logout();
    }
  }, [user, logout, maintenance.interactionEnabled, maintenance.enabled]);

  const showSnackbar = useCallback(() => {
    enqueueSnackbar(
      <MaintenanceSnackbar
        title={t(maintenance.titleKey)}
        description={t(maintenance.descriptionKey)}
      />,
      {
        persist: true,
        preventDuplicate: true,
        variant: 'warning',
      },
    );
  }, [enqueueSnackbar, maintenance.descriptionKey, maintenance.titleKey, t]);

  const notifyAboutMaintenance = useCallback(() => {
    if (!maintenance.enabled || isNotified) {
      return;
    }

    showSnackbar();
    setNotified(true);
  }, [isNotified, maintenance.enabled, showSnackbar]);

  const closeNotification = useCallback(() => {
    closeSnackbar();
    setNotified(false);
  }, [closeSnackbar]);

  const resetState = useCallback(() => {
    closeNotification();
    setMaintenance(initialContextValue);
  }, [closeNotification]);

  useEffect(() => {
    handleInteractionMode();
    notifyAboutMaintenance();
  }, [handleInteractionMode, notifyAboutMaintenance]);

  useEffect(() => {
    closeNotification();
  }, [closeNotification, langCode]);

  useEffect(() => {
    resetState();
  }, [country, resetState]);

  useEffect(() => {
    getData$();
  }, [getData$]);

  return <Context.Provider value={maintenance}>{children}</Context.Provider>;
};
