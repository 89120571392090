import React, { ReactElement } from 'react';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { Icon } from '@shared/components';
import { TranslationLabels } from '@generated/translation-labels';
import { useTranslation } from '@shared/translations';
import { InfiniteData } from 'react-query';
import clsx from 'clsx';
import { ReactComponent as MessagesBubbleDoubleIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-double.svg';
import { useStyles } from './comments.styles';
import { WithId } from './comments.type';

type CommentsButtonProps<MieCommentGeneric> = {
  small?: boolean;
  commentsData?: InfiniteData<GenericTypes.Paginated<MieCommentGeneric>>;
};

export const CommentsButton = <T extends WithId>({
  commentsData,
  small,
}: CommentsButtonProps<T>): ReactElement | null => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      className={clsx(classes.button, { [classes.smallButton]: small })}
      color="primary"
      variant="outlined"
    >
      {t(TranslationLabels.ticketingDetailsCommentsOpenButtonLabel)}
      <Icon
        className={clsx(classes.closeIcon, {
          [classes.smallIcon]: small,
        })}
        icon={MessagesBubbleDoubleIcon}
        height={22}
        width={22}
      />
      <Badge
        badgeContent={commentsData?.pages[0]?.count || 0}
        color="primary"
      />
    </Button>
  );
};
