import Typography from '@material-ui/core/Typography';
import React, { FC, MouseEvent, SVGProps } from 'react';
import { Button, Grid } from '@material-ui/core';
import { ReactComponent as ArrowRight } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import { useTranslation } from '@shared/translations';
import { Icon } from '@shared/components';
import { useStyles } from './fancy-info-box.styles';

type Props = {
  img?: {
    component: FC<SVGProps<SVGSVGElement>>;
    maxWidth: string;
    maxHeight: string;
  };
  textKey: GenericTypes.TranslationLabel;
  textParams: Record<string, string>;
  button?: {
    textKey: GenericTypes.TranslationLabel;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    href?: string;
  };
};

export const FancyInfoBox: FC<Props> = (props) => {
  const { img, textKey, textParams, button } = props;
  const Img = img?.component as FC<SVGProps<SVGSVGElement>>;
  const classes = useStyles({
    imgMaxWidth: img?.maxWidth,
    imgMaxHeight: img?.maxHeight,
  });
  const { t } = useTranslation();

  return (
    <div className={classes.box}>
      <Grid container alignItems="center" justify="center">
        {img && (
          <Grid item xs={12} sm={3} lg="auto" className={classes.imgWrapper}>
            <Img className={classes.img} />
          </Grid>
        )}
        <Grid item xs={12} sm={6} lg="auto" zeroMinWidth>
          <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: t(textKey, textParams) }}
          />
        </Grid>
        {button && (
          <Grid item xs={12} sm={3} lg="auto" className={classes.buttonWrapper}>
            <Button
              color="primary"
              variant="outlined"
              onClick={button.onClick}
              href={button.href}
            >
              {t(button.textKey)}{' '}
              <Icon
                icon={ArrowRight}
                width={16}
                height={16}
                className={classes.buttonIcon}
              />
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
