import { AxiosInstance, ResponseType } from 'axios';
import {
  HttpClientOptions,
  HttpHeaders,
  HttpMethod,
  Params,
} from './http.type';

export class HttpClient {
  public instance: AxiosInstance;

  constructor(instance: AxiosInstance, options?: HttpClientOptions) {
    this.instance = instance;

    if (options?.headers) {
      this.instance.interceptors.request.use((req) => {
        req.headers = {
          ...req.headers,
          ...options.headers,
        };
        return req;
      });
    }
  }

  public setHeaders = (headers: HttpHeaders): void => {
    const prevHeaders = this.instance.defaults.headers;
    this.instance.defaults.headers = {
      ...prevHeaders,
      ...headers,
    };
  };

  public delete<PAYLOAD, RESPONSE>(
    url: string,
    params?: Params,
    responseType?: ResponseType,
  ): Promise<RESPONSE> {
    return this.request<PAYLOAD, RESPONSE>(
      url,
      HttpMethod.DELETE,
      undefined,
      params,
      responseType,
    );
  }

  public get<RESPONSE>(
    url: string,
    params?: Params,
    responseType?: ResponseType,
  ): Promise<RESPONSE> {
    return this.request<undefined, RESPONSE>(
      url,
      HttpMethod.GET,
      undefined,
      params,
      responseType,
    );
  }

  public patch<PAYLOAD, RESPONSE>(
    url: string,
    data: PAYLOAD,
    params?: Params,
    responseType?: ResponseType,
  ): Promise<RESPONSE> {
    return this.request<PAYLOAD, RESPONSE>(
      url,
      HttpMethod.PATCH,
      data,
      params,
      responseType,
    );
  }

  public post<PAYLOAD, RESPONSE>(
    url: string,
    data?: PAYLOAD,
    params?: Params,
    responseType?: ResponseType,
  ): Promise<RESPONSE> {
    return this.request<PAYLOAD, RESPONSE>(
      url,
      HttpMethod.POST,
      data,
      params,
      responseType,
    );
  }

  public put<PAYLOAD, RESPONSE>(
    url: string,
    data: PAYLOAD,
    params?: Params,
    responseType?: ResponseType,
  ): Promise<RESPONSE> {
    return this.request<PAYLOAD, RESPONSE>(
      url,
      HttpMethod.PUT,
      data,
      params,
      responseType,
    );
  }

  private request<PAYLOAD, RESPONSE>(
    url: string,
    method: HttpMethod,
    data?: PAYLOAD,
    params?: Params,
    responseType?: ResponseType,
  ): Promise<RESPONSE> {
    return (this.instance.request<RESPONSE>({
      data,
      headers: this.instance.defaults.headers,
      method,
      params,
      url,
      responseType,
    }) as unknown) as Promise<RESPONSE>;
  }
}
