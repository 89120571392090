import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import * as Sentry from '@sentry/react';
import { unitsApi, ProspectUnit } from '@shared/http/mie';
import { Unit, Attachment } from '../../../../../+apartment/apartment.type';
import { Comment } from '../ticket/type';
import {
  PostProspectCommentMutationParams,
  ProspectPublicUnit,
  PostProspectViewingMutationParams,
  MieAttachmentType,
} from './units.type';

export const useUnitsQuery = (
  pagination: GenericTypes.Pagination,
  sort?: GenericTypes.Sort<keyof Unit>,
): UseQueryResult<GenericTypes.Paginated<Unit>> => {
  const units = useQuery<GenericTypes.Paginated<Unit>>(
    [
      'myUnits',
      pagination.page,
      pagination.pageSize,
      sort?.sortBy,
      sort?.order,
    ],
    () => unitsApi.getMyUnits(pagination, sort),
    { keepPreviousData: true },
  );
  if (units.data?.results?.length === 0) {
    Sentry.captureException(new Error(`User don't have any active lease.`));
  }
  return units;
};

export const useUnitQuery = (id?: string): UseQueryResult<Unit | undefined> => {
  return useQuery<Unit | undefined>(['myUnitById', id], () => {
    if (!id) return undefined;
    return unitsApi.getUnitById(id);
  });
};

export const useProspectUnitsQuery = (
  pagination: GenericTypes.Pagination,
  sort?: GenericTypes.Sort<keyof ProspectUnit>,
): UseQueryResult<GenericTypes.Paginated<ProspectUnit>> => {
  const prospectUnits = useQuery<GenericTypes.Paginated<ProspectUnit>>(
    [
      'prospectUnits',
      pagination.page,
      pagination.pageSize,
      sort?.sortBy,
      sort?.order,
    ],
    () => unitsApi.getProspectUnits(pagination, sort),
    { keepPreviousData: true },
  );
  return prospectUnits;
};

export const useUnitAttachmentsQuery = (
  unitId?: string,
  pagination?: GenericTypes.Pagination,
  category?: string,
  types?: MieAttachmentType[],
): UseQueryResult<GenericTypes.Paginated<Attachment> | undefined> => {
  return useQuery<GenericTypes.Paginated<Attachment> | undefined>(
    [
      'unitAttachments',
      types,
      pagination?.page,
      pagination?.pageSize,
      category,
    ],
    () => {
      if (!unitId) return undefined;
      return unitsApi.getUnitAttachments(unitId, pagination, category, types);
    },
  );
};

export const usePropertyAttachmentsQuery = (
  propertyId?: string,
  pagination?: GenericTypes.Pagination,
  category?: string,
): UseQueryResult<GenericTypes.Paginated<Attachment> | undefined> => {
  return useQuery<GenericTypes.Paginated<Attachment> | undefined>(
    [
      'propertyAttachments',
      propertyId,
      pagination?.page,
      pagination?.pageSize,
      category,
    ],
    () => {
      if (!propertyId) return undefined;
      return unitsApi.getPropertyAttachments(propertyId, pagination, category);
    },
  );
};

export const usePropertyObjectAttachmentsQuery = (
  propertyObjectId?: string,
  pagination?: GenericTypes.Pagination,
  category?: string,
): UseQueryResult<GenericTypes.Paginated<Attachment> | undefined> => {
  return useQuery<GenericTypes.Paginated<Attachment> | undefined>(
    [
      'propertyObjectAttachments',
      propertyObjectId,
      pagination?.page,
      pagination?.pageSize,
      category,
    ],
    () => {
      if (!propertyObjectId) return undefined;
      return unitsApi.getPropertyObjectAttachments(
        propertyObjectId,
        pagination,
        category,
      );
    },
  );
};

export const useProspectCommentsQuery = (
  id: string,
  pagination: GenericTypes.Pagination,
): UseInfiniteQueryResult<GenericTypes.Paginated<Comment>> => {
  return useInfiniteQuery(
    ['comments', id, pagination.page, pagination.pageSize],
    ({ pageParam }: { pageParam?: number }) => {
      return unitsApi.getProspectComments(id, {
        pageSize: pagination.pageSize,
        page: pageParam || pagination.page,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next;
      },
    },
  );
};

export const useProspectCommentMutation = (
  prospectId: string,
): UseMutationResult<Comment, Error, PostProspectCommentMutationParams> => {
  const queryClient = useQueryClient();
  return useMutation<Comment, Error, PostProspectCommentMutationParams>(
    (params) => {
      return unitsApi.postComment(params.prospectId, params.comment);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['comments', prospectId]);
      },
    },
  );
};

export const usePublicProspectQuery = (
  token?: string,
): UseQueryResult<ProspectPublicUnit | undefined> => {
  return useQuery(['publicProspect', token], () => {
    if (!token) return undefined;
    return unitsApi.getProspectsPublicInfo(token);
  });
};

export const useProspectViewingMutation = (): UseMutationResult<
  ProspectUnit,
  Error,
  PostProspectViewingMutationParams
> => {
  const queryClient = useQueryClient();
  return useMutation<ProspectUnit, Error, PostProspectViewingMutationParams>(
    (params) => {
      return unitsApi.postProspectViewing(params.prospectId);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['prospectUnits']);
      },
    },
  );
};
