import { mieCatalogClient } from '../../mie-catalog.client';
import { UnitNearby } from '../../../../../+prospects/types';
import { GetUnitsNearbyResponse, MieUnitNearby } from './catalog.type';
import { adaptUnitNearby } from './catalog.adapter';
import { adaptMiePaginated, createMieOrder } from '../../mie.helper';

export const catalogApi = {
  getAvailableUnitsNearby: async (
    unitId: UnitNearby['id'],
    pagination: GenericTypes.Pagination,
    sort?: GenericTypes.Sort<keyof UnitNearby>,
  ): Promise<GenericTypes.Paginated<UnitNearby>> => {
    const response = await mieCatalogClient.get<GetUnitsNearbyResponse>(
      `/units/${unitId}/nearby`,
      {
        page: pagination.page,
        page_size: pagination.pageSize,
        ...(sort && { order: createMieOrder<keyof UnitNearby>(sort) }),
      },
    );

    return adaptMiePaginated<MieUnitNearby, UnitNearby>(
      response,
      adaptUnitNearby,
    );
  },
};
