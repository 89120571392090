import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { Content, Elements } from '../../../shared';
import { ReactComponent as InvoicesImage } from '../../../assets/invoices.svg';
import { stepper } from '../../stepper.config';
import { useGetItemsQuery } from './items.hook';

export const Items: FC = () => {
  const country = useCountry();
  const { data: items, isLoading } = useGetItemsQuery(country);

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
        childrenRightColumn: <InvoicesImage />,
      }}
      isFetching={isLoading}
      type="columns"
      stepperConfig={stepper}
    >
      <Elements data={items} dataTestPrefix="items" />
    </Content>
  );
};
