import { useEffect, useState } from 'react';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useTheme } from '@material-ui/core/styles';

type Result = {
  isDown: boolean;
  isUp: boolean;
  isEqual: boolean;
};

export const useBreakpoint = (breakpoint: Breakpoint): Result => {
  const theme = useTheme();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const value = theme.breakpoints.values[breakpoint];

  useEffect(() => {
    const handleResize = (): void => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return {
    isDown: innerWidth < value,
    isUp: innerWidth > value,
    isEqual: innerWidth === value,
  };
};
