import React, { FC } from 'react';
import { useCountry } from '@shared/hooks';
import { useStyles } from './app-logo.styles';
import { useGetAppLogoQuery } from './app-logo.hook';

export const AppLogo: FC = () => {
  const classes = useStyles();
  const country = useCountry();
  const { data: appLogoUrl } = useGetAppLogoQuery(country);

  return (
    <img
      src={appLogoUrl}
      alt="MyHome application logo"
      className={classes.appLogo}
    />
  );
};
