import { Action, State } from './type';

export const initialState: State = {
  user: undefined,
  auth: undefined,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'AUTH_INIT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
