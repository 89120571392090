import { useQuery, UseQueryResult } from 'react-query';
import { wordpressClient } from '@shared/http';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Post, PostResponse } from './blog.type';

export const useGetPostsQuery = (
  country: GenericTypes.Country,
): UseQueryResult<Post[] | undefined> => {
  return useQuery<Post[] | undefined>(['getBlogPosts', country], async () => {
    if (!country) return undefined;

    const response = await wordpressClient(country).get<PostResponse[]>(
      'posts',
      {
        per_page: 3,
        order: 'desc',
        _embed: 'wp:featuredmedia',
      },
    );

    return response.map(({ id, title, link, embedded }) => ({
      id,
      title: title.rendered,
      link,
      imageUrl:
        embedded?.wpFeaturedmedia?.[0]?.mediaDetails?.sizes.full.sourceUrl,
    }));
  });
};

export const useBlogUrlQuery = (
  country: GenericTypes.Country,
): UseQueryResult<GenericTypes.URL | undefined> => {
  return useQuery<GenericTypes.URL | undefined>(
    ['getBlogUrl', country],
    async () => {
      if (!country) return undefined;

      const response = await sanityClient.fetch(
        `* [_type == $type && content.country == $country]{
      "url": content.blogUrl,
      }[0]`,
        {
          country,
          type: SanityDocumentType.SETTINGS,
        },
      );
      return response.url;
    },
  );
};
