import { useContext } from 'react';
import { Context, ContextValue } from '../state';
import { logout, refreshToken } from '../auth.helpers';

type UseAuth = ContextValue & {
  logout: (returnTo?: GenericTypes.URL) => void;
  refreshToken: () => Promise<void>;
};

export const useAuth = (): UseAuth => ({
  ...useContext(Context),
  logout,
  refreshToken,
});
