import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/index';

export const useStyles = makeStyles((theme) =>
  createStyles({
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    elementTitle: {
      display: 'block',
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(0.5),
    },
    elementIcon: {
      '& path': {
        stroke: palette.shark,
      },
    },
    requestElements: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    status: {
      margin: theme.spacing(0, -1, 0, 3),
    },
    statusContainer: {
      width: '300px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    elementButton: {
      marginRight: 'unset',
    },
  }),
);
