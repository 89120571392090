import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { ErrorScreen } from '@shared/components';
import { useHistory } from 'react-router';

const NotFoundError: FC = () => {
  const history = useHistory();

  return (
    <ErrorScreen
      titleKey={TranslationLabels.notFoundErrorTitle}
      textKey={TranslationLabels.notFoundErrorText}
      button={{
        textKey: TranslationLabels.goBackButtonText,
        onClick: history.goBack,
      }}
    />
  );
};
export default NotFoundError;
