import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../shared';

export const useStyles = makeStyles((theme) =>
  createStyles({
    containerFluid: {
      backgroundColor: palette.athensGray,
      margin: theme.spacing(4, 0, 0),
      padding: theme.spacing(4, 0),
    },
    wrapper: {
      height: '100%',
      position: 'absolute',
      width: '100%',
    },
    information: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'none',
      width: '100%',
      zIndex: 2,
      cursor: 'pointer',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.12)',
      [theme.breakpoints.up('md')]: {
        width: 514,
        top: '50%',
        left: '10%',
        transform: 'translateY(-50%)',
      },
    },
    image: {
      borderRadius: theme.spacing(1),
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingTop: theme.spacing(2),
    },
    singleProperty: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    button: {
      marginTop: 'auto',
      marginLeft: 'auto',
    },
    carouselButton: {
      '& path': {
        stroke: palette.shark,
        strokeWidth: 1.0,
      },
    },
    coverPhoto: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: theme.spacing(1),
      width: '100%',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 1,
    },
    carousel: {
      height: 300,
    },
    [theme.breakpoints.down('sm')]: {
      wrapper: {
        height: 'auto',
      },
      information: {
        flexDirection: 'column',
        width: '100%',
      },
      coverPhoto: {
        display: 'none',
      },
      text: {
        paddingTop: 0,
      },
      carousel: {
        height: 180,
      },
    },
  }),
);
