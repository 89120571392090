import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formControlRoot: {
      display: 'block',
    },
    helperText: {
      marginBottom: theme.spacing(1),
      color: palette.doveGray,
    },
    dropzone: {
      alignItems: 'center',
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(1),
      cursor: 'pointer',
      display: 'flex',
      height: 80,
      justifyContent: 'center',
      padding: theme.spacing(1),
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
    },
    disabled: {
      cursor: 'default',
      color: theme.palette.text.disabled,

      '& $icon > path': {
        stroke: theme.palette.text.disabled,
      },
    },
  }),
);
