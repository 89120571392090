import { TFunction } from 'react-i18next';
import { TranslationLabels } from '@generated/translation-labels';
import { ElementName, Ticket } from '@shared/http/mie/api/ticket/type';

export const getTypeLabel = (
  ticket: Ticket,
  t: TFunction,
  elementNames?: ElementName[],
): string => {
  switch (ticket.ticketType) {
    case 'service_request': {
      const levels = [
        ticket?.roomWithIssue,
        ticket?.damagedItem,
        ticket?.issueSubtype,
      ];

      return levels
        .map(
          (level) =>
            elementNames?.find((element) => element.id === level)?.titleKey,
        )
        .map((key) => (key ? t(key) : ''))
        .filter((key) => key !== '')
        .join(' - ');
    }
    case 'complaint': {
      return t(TranslationLabels.issueComplaintRequestTitle);
    }
    case 'invoice_request': {
      return t(TranslationLabels.invoiceAndCustomerAccountTitle);
    }
    case 'general_request': {
      return t(TranslationLabels.customerServiceRequestTitle);
    }
    case 'contract_request': {
      return t(TranslationLabels.terminateContractRequestTitle);
    }
    default:
      return '';
  }
};
