import { TranslationLabels } from '@generated/translation-labels';
import { ClassNameMap } from '@material-ui/styles';
import { FormikConfig } from 'formik';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
  Attachment,
  Ticket,
  UpdateServiceRequestParams,
} from '@shared/http/mie/api/ticket/type';
import { AttachmentValue, InputValue } from '@shared/components/Fields';
import { transformTicketForUpdate } from '../../../../shared/http/mie/api/ticket/tickets.adapter';
import { Field, ServiceRequestVisibilityEnum } from '../../../../shared';

type CustomerServiceRequestValues = {
  [key: string]: InputValue | AttachmentValue[];
};

const getChangeUserDetailsFields = (
  isFormReadonly: boolean,
  ticketStatus: Ticket['status'],
): Field<CustomerServiceRequestValues>[] => {
  return [
    {
      id: 'phone',
      required: true,
      type: 'text',
      readonly: isFormReadonly || ticketStatus !== 'open',
      label: TranslationLabels.profileFormMobilePhone,
    },
    {
      id: 'email',
      required: true,
      type: 'text',
      readonly: isFormReadonly || ticketStatus !== 'open',
      label: TranslationLabels.profileFormEmail,
    },
  ];
};

export const getFields = (
  isFormReadonly: boolean,
  classes: ClassNameMap,
  t: TFunction,
  isChangeUserDetailsRequest: boolean,
  ticketStatus: Ticket['status'],
): Field<CustomerServiceRequestValues>[] => {
  // Backend doesn't provide separate fields such as first name, last name, etc. for creating/updating
  // change user details request. It is a general request with entity type = 'user'.
  // All the data are put in the description field in a pattern: firstName: XX, lastName: YY
  if (isChangeUserDetailsRequest) {
    return getChangeUserDetailsFields(isFormReadonly, ticketStatus);
  }

  return [
    {
      id: 'summary',
      required: true,
      type: 'text',
      readonly: isFormReadonly || ticketStatus !== 'open',
      className: classes.summaryText,
      label:
        isFormReadonly || ticketStatus !== 'open'
          ? ''
          : TranslationLabels.ticketingCustomerAccountSummaryLabel,
    },
    {
      id: 'description',
      required: true,
      type: 'textarea',
      readonly: isFormReadonly || ticketStatus !== 'open',
      label: TranslationLabels.ticketingServiceRequestDescriptionLabel,
    },
    {
      id: 'attachments',
      accept: ['.png', '.jpg', '.jpeg', '.pdf'],
      helperTextKey:
        TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
      helperTextOptions: {
        formats: 'JPG, JPEG, PNG, PDF',
        maxSize: 100,
      },
      isMultiple: true,
      type: 'attachment',
      readonly: isFormReadonly,
      label: TranslationLabels.formFieldsAttachmentFileLabel,
    },
    {
      id: 'visibility',
      label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
      switchOptions: {
        checkedLabelKey:
          TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
        checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
        uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
        checkedValueDescriptionKey:
          TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
        helperTextKey:
          TranslationLabels.ticketingServiceRequestVisibilityHelperText,
      },
      type: 'switch',
      readonly: isFormReadonly || ticketStatus !== 'open',
    },
  ];
};

const mapAttachmentsToAttachmentValue = (
  attachments: Attachment[],
): AttachmentValue[] => {
  return attachments.map((attachment) => ({
    id: attachment.uuid,
    name: attachment.name,
    url: attachment.url,
  }));
};

const getUserDataFromDescription = (
  description: string,
): CustomerServiceRequestValues => {
  const userData = JSON.parse(description);
  return {
    phone: userData?.phone || '',
    email: userData?.email || '',
  };
};

const getInitialValues = (
  ticket: Ticket,
  isChangeUserDetailsRequest: boolean,
  attachments: Attachment[],
): CustomerServiceRequestValues => {
  if (isChangeUserDetailsRequest) {
    return {
      ...getUserDataFromDescription(ticket.description),
      attachments: mapAttachmentsToAttachmentValue(attachments),
    };
  }
  return {
    summary: ticket.summary || '',
    description: ticket.description || '',
    attachments: mapAttachmentsToAttachmentValue(attachments),
    visibility: ticket.visibility || ServiceRequestVisibilityEnum.PUBLIC,
  };
};

const transformUserDetailsValuesToGeneralRequest = (
  values: CustomerServiceRequestValues,
): CustomerServiceRequestValues => {
  return {
    description: JSON.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
    }),
    attachments: values.attachments,
  };
};

export const getConfig = ({
  ticket,
  attachments,
  onUpdateTicket,
  isChangeUserDetailsRequest,
}: {
  ticket: Ticket;
  attachments: Attachment[];
  onUpdateTicket: (params: UpdateServiceRequestParams) => void;
  isChangeUserDetailsRequest: boolean;
}): FormikConfig<CustomerServiceRequestValues> => {
  return {
    initialValues: getInitialValues(
      ticket,
      isChangeUserDetailsRequest,
      attachments,
    ),
    validationSchema:
      !isChangeUserDetailsRequest &&
      Yup.object().shape({
        description: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
        summary: Yup.string().required(TranslationLabels.formErrorsRequired),
      }),
    onSubmit: (values) => {
      const requestValues = isChangeUserDetailsRequest
        ? transformUserDetailsValuesToGeneralRequest(values)
        : values;

      const updatedTicket = transformTicketForUpdate({
        ...requestValues,
        id: ticket.id,
      });

      const initialTicket = transformTicketForUpdate(ticket);

      const attachmentFormValues = values.attachments as AttachmentValue[];

      return onUpdateTicket({
        ticket: updatedTicket,
        existingTicket: initialTicket,
        attachmentValues: attachmentFormValues,
        existingAttachments: attachments,
      });
    },
  };
};
