import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Content, Elements } from '../../shared';
import { RouteParams } from '../service-request.type';
import { useItemsQuery } from './items.repository';
import { stepper } from '../stepper.config';

export const Items: FC = () => {
  const country = useCountry();
  const { areaId, roomId, issueId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const { isFetching, isError, error, data: itemsData } = useItemsQuery(
    country,
    areaId,
    pathname,
    issueId,
    roomId,
  );

  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestItemsText,
        titleKey: TranslationLabels.ticketingServiceRequestItemsTitle,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
    >
      <Elements
        data={itemsData?.items}
        otherElementRedirectURL={itemsData?.otherElementRedirectURL}
        dataTestPrefix="items"
      />
    </Content>
  );
};
