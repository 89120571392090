import React, { FC, ReactElement } from 'react';
import { UserGlobalRole, UserUnitRole } from '@shared/enums';
import { AvailableForInternal } from './available-for-internal.component';

type Props = {
  children: NonNullable<ReactElement>;
  availableForRoles?: (UserGlobalRole | UserUnitRole)[];
  currentUnitId?: string; // needed only if unit-depended roles provided
  fallback?: ReactElement;
  showLoader?: boolean;
};

// if 'availableForRoles' not provided or an empty array, don't wrap in AvailableForInternal
export const AvailableFor: FC<Props> = ({
  availableForRoles,
  currentUnitId,
  fallback,
  showLoader,
  children,
}) => {
  if (Array.isArray(availableForRoles) && availableForRoles.length > 0) {
    return (
      <AvailableForInternal
        availableForRoles={availableForRoles}
        currentUnitId={currentUnitId}
        fallback={fallback}
        showLoader={showLoader}
      >
        {children}
      </AvailableForInternal>
    );
  }

  return children;
};
