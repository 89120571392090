import { mieClient } from '../../mie.client';
import { Unit, Attachment } from '../../../../../+apartment/apartment.type';
import {
  GetMyUnitsResponse,
  GetUnitByIdResponse,
  MieUnit,
  MieProspectUnit,
  GetProspectUnitsResponse,
  ProspectUnit,
  GetProspectCommentsResponse,
  ProspectPublicUnit,
  MieProspectPublicUnit,
  MieAttachmentType,
  MieAttachment,
  GetUnitAttachmentsResponse,
  PostProspectViewingAPIParams,
  GetPropertyAttachmentsResponse,
} from './units.type';
import {
  adaptUnit,
  adaptProspectUnit,
  adaptProspectPublicUnit,
  adaptAttachment,
} from './units.adapter';
import { adaptMiePaginated, createMieOrder } from '../../mie.helper';
import { adaptComment } from '../ticket/tickets.adapter';
import { MieComment, Comment, PostCommentAPIParams } from '../ticket/type';

export const unitsApi = {
  getMyUnits: async (
    pagination: GenericTypes.Pagination,
    sort?: GenericTypes.Sort<keyof Unit>,
  ): Promise<GenericTypes.Paginated<Unit>> => {
    const response = await mieClient.get<GetMyUnitsResponse>('/units/my', {
      page: pagination.page,
      page_size: pagination.pageSize,
      ...(sort && { order: createMieOrder<keyof Unit>(sort) }),
    });

    return adaptMiePaginated<MieUnit, Unit>(response, adaptUnit);
  },

  getUnitById: async (id: Unit['id']): Promise<Unit> => {
    const response = await mieClient.get<GetUnitByIdResponse>(
      `/units/my/${id}`,
    );

    return adaptUnit(response);
  },

  getUnitAttachments: async (
    unitId: string,
    pagination?: GenericTypes.Pagination,
    category?: string,
    types?: MieAttachmentType[],
  ): Promise<GenericTypes.Paginated<Attachment>> => {
    const response = await mieClient.get<GetUnitAttachmentsResponse>(
      `/units/${unitId}/attachments`,
      {
        ...(pagination && { page: pagination.page }),
        ...(pagination && { page_size: pagination.pageSize }),
        ...(category && { category }),
        ...(types && { types: types.join(',') }),
      },
    );

    return adaptMiePaginated<MieAttachment, Attachment>(
      response,
      adaptAttachment,
    );
  },

  getPropertyAttachments: async (
    propertyId: string,
    pagination?: GenericTypes.Pagination,
    category?: string,
  ): Promise<GenericTypes.Paginated<Attachment>> => {
    const response = await mieClient.get<GetPropertyAttachmentsResponse>(
      `/properties/${propertyId}/attachments`,
      {
        ...(pagination && { page: pagination.page }),
        ...(pagination && { page_size: pagination.pageSize }),
        ...(category && { category }),
      },
    );

    return adaptMiePaginated<MieAttachment, Attachment>(
      response,
      adaptAttachment,
    );
  },

  getPropertyObjectAttachments: async (
    propertyObjectId: string,
    pagination?: GenericTypes.Pagination,
    category?: string,
  ): Promise<GenericTypes.Paginated<Attachment>> => {
    const response = await mieClient.get<GetPropertyAttachmentsResponse>(
      `/property_objects/${propertyObjectId}/attachments`,
      {
        ...(pagination && { page: pagination.page }),
        ...(pagination && { page_size: pagination.pageSize }),
        ...(category && { category }),
      },
    );

    return adaptMiePaginated<MieAttachment, Attachment>(
      response,
      adaptAttachment,
    );
  },

  getProspectUnits: async (
    pagination: GenericTypes.Pagination,
    sort?: GenericTypes.Sort<keyof ProspectUnit>,
  ): Promise<GenericTypes.Paginated<ProspectUnit>> => {
    const response = await mieClient.get<GetProspectUnitsResponse>(
      '/prospects/',
      {
        page: pagination.page,
        page_size: pagination.pageSize,
        ...(sort && { order: createMieOrder<keyof ProspectUnit>(sort) }),
      },
    );

    return adaptMiePaginated<MieProspectUnit, ProspectUnit>(
      response,
      adaptProspectUnit,
    );
  },

  getProspectComments: async (
    id: string,
    pagination: GenericTypes.Pagination,
  ): Promise<GenericTypes.Paginated<Comment>> => {
    const response = await mieClient.get<GetProspectCommentsResponse>(
      `/prospects/${id}/comments`,
      {
        order: '-created_at',
        page: pagination.page,
        page_size: pagination.pageSize,
      },
    );
    return adaptMiePaginated<MieComment, Comment>(response, adaptComment);
  },

  postComment: async (
    id: string,
    comment: PostCommentAPIParams,
  ): Promise<Comment> => {
    const response = await mieClient.post<PostCommentAPIParams, MieComment>(
      `/prospects/${id}/comments`,
      comment,
    );
    return adaptComment(response);
  },

  getProspectsPublicInfo: async (
    token: string,
  ): Promise<ProspectPublicUnit> => {
    const response = await mieClient.get<MieProspectPublicUnit>(
      '/prospects/public',
      { token },
    );

    return adaptProspectPublicUnit(response);
  },

  postProspectViewing: async (id: string): Promise<ProspectUnit> => {
    const response = await mieClient.post<
      PostProspectViewingAPIParams,
      MieProspectUnit
    >(`/prospects/${id}/viewing/request`);
    return adaptProspectUnit(response);
  },
};
