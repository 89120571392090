import React, { FC, useMemo } from 'react';
import { TranslationLabels } from '@generated/translation-labels';
import { useTranslation } from '@shared/translations';
import * as Sentry from '@sentry/react';
import {
  FancyInfoBox,
  Icon,
  InfoBox,
  List,
  Model,
  Status,
} from '@shared/components';
import { formatStandardDateTime, renderUnitAddress } from '@shared/helpers';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ArrowRight1Icon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg';
import { useProspectUnitsQuery, ProspectUnit } from '@shared/http/mie';
import { useProspectStatusesQuery } from '@shared/http/mie/api/ticket';
import { useStyles } from './prospects-list.styles';
import { ProspectComments } from '../ProspectComments/prospect-comments.component';
import { RequestViewing } from '../RequestViewing/request-viewing.component';
import { ReactComponent as SimpleRentImg } from '../../../assets/simple-rent.svg';

const DEFAULT_PAGE_SIZE = 10;

export const ProspectsList: FC = () => {
  const { t } = useTranslation();
  const { data: viewingStatuses } = useProspectStatusesQuery();
  const { data: prospects, error: prospectsError } = useProspectUnitsQuery({
    page: 1,
    pageSize: 100, // should be enough to find all prospects
  });
  const classes = useStyles();

  const pendingVerifications = useMemo(
    () =>
      (prospects?.results || [])
        .filter(
          ({ prospectVerifications }) => prospectVerifications?.length > 0,
        )
        .map(({ prospectVerifications, unit }) => {
          const verification = prospectVerifications?.[0]; // get first element as only SimplRent supported (UI/backend inconsistency)
          return {
            id: verification?.id,
            url: verification?.url,
            address: renderUnitAddress(unit, t),
          };
        }),
    [t, prospects?.results],
  );

  const model: Model<ProspectUnit>[] = [
    {
      getContent: ({ unit }) => {
        return (
          <div>
            <Typography className={classes.rowMainText}>
              {unit.address} {unit.streetNumber} {unit.unitNumber}
            </Typography>
            <Typography className={classes.rowSubText}>
              {unit.city.name}
            </Typography>
          </div>
        );
      },
      id: 'unit',
      label: TranslationLabels.prospectsListTableLocationLabel,
    },
    {
      getContent: ({ lettingAgent }) => {
        return (
          <div className={classes.lettingAgentWrapper}>
            {lettingAgent.imageUrl && (
              <img
                src={lettingAgent.imageUrl}
                alt="letting agent"
                className={classes.avatar}
              />
            )}
            <span>
              {lettingAgent.firstName} {lettingAgent.lastName}
            </span>
          </div>
        );
      },
      id: 'lettingAgent',
      label: TranslationLabels.prospectsListTableLettingOfficerLabel,
    },
    {
      getContent: ({
        agreedDatetimeForViewing,
        id,
        unit,
        lettingAgent: { email },
      }) => {
        if (agreedDatetimeForViewing) {
          return (
            <Typography>
              {formatStandardDateTime(agreedDatetimeForViewing)}
            </Typography>
          );
        }
        return (
          <RequestViewing
            prospectId={id}
            unit={unit}
            lettingAgentEmail={email}
          />
        );
      },
      id: 'agreedDatetimeForViewing',
      label: TranslationLabels.prospectsListTableMeetingDateLabel,
    },
    {
      getContent: ({ status, prospectVerifications }) => {
        const viewingStatus = viewingStatuses?.find(
          (viewingStatus) => viewingStatus.jiraKey === status,
        );
        if (viewingStatus) {
          const verificationUrl = prospectVerifications?.[0]?.url; // get first element as only SimplRent supported (UI/backend inconsistency)
          return (
            <div className={classes.simplRentTableWrapper}>
              <Status className={classes.statusLabel} status={viewingStatus} />
              {verificationUrl && (
                <a
                  className={classes.simplRentTableButton}
                  href={verificationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    className={classes.simplRentTableIcon}
                    width={12}
                    height={12}
                    icon={ArrowRight1Icon}
                  />
                </a>
              )}
            </div>
          );
        }
        return null;
      },
      id: 'status',
      label: TranslationLabels.prospectsListTableStatusLabel,
    },
    {
      getContent: ({ id, lettingAgent }) => {
        return <ProspectComments prospectId={id} lettingAgent={lettingAgent} />;
      },
      align: 'right',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      id: 'comments' as any,
      label: '',
    },
  ];

  if (prospectsError) {
    Sentry.captureException(prospectsError);
  }

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>
        {t(TranslationLabels.prospectsListTitle)}
      </Typography>
      <InfoBox
        className={classes.infoBox}
        type="info"
        messageKey={TranslationLabels.infoForProspectHowToAskQuestions}
      />
      {pendingVerifications.map(({ id, url, address }) => (
        <FancyInfoBox
          key={id}
          textKey={TranslationLabels.simplRentInfoTextKey}
          textParams={{
            address: `<span>${address}</span>`,
          }}
          img={{
            component: SimpleRentImg,
            maxWidth: '147px',
            maxHeight: '133px',
          }}
          button={{
            textKey: TranslationLabels.simpleRentButtonTextKey,
            href: url,
          }}
        />
      ))}
      <List<ProspectUnit>
        model={model}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        pageSizeOptions={[2, 5, 10, 25]}
        paginatedQuery={useProspectUnitsQuery}
        defaultSortBy="status"
        showArrow={false}
      />
    </div>
  );
};
