import { useQuery, UseQueryResult } from 'react-query';
import { Category, Config, Topic } from '@shared/components/Faq/faq.type';
import { kundoClient } from '@shared/components/Faq/kundo.client';

type CategoryAndTopics = Category & { topics: Topic[] };

export const useCategoriesQuery = (
  country: GenericTypes.Country,
): UseQueryResult<CategoryAndTopics[] | undefined> => {
  return useQuery<CategoryAndTopics[] | undefined>(
    ['getFaqCategories', country],
    async () => {
      if (!country) return undefined;

      const config = await kundoClient
        .get<Config[]>(`/properties/heimstaden-${country}.json?limit=1`)
        .then((configs) => configs[0]);

      const topics = await kundoClient.get<Topic[]>(
        `/heimstaden-${country}/${config.enabledTopics.join(',')}.json`,
      );

      return config.categories
        .map((category, index) => ({
          ...category,
          topics: topics
            .filter(({ topic }) => topic === config.enabledTopics[index])
            .splice(0, 5),
        }))
        .filter((category) => category.topics.length > 0);
    },
  );
};
