import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useAnimatedRoutes } from '@shared/animated-routes';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon, Spinner } from '@shared/components';
import { generatePath } from 'react-router';
import { useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { useUnitsQuery } from '@shared/http/mie';
import * as Sentry from '@sentry/react';
import Grid from '@material-ui/core/Grid';
import { GridSize } from '@material-ui/core';
import { ReactComponent as ArrowRight } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Content } from '../shared';
import { useStyles } from './requests.styles';
import { ReactComponent as IntroImage } from '../assets/intro.svg';
import { useGetRequestsQuery } from './requests.hook';

const PAGE_SIZE = 100;

export const Requests: FC = () => {
  const classes = useStyles();
  const country = useCountry();
  const { t } = useTranslation();
  const { data: requests, isLoading: isLoadingRequests } = useGetRequestsQuery(
    country,
  );
  const { goTo } = useAnimatedRoutes();
  const { isLoading, isError, error, data: unitsPaginated } = useUnitsQuery({
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const [unitId, setUnitId] = useState<string | undefined>(
    unitsPaginated?.results[0]?.id,
  );

  const filteredRequests = useMemo(
    () =>
      (requests || []).filter(
        ({ externalWebpageURL, redirectURL }) =>
          externalWebpageURL || redirectURL,
      ),
    [requests],
  );

  useEffect(() => {
    setUnitId(unitsPaginated?.results[0]?.id);
  }, [unitsPaginated?.results]);

  const getMdGridSize = useCallback((itemsCount: number): GridSize => {
    if (itemsCount === 0) return 'auto';
    if (itemsCount >= 4) return 3;
    return (12 / itemsCount) as GridSize;
  }, []);

  const mdGridSize = useMemo(() => getMdGridSize(filteredRequests.length), [
    filteredRequests.length,
    getMdGridSize,
  ]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    Sentry.captureException(error);
    return null;
  }

  // The list returned by the backend is paginated and there is no time for implementing endless scroll.
  // At the same time, there are not customers who have more than 40 properties, so PAGE_SIZE=100 limit works.
  // Nevertheless, I leave here error reporting to Sentry, just in case.
  if (unitsPaginated && unitsPaginated.count > PAGE_SIZE) {
    Sentry.captureException(
      `${PAGE_SIZE} is lower than the number of users' apartments`,
    );
  }

  return (
    <Content
      classes={!isLoadingRequests ? { boxClassName: classes.box } : undefined}
      introProps={{
        descriptionKey: TranslationLabels.ticketingRequestText,
        titleKey: TranslationLabels.ticketingRequestTitle,
        childrenRightColumn: <IntroImage />,
      }}
      isFetching={isLoadingRequests}
    >
      <div className={classes.localization}>
        <div className={clsx(classes.bg, classes.bgLeft)} />
        <div className={clsx(classes.bg, classes.bgRight)} />
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
            <p className={classes.localizationText}>
              {t(TranslationLabels.apartmentSwitcherTitle)}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={clsx(classes.gridItem, classes.selectGridItem)}
          >
            <FormControl className={classes.selectWrapper} fullWidth>
              <TextField
                value={unitId || ''}
                onChange={(event) => {
                  setUnitId(event.target.value as string);
                }}
                select
                SelectProps={{
                  native: false,
                  classes: {
                    select: classes.select,
                  },
                }}
                variant="outlined"
                size="small"
              >
                {(unitsPaginated?.results || []).map(
                  ({ id, name, postalCode, city }) => (
                    <MenuItem key={id} value={id}>
                      {name}, {postalCode} {city.name}
                    </MenuItem>
                  ),
                )}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <Grid container className={classes.list}>
        {filteredRequests.map((request) => {
          const {
            descriptionKey,
            externalWebpageURL,
            iconURL,
            key,
            redirectURL,
            titleKey,
          } = request;
          const props = externalWebpageURL
            ? {
                href: externalWebpageURL,
                rel: 'noopener noreferrer',
                target: '_blank',
              }
            : {
                onClick: () =>
                  goTo(
                    generatePath(redirectURL, {
                      unitId,
                    }),
                  ),
              };

          return (
            <Grid
              item
              md={mdGridSize}
              sm={12}
              key={key}
              className={classes.requestWrapper}
              data-test={`request-type-box:${key}`}
            >
              <Button
                {...props}
                classes={{ label: classes.label }}
                className={classes.request}
              >
                {/* TODO: Find a better solution to display icons */}
                {iconURL && (
                  <img
                    src={iconURL}
                    alt={t(titleKey)}
                    className={classes.icon}
                  />
                )}
                <Typography variant="h3" className={classes.title}>
                  <strong>{t(titleKey)}</strong>
                </Typography>
                <Typography className={classes.description}>
                  {t(descriptionKey)}
                </Typography>
                <Icon
                  className={classes.goTo}
                  icon={ArrowRight}
                  height={24}
                  width={24}
                />
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Content>
  );
};
