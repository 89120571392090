import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { SubItems } from './sub-items.type';

export const useGetSubItemsQuery = (
  country: GenericTypes.Country,
  path: string,
  itemId: string,
): UseQueryResult<SubItems | undefined> => {
  return useQuery<SubItems | undefined>(
    ['getTicketingSubItems', country, path, itemId],
    () => {
      if (!country || !path || !itemId) return undefined;

      const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.customerAccountRequest.types.invoiceAndCustomerAccount.items[item->fieldId == $itemId][0]{
          "otherElementRedirectURL": "${path}/" + otherElementId,
          "items": failures[]{
            "key": _key,
            ...failure->{
              "key": fieldId,
              "redirectURL": "${path}/" + fieldId,
              "titleKey": title->key,
              externalWebpageUrl,
              roles,
            }
          }
        }
      }
    `;

      return sanityClient.fetch<SubItems>(query, {
        country,
        itemId,
        type: SanityDocumentType.SETTINGS,
      });
    },
  );
};
