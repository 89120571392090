import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo, useState } from 'react';
import { FormProps } from '@shared/FormV2';
import {
  useAddAttachments,
  useCreateRepairAndMaintenanceRequest,
} from '@shared/http/mie/api/ticket';
import { useBreakpoint } from '@shared/hooks';
import { useParams } from 'react-router-dom';
import { Content, RequestCreator } from '../../shared';
import { getFields } from './form.model';
import { Values } from './form.type';
import { ReactComponent as AdditionalInfoImage } from '../../assets/additional-info.svg';
import { stepper } from '../stepper.config';
import { RouteParams } from '../service-request.type';

export const Form: FC = () => {
  const createRepairAndMaintenanceRequestMutation = useCreateRepairAndMaintenanceRequest();
  const addAttachments = useAddAttachments();
  const { isDown: isDownMd } = useBreakpoint('md');
  const queryParams = useParams<RouteParams>();
  const [ticketId, setTicketId] = useState('');

  const config = useMemo(
    (): FormProps<Values>['config'] => ({
      onSubmit: async (values) => {
        const {
          unitId,
          issueId,
          failureId,
          areaId,
          roomId,
          itemId,
        } = queryParams;
        const response = await createRepairAndMaintenanceRequestMutation.mutateAsync(
          {
            summary: values.summary,
            description: values.description,
            entity_uuid: unitId,
            entity_type: 'unit',
            visibility: values.visibility,
            issue_type: issueId,
            issue_subtype: failureId,
            issue_location: areaId,
            room_with_issue: roomId,
            damaged_item: itemId,
          },
        );
        setTicketId(response.id);
        addAttachments.mutateAsync({
          ticketId: response.id,
          attachments: values.attachment,
        });
      },
    }),
    [createRepairAndMaintenanceRequestMutation, queryParams, addAttachments],
  );
  const fields = useMemo(() => getFields(), []);

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingGeneralText,
        titleKey: TranslationLabels.ticketingGeneralTitle,
        childrenRightColumn: isDownMd ? undefined : <AdditionalInfoImage />,
      }}
      stepperConfig={stepper}
      type="columns"
    >
      <RequestCreator<Values>
        formProps={{ config, fieldsConfig: fields }}
        fullWidth
        ticketParams={queryParams}
        ticketType="service_request"
        ticketId={ticketId}
      />
    </Content>
  );
};
