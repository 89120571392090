import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import * as Sentry from '@sentry/react';
import Typography from '@material-ui/core/Typography';
import { useAnimatedRoutes } from '@shared/animated-routes';
import { useCountry } from '@shared/hooks';
import { sanityImageBuilder } from '@shared/sanity';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@shared/translations';
import { Box, Spinner } from '@shared/components';
import React, { FC } from 'react';
import { useLocation, generatePath } from 'react-router';
import { Content } from '../../shared';
import { useAreasQuery } from './areas.repository';
import { useStyles } from './areas.styles';
import { ReactComponent as AreasImage } from '../../assets/areas.svg';
import { stepper } from '../stepper.config';
import { RouteParams } from '../service-request.type';

export const Areas: FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { unitId } = useParams<RouteParams>();
  const { goTo } = useAnimatedRoutes();
  const { t } = useTranslation();
  const country = useCountry();
  const areasQuery = useAreasQuery(country, pathname);

  if (areasQuery.isLoading) {
    return <Spinner />;
  }

  if (areasQuery.isError) {
    Sentry.captureException(areasQuery.error);
    return null;
  }

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestAreasText,
        titleKey: TranslationLabels.ticketingServiceRequestAreasTitle,
        childrenRightColumn: <AreasImage />,
      }}
      isFetching={areasQuery.isFetching}
      stepperConfig={stepper}
    >
      <Grid container spacing={2}>
        {areasQuery.data?.areas.map((props) => {
          const {
            buttonLabelKey,
            id,
            imageURL,
            redirectURL,
            textKey,
            titleKey,
          } = props;
          const imageUrl = sanityImageBuilder.image(imageURL).width(510).url();

          return (
            <Grid key={id} item xs={12} sm={6} md={3}>
              <Box className={classes.content} data-test={`location-box:${id}`}>
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={t(titleKey)}
                    className={classes.image}
                  />
                )}
                <Typography variant="h3">
                  <strong>{t(titleKey)}</strong>
                </Typography>
                <Typography className={classes.description}>
                  {t(textKey)}
                </Typography>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={() =>
                    goTo(
                      generatePath(redirectURL, {
                        unitId,
                      }),
                    )
                  }
                  variant="outlined"
                >
                  {t(buttonLabelKey)}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Content>
  );
};
