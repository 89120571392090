import React, { FC, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { FormProps } from '@shared/FormV2';
import {
  useAddAttachments,
  useCreateContractRequest,
  useCreateInvoiceAndAccountRequest,
} from '@shared/http/mie/api/ticket';
import {
  MieContractRequestType,
  MieInvoiceAndAccountRequestType,
} from '@shared/http/mie/api/ticket/mie.type';
import { profileRoutes } from '../../../../+profile';
import { IntroProps } from '../../../shared/components/Intro';
import { Content, RequestCreator } from '../../../shared';
import { getIntroProps } from './form.helper';
import { getFields, Values } from './form.model';
import { stepper } from '../../stepper.config';
import { RouteParams } from '../../type';

export const Form: FC = () => {
  const { unitId, subItemId, itemId } = useParams<RouteParams>();
  const [ticketId, setTicketId] = useState('');

  const isProfileRequest = false; // todo: MYH-1035 unmock
  const isChangeCustomerInfoRequest = false; // todo: MYH-1035 unmock
  const isContractRequest = itemId === 'contract';

  const createInvoiceAndAccountRequestMutation = useCreateInvoiceAndAccountRequest();
  const createContractRequestMutation = useCreateContractRequest();
  const addAttachments = useAddAttachments();

  const invoiceAndAccountRequestConfig = useMemo(
    (): FormProps<Values>['config'] => ({
      onSubmit: async (values) => {
        const response = await createInvoiceAndAccountRequestMutation.mutateAsync(
          {
            summary: values.summary,
            description: values.description,
            entity_uuid: unitId,
            entity_type: 'unit',
            visibility: values.visibility,
            ticket_subtype: subItemId as MieInvoiceAndAccountRequestType,
          },
        );
        addAttachments.mutateAsync({
          ticketId: response.id,
          attachments: values.attachment,
        });
      },
    }),
    [createInvoiceAndAccountRequestMutation, unitId, subItemId, addAttachments],
  );
  const contractRequestConfig = useMemo(
    (): FormProps<Values>['config'] => ({
      onSubmit: async (values) => {
        const response = await createContractRequestMutation.mutateAsync({
          summary: values.summary,
          description: values.description,
          entity_uuid: unitId,
          entity_type: 'unit',
          visibility: values.visibility,
          ticket_subtype: subItemId as MieContractRequestType,
        });
        setTicketId(response.id);
        addAttachments.mutateAsync({
          ticketId: response.id,
          attachments: values.attachment,
        });
      },
    }),
    [createContractRequestMutation, unitId, subItemId, addAttachments],
  );
  const fields = useMemo(() => getFields(), []);

  const introProps: IntroProps = useMemo(
    () => getIntroProps(isChangeCustomerInfoRequest),
    [isChangeCustomerInfoRequest],
  );

  return (
    <Content introProps={introProps} type="columns" stepperConfig={stepper}>
      <RequestCreator<Values>
        formProps={{
          config: isContractRequest
            ? contractRequestConfig
            : invoiceAndAccountRequestConfig,
          fieldsConfig: fields,
        }}
        ticketType="invoice_request"
        fullWidth
        ticketId={ticketId}
      >
        {(form) => {
          if (isProfileRequest) return <Redirect to={profileRoutes.PROFILE} />;

          return form;
        }}
      </RequestCreator>
    </Content>
  );
};
