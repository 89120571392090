import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import React, { FC, useMemo, useState } from 'react';
import { Box, AvailableFor, InfoBox } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { UserGlobalRole } from '@shared/enums';
import { useAvailableFor } from '@shared/hooks';
import {
  useCreateGeneralRequest,
  useIsUserGeneralRequestAlreadyCreated,
} from '@shared/http/mie/api/ticket';
import { FormV2 } from '@shared/FormV2';
import { useProfileQuery } from '@shared/http/mie/api/auth/auth.hook';

import { useStyles } from './details.styles';
import { getConfig, getFields, Values } from './details.model';

export const Details: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: profileData, isError } = useProfileQuery();
  const useCreateGeneralRequestMutation = useCreateGeneralRequest(true);
  const { isAvailable: isUpdateProfileAvailable } = useAvailableFor({
    availableForRoles: [UserGlobalRole.CUSTOMER],
  });
  const {
    data: existingTicket,
    isError: isGeneralRequestAlreadyCreatedError,
    isLoading,
  } = useIsUserGeneralRequestAlreadyCreated(profileData?.uuid, {
    enabled: isUpdateProfileAvailable,
  });
  const [isFormReadonly, setIsFormReadonly] = useState(true);

  const toggleReadonly = (): void => {
    setIsFormReadonly((prevState) => !prevState);
  };

  const formConfig = useMemo(
    () =>
      getConfig(useCreateGeneralRequestMutation.mutateAsync, profileData?.uuid),
    [useCreateGeneralRequestMutation, profileData?.uuid],
  );
  const fieldsConfig = useMemo(() => getFields(profileData, isFormReadonly), [
    profileData,
    isFormReadonly,
  ]);

  const disabled = isError || isLoading || isGeneralRequestAlreadyCreatedError;

  return (
    <Box>
      {existingTicket && (
        <AvailableFor availableForRoles={[UserGlobalRole.CUSTOMER]}>
          <InfoBox
            className={classes.detailsInfoBox}
            messageKey={TranslationLabels.ticketAssignmentInfo}
            messageParams={{
              ticketId: existingTicket.referenceNumber,
            }}
            link={`/ticketing/details/general_request/${existingTicket.id}`}
            type="info"
          />
        </AvailableFor>
      )}
      <Typography variant="h2">
        {isFormReadonly
          ? t(TranslationLabels.profileDetailsTitle)
          : t(
              TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
            )}
      </Typography>
      <Typography className={classes.text}>
        {isFormReadonly
          ? t(TranslationLabels.profileDetailsText)
          : t(
              TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsDescription,
            )}
      </Typography>
      <FormV2<Values>
        config={formConfig}
        fieldsConfig={fieldsConfig}
        showButtons={!isFormReadonly}
        handleSuccess={toggleReadonly}
        onCancel={toggleReadonly}
      />
      {isFormReadonly && (
        <AvailableFor availableForRoles={[UserGlobalRole.CUSTOMER]}>
          <div className={classes.buttonsRows}>
            <Button
              onClick={toggleReadonly}
              variant="contained"
              color="primary"
              disabled={disabled || Boolean(existingTicket)}
            >
              {t(TranslationLabels.profileDetailsButtonLabel)}
            </Button>
          </div>
        </AvailableFor>
      )}
    </Box>
  );
};
