import { TranslationLabels } from '@generated/translation-labels';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
import { useUnitsQuery } from '@shared/http/mie';
import clsx from 'clsx';
import {
  ArrowRightIcon,
  Carousel,
  ContainerFluid,
  Spinner,
  useTranslation,
} from '../../shared';
import outsideDefaultImage from '../assets/apartment-outside.jpg';
import { routes } from '../shared';
import { useStyles } from './widget.styles';

const DEFAULT_PAGE = 1;
const PAGE_SIZE = 1;

export const Widget: FC = () => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: propertyUnitsPaginated,
  } = useUnitsQuery({ page, pageSize: PAGE_SIZE });

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const navigateToApartment = useCallback(
    (id) => history.push(`${routes.APARTMENT}/${id}`),
    [history],
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !propertyUnitsPaginated) {
    Sentry.captureException(error);
    return null;
  }

  if (propertyUnitsPaginated?.results?.length === 0) {
    return null;
  }

  const handleLeftClick = (): void => {
    const nextPage = page - 1;
    if (nextPage > 0) {
      setPage(nextPage);
    }
  };

  const handleRightClick = (): void => {
    const nextPage = page + 1;
    if (nextPage <= propertyUnitsPaginated.count) {
      setPage(nextPage);
    }
  };

  return (
    <ContainerFluid className={classes.containerFluid}>
      <Typography variant="h2">
        {t(TranslationLabels.apartmentWidgetTitle)}
      </Typography>
      <Carousel
        length={propertyUnitsPaginated.count}
        className={classes.carousel}
      >
        {({ LeftButton, RightButton }) => {
          const {
            id,
            thumbnail,
            postalCode,
            city,
            address,
            unitNumber,
          } = propertyUnitsPaginated.results[0];

          return (
            <div key={id} className={classes.wrapper}>
              <div className={classes.information}>
                <Grid container spacing={2}>
                  {propertyUnitsPaginated.count > 1 && (
                    <Grid item md={2}>
                      <LeftButton
                        className={classes.carouselButton}
                        onClick={handleLeftClick}
                        disabled={isFetching}
                      />
                    </Grid>
                  )}
                  <Grid onClick={() => navigateToApartment(id)} item md sm xs>
                    <div
                      className={clsx(
                        classes.text,
                        propertyUnitsPaginated?.count === 1 &&
                          classes.singleProperty,
                      )}
                    >
                      <Typography variant="h3">
                        <strong>
                          {address} {unitNumber}
                        </strong>
                        <br />
                        {postalCode}&nbsp;{city.name}
                      </Typography>
                      {propertyUnitsPaginated.count === 1 && (
                        <ArrowRightIcon width={32} height={32} />
                      )}
                      {propertyUnitsPaginated?.count > 1 && (
                        <Button
                          className={classes.button}
                          color="primary"
                          disabled={isFetching}
                          onClick={() => navigateToApartment(id)}
                          variant="outlined"
                        >
                          {t(TranslationLabels.apartmentWidgetLink)}
                        </Button>
                      )}
                    </div>
                  </Grid>
                  {propertyUnitsPaginated.count > 1 && (
                    <Grid item md={2}>
                      <RightButton
                        className={classes.carouselButton}
                        onClick={handleRightClick}
                        disabled={isFetching}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
              <div
                className={classes.coverPhoto}
                style={{
                  backgroundImage: `url('${thumbnail || outsideDefaultImage}')`,
                }}
              />
            </div>
          );
        }}
      </Carousel>
    </ContainerFluid>
  );
};
