import React, { FC, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './header-section.styles';

type Props = {
  htmlText: string;
  children?: ReactNode;
};

export const HeaderSection: FC<Props> = ({ children, htmlText }: Props) => {
  const classes = useStyles();

  return (
    <>
      <hr className={classes.line} />
      <div className={classes.headerWrapper}>
        <div className={classes.titleWrapper}>
          <Typography
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: htmlText }}
            variant="h2"
          />
        </div>
        {children}
      </div>
    </>
  );
};
