import { Attachment } from '../../../apartment.type';

type GroupedAttachments = {
  houseRules: Attachment[];
  manuals: Attachment[];
  contract: Attachment[];
  other: Attachment[];
};

export const groupAttachments = (
  attachmentsCombined: Attachment[],
): GroupedAttachments => {
  const houseRules = (attachmentsCombined || []).filter(
    (a) => a.type === 'house_rules',
  );

  const manuals = (attachmentsCombined || []).filter(
    (a) => a.type === 'manuals',
  );

  const contract = (attachmentsCombined || []).filter(
    (a) => a.type === 'contract_pdf',
  );

  const other = (attachmentsCombined || []).filter(
    (a) => a.type === 'other' || a.type === 'pdf' || a.type === 'floor_plan',
  );
  return { houseRules, manuals, contract, other };
};
