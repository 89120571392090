import { UnitNearby } from '../../../../../+prospects/types';
import { MieUnitNearby } from './catalog.type';

export const adaptUnitNearby = (unit: MieUnitNearby): UnitNearby => {
  return {
    id: unit.unitUuid,
    thumbnail: unit.thumbnailImage,
    name: unit.name,
    address: unit.addressLine,
    streetNumber: unit.streetNumber,
    unitNumber: unit.unitNumber,
    city: unit.city,
    netArea: unit.netArea,
    rentalPrice: unit.rentPrice,
    rentalPriceCurrency: unit.rentPriceCurrency,
    advertisementUrls: unit.advertisementsUrls,
  };
};
