import React, { ChangeEvent, FC, useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import { SwitchProps, SwitchValue } from '@shared/FormV2/fields/types';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { useTranslation } from '@shared/translations';
import { getFirstError } from '@shared/components';
import { useStyles } from '@shared/FormV2/fields/switch-field.styles';
import clsx from 'clsx';

type Props<TFormValues> = FormikFieldProps<SwitchValue, TFormValues> & {
  props: SwitchProps;
};

export function SwitchFieldV2<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const { meta, form, field, props: fieldProps } = props;
  const { touched, error, value } = meta;
  const { setFieldValue, setFieldTouched } = form;
  const { name: fieldName } = field;
  const {
    checkedValueDescriptionKey,
    checkedLabelKey,
    checkedValue,
    disabled,
    helperTextKey,
    label,
    readonly,
    required,
    uncheckedValue,
    onClickDisabled,
  } = fieldProps;

  const classes = useStyles();
  const { t } = useTranslation();
  const errorMessage = touched && getFirstError(t, error);
  const isError = Boolean(errorMessage);

  const isChecked = useMemo(() => value === checkedValue, [
    checkedValue,
    value,
  ]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (disabled || readonly) {
      return;
    }

    const value = event.target.checked ? checkedValue : uncheckedValue;

    setFieldTouched(fieldName);
    setFieldValue(fieldName, value);
  };

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      required={required && !readonly}
      error={isError}
      onClick={onClickDisabled}
    >
      <FieldLabel label={label} />
      {helperTextKey && !readonly && (
        <Typography
          className={clsx(classes.helperText, {
            [classes.disabledText]: disabled,
          })}
          variant="body2"
        >
          {t(helperTextKey)}
        </Typography>
      )}
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          classes={{
            root: clsx({ [classes.readonly]: readonly }),
            label: clsx({ [classes.uncheckedText]: !isChecked }),
          }}
          data-test={`switch-field-label:${fieldName}`}
          control={
            <Switch
              checked={isChecked}
              onChange={handleChange}
              inputProps={{
                'aria-checked': isChecked,
                role: 'switch',
              }}
            />
          }
          label={t(checkedLabelKey)}
        />
      </FormGroup>
      {checkedValueDescriptionKey && (
        <Typography
          className={clsx(classes.stateDescription, {
            [classes.disabledText]: disabled,
            [classes.uncheckedText]: !disabled && !isChecked,
          })}
          variant="body2"
        >
          {t(checkedValueDescriptionKey)}
        </Typography>
      )}
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
