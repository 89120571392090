import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { ErrorScreen } from '@shared/components';

export const GeneralError: FC = () => {
  return (
    <ErrorScreen
      titleKey={TranslationLabels.generalErrorTitle}
      textKey={TranslationLabels.generalErrorText}
    />
  );
};
