import { Form, Formik } from 'formik';
import isEqual from 'lodash-es/isEqual';
import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './formV2.styles';
import { ArrowRightButton } from './components/arrow-right-button.component';
import { GlobalError } from './components/global-error.component';
import { FormField } from './components/form-field.component';
import { FormButtons } from './components/form-buttons.component';
import { handleSubmit } from './helpers/handleSubmit';
import { prepareInitialValues } from './helpers/prepareInitialValues';
import { prepareValidationSchema } from './helpers/prepareValidationSchema';
import { FormProps } from './types';
import { AutoSave } from './components/auto-save.component';

export function FormV2<TFormValues>({
  children,
  config,
  fieldsConfig,
  handleSuccess,
  submitLabel,
  submitIcon,
  cancelLabel,
  onCancel,
  submitDisabled,
  showButtons,
  showInlineButtons,
  className,
  inputClassName,
  autosave,
  resetFormOnSubmit,
}: FormProps<TFormValues>): ReturnType<FC<FormProps<TFormValues>>> {
  const classes = useStyles();
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  return (
    <Formik
      {...config}
      initialValues={prepareInitialValues(fieldsConfig)}
      validationSchema={prepareValidationSchema(fieldsConfig)}
      onSubmit={handleSubmit<TFormValues>(
        setGlobalError,
        handleSuccess,
        config.onSubmit,
        resetFormOnSubmit,
      )}
    >
      {(formProps) => {
        return (
          <Form noValidate className={clsx(classes.form, className)}>
            <GlobalError errorMessage={globalError} />
            {children
              ? children(formProps)
              : fieldsConfig.map((fieldConfig) => (
                  <div
                    key={`from-input-${fieldConfig.field.name}`}
                    className={clsx(classes.input, inputClassName)}
                  >
                    <FormField<TFormValues>
                      fieldConfig={fieldConfig}
                      values={formProps.values}
                    />
                  </div>
                ))}
            {showInlineButtons && (
              <ArrowRightButton
                disabled={
                  formProps.isSubmitting ||
                  isEqual(formProps.initialValues, formProps.values)
                }
              />
            )}
            {showButtons && (
              <FormButtons<TFormValues>
                formProps={formProps}
                submitLabel={submitLabel}
                submitIcon={submitIcon}
                cancelLabel={cancelLabel}
                onCancel={onCancel}
                submitDisabled={submitDisabled}
              />
            )}
            {autosave && <AutoSave debounceMs={1000} />}
          </Form>
        );
      }}
    </Formik>
  );
}

FormV2.defaultProps = {
  showButtons: true,
  resetFormOnSubmit: true,
};
