import { TranslationLabels } from '@generated/translation-labels';
import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryClient,
  useInfiniteQuery,
} from 'react-query';
import { AxiosError } from 'axios';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from 'notistack';
import { serviceRequestsApi } from '@shared/http/mie/api/ticket/service-requests';
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
} from 'react-query/types/react/types';
import isEqual from 'lodash-es/isEqual';
import {
  ComplaintRequest,
  GeneralRequest,
  InvoiceAndAccountRequest,
  ContractRequest,
  RepairAndMaintenanceRequest,
  Ticket,
  PostCommentMutationParams,
  Comment,
  Attachment,
  TicketType,
  PostAttachmentsMutationParams,
  MieTicket,
  UpdateServiceRequestParams,
  Status,
  ElementName,
  GetTicketsQueryParams,
} from '@shared/http/mie/api/ticket/type';
import {
  CreateGeneralRequestPayload,
  CreateComplaintRequestPayload,
  CreateInvoiceAndAccountRequestPayload,
  CreateContractRequestPayload,
  CreateRepairAndMaintenanceRequestPayload,
  MieRequestNotClosedStatus,
} from '@shared/http/mie/api/ticket/mie.type';
import { TFunction } from 'i18next';
import { transformAttachmentsForUpdate } from './tickets.adapter';
import { useTranslation } from '../../../../translations';
import { useCountry } from '../../../../hooks';

const onCreateRequestSuccess = (
  queryClient: QueryClient,
  t?: TFunction,
  enqueueSnackbar?: (
    message: SnackbarMessage,
    options?: OptionsObject,
  ) => SnackbarKey,
  isProfileChange = false,
) => async (): Promise<void> => {
  await queryClient.invalidateQueries(['tickets']);
  if (isProfileChange && enqueueSnackbar && t)
    enqueueSnackbar(t(TranslationLabels.ticketCreated), {
      autoHideDuration: 3000,
      variant: 'success',
    });
};

export const useCreateGeneralRequest = (
  isProfileChange = false,
): UseMutationResult<
  GeneralRequest,
  AxiosError,
  CreateGeneralRequestPayload
> => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation(serviceRequestsApi.createGeneralRequest, {
    onSuccess: onCreateRequestSuccess(
      queryClient,
      t,
      enqueueSnackbar,
      isProfileChange,
    ),
  });
};

export const useCreateComplaintRequest = (): UseMutationResult<
  ComplaintRequest,
  AxiosError,
  CreateComplaintRequestPayload
> => {
  const queryClient = useQueryClient();
  return useMutation(serviceRequestsApi.createComplaintRequest, {
    onSuccess: onCreateRequestSuccess(queryClient),
  });
};

export const useCreateInvoiceAndAccountRequest = (): UseMutationResult<
  InvoiceAndAccountRequest,
  AxiosError,
  CreateInvoiceAndAccountRequestPayload
> => {
  const queryClient = useQueryClient();
  return useMutation(serviceRequestsApi.createInvoiceAndAccountRequest, {
    onSuccess: onCreateRequestSuccess(queryClient),
  });
};

export const useCreateContractRequest = (): UseMutationResult<
  ContractRequest,
  AxiosError,
  CreateContractRequestPayload
> => {
  const queryClient = useQueryClient();
  return useMutation(serviceRequestsApi.createContractRequest, {
    onSuccess: onCreateRequestSuccess(queryClient),
  });
};

export const useCreateRepairAndMaintenanceRequest = (): UseMutationResult<
  RepairAndMaintenanceRequest,
  AxiosError,
  CreateRepairAndMaintenanceRequestPayload
> => {
  const queryClient = useQueryClient();
  return useMutation(serviceRequestsApi.createRepairAndMaintenanceRequest, {
    onSuccess: onCreateRequestSuccess(queryClient),
  });
};

export const useTicketsQuery = (
  pagination: GenericTypes.Pagination,
  sort?: GenericTypes.Sort<keyof Ticket>,
  queryParams?: GetTicketsQueryParams,
): UseQueryResult<GenericTypes.Paginated<Ticket>> => {
  return useQuery(
    [
      'tickets',
      pagination.page,
      pagination.pageSize,
      sort?.sortBy,
      sort?.order,
      queryParams,
    ],
    () => serviceRequestsApi.getTickets(pagination, sort, queryParams),
    { keepPreviousData: true, staleTime: Infinity },
  );
};

export const useDuplicatedTicketQuery = (
  queryParams: GetTicketsQueryParams,
): UseQueryResult<Ticket> => {
  return useQuery(
    ['duplicatedTickets', queryParams],
    async () => {
      const response = await serviceRequestsApi.getTickets(
        { page: 1, pageSize: 1 },
        undefined,
        queryParams,
      );
      return response.results[0];
    },
    { keepPreviousData: true },
  );
};

export const useServiceRequestQuery = (
  id: string,
  type: TicketType,
): UseQueryResult<Ticket> => {
  return useQuery<Ticket>(
    ['serviceRequest', id],
    () => {
      if (type === 'service_request') {
        return serviceRequestsApi.getServiceRequestById(id);
      }
      if (type === 'contract_request') {
        return serviceRequestsApi.getContractRequestById(id);
      }
      if (type === 'invoice_request') {
        return serviceRequestsApi.getInvoiceRequestById(id);
      }
      if (type === 'complaint') {
        return serviceRequestsApi.getComplaintRequestById(id);
      }
      return serviceRequestsApi.getGeneralRequestById(id);
    },
    { retry: false },
  );
};

export const useTicketStatusesQuery = (): UseQueryResult<Status[]> => {
  const country = useCountry();
  return useQuery<Status[]>(['ticketStatuses'], async () => {
    const statuses = await serviceRequestsApi.getTicketStatusesConfig(country);
    return statuses;
  });
};

export const useProspectStatusesQuery = (): UseQueryResult<Status[]> => {
  const country = useCountry();
  return useQuery<Status[]>(['prospectStatuses'], async () => {
    const statuses = await serviceRequestsApi.getProspectStatusesConfig(
      country,
    );
    return statuses;
  });
};

export const useElementNamesQuery = (
  ticket?: Ticket,
  enabled?: boolean,
): UseQueryResult<ElementName[]> => {
  const country = useCountry();
  const elementIds = [
    ticket?.roomWithIssue,
    ticket?.damagedItem,
    ticket?.issueSubtype,
  ];

  return useQuery<ElementName[]>(
    ['serviceRequest', ticket?.id, 'elementNames'],
    () => {
      if (elementIds.filter((element) => Boolean(element)).length === 0) {
        return [];
      }
      return serviceRequestsApi.getElementNames(elementIds, country);
    },
    { enabled },
  );
};

export const useCommentsQuery = (
  id: string,
  pagination: GenericTypes.Pagination,
): UseInfiniteQueryResult<GenericTypes.Paginated<Comment>> => {
  return useInfiniteQuery(
    ['comments', id, pagination.page, pagination.pageSize],
    ({ pageParam }: { pageParam?: number }) => {
      return serviceRequestsApi.getComments(id, {
        pageSize: pagination.pageSize,
        page: pageParam || pagination.page,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next;
      },
    },
  );
};

export const useCommentMutation = (
  ticketId: string,
): UseMutationResult<Comment, Error, PostCommentMutationParams> => {
  const queryClient = useQueryClient();
  return useMutation<Comment, Error, PostCommentMutationParams>(
    (params) => {
      return serviceRequestsApi.postComment(params.ticketId, params.comment);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['comments', ticketId]);
      },
    },
  );
};

const getUpdateTicketRequest = (
  ticketType: TicketType,
): ((ticket: Partial<MieTicket>) => Promise<Ticket>) => {
  if (ticketType === 'service_request') {
    return serviceRequestsApi.patchServiceRequest;
  }
  if (ticketType === 'invoice_request') {
    return serviceRequestsApi.patchInvoiceRequest;
  }
  if (ticketType === 'contract_request') {
    return serviceRequestsApi.patchContractRequest;
  }
  if (ticketType === 'complaint') {
    return serviceRequestsApi.patchComplaintRequest;
  }
  return serviceRequestsApi.patchGeneralRequest;
};

export const useServiceRequestMutation = (
  ticketType: TicketType,
  ticketId: string,
): UseMutationResult<void, Error, UpdateServiceRequestParams> => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation<void, Error, UpdateServiceRequestParams>(
    async ({
      ticket,
      existingTicket,
      attachmentValues,
      existingAttachments,
    }) => {
      if (!isEqual(ticket, existingTicket)) {
        const updateTicket = getUpdateTicketRequest(ticketType);
        await updateTicket(ticket);
      }

      // Add attachments
      const attachmentsToAdd = attachmentValues.filter(
        (attachment) => attachment?.value,
      );
      const postAttachmentsRequests = attachmentsToAdd.map((attachment) =>
        serviceRequestsApi.postAttachments(
          transformAttachmentsForUpdate(attachment),
          ticketId,
        ),
      );

      // Delete attachments
      const attachmentsToRemove = existingAttachments.filter(
        (attachment) =>
          !attachmentValues.find(
            (attachmentFormValue) => attachment.uuid === attachmentFormValue.id,
          ),
      );
      const deleteAttachmentRequests = attachmentsToRemove
        .map((attachment) => attachment.uuid)
        .map((id) => serviceRequestsApi.deleteAttachment(ticketId, id));

      await Promise.all<void | Attachment>([
        ...postAttachmentsRequests,
        ...deleteAttachmentRequests,
      ]);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['serviceRequest', ticketId]),
          queryClient.invalidateQueries(['attachments', ticketId]),
        ]);
      },
      onError: () => {
        enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
          variant: 'error',
        });
      },
    },
  );
};

export const useAddAttachments = (
  ticketId?: string,
): UseMutationResult<Attachment[], Error, PostAttachmentsMutationParams> => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation<Attachment[], Error, PostAttachmentsMutationParams>(
    async (params) => {
      const requests = params.attachments.map((attachment) =>
        serviceRequestsApi.postAttachments(
          transformAttachmentsForUpdate(attachment),
          params.ticketId,
        ),
      );
      const results = await Promise.all(requests);
      return results;
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['attachments', ticketId]);
      },
      onError: () => {
        enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
          variant: 'error',
        });
      },
    },
  );
};

export const useAttachmentsQuery = (
  ticketId: string,
): UseQueryResult<Attachment[]> => {
  return useQuery<Attachment[]>(['attachments', ticketId], () =>
    serviceRequestsApi.getAttachments(ticketId),
  );
};

export const useIsUserGeneralRequestAlreadyCreated = (
  userId?: string,
  options?: { enabled: boolean },
): UseQueryResult<Ticket | undefined> => {
  return useQuery<Ticket | undefined>(
    ['tickets', 'userGeneralRequest', userId],
    async () => {
      if (!userId) {
        return undefined;
      }

      const allStatusesExceptForClosed: MieRequestNotClosedStatus[] = [
        'open',
        'in_progress',
        'awaiting_tenant',
        'awaiting_external_provider',
        'on_hold',
      ];

      const { results } = await serviceRequestsApi.getTickets(
        { page: 1, pageSize: 1 },
        undefined,
        {
          ticket_type: 'general_request',
          entity_type: 'user',
          entity_uuid: userId,
          status: allStatusesExceptForClosed.join(','),
        },
      );
      return results[0];
    },
    options,
  );
};
