import { Key } from 'react';
import { TFunction } from 'i18next';
import { TranslationLabels } from '@generated/translation-labels';
import { Ticket } from '../shared/http/mie/api/ticket/type';

export const transformObjectToArray = <TData>(
  data: Record<string, TData>,
): (TData & { key: Key })[] =>
  Object.entries(data).reduce<(TData & { key: string })[]>(
    (acc, [key, values]) => [...acc, { ...values, key }],
    [],
  );

export const renderApartmentNo = (
  t: TFunction,
  apartmentNo?: string | null,
): string | null => {
  if (!apartmentNo) {
    return null;
  }
  return ` ${t(TranslationLabels.apartmentNumberPrefix)} ${apartmentNo}`;
};

export const isChangeUserDetailsRequest = (ticket: Ticket): boolean =>
  ticket.entityType === 'user' && ticket.ticketType === 'general_request';
