import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { suggestionPathSuffix } from '../../shared';
import { Failures } from './failures.type';

const getAreaQuery = (area: string, path: string): string => `
  ${area}.typeId == $areaId => ${area}.rooms[room->fieldId == $roomId][0]{
    ...issues[issue->fieldId == $issueId][0]{
      ...items[item->fieldId == $itemId][0]{
        "otherElementRedirectURL": "${path}/" + otherElementId,
        "failures": failures[]{
          ...failure->{
            "key": fieldId,
            "redirectURL": select(
              defined(suggestion) => "${path}/" + fieldId + "${suggestionPathSuffix}",
              "${path}/" + fieldId
            ),
            "titleKey": title->key,
            externalWebpageUrl,
            roles,
          }
        }
      }
    }
  }
`;

const getQuery = (path: string): string => `
  * [_type == $type && content.country == $country][0]{
    ...content.ticketing.repairMaintenanceRequest.areas{
      ...select(
        ${getAreaQuery('commonV2', path)},
        ${getAreaQuery('outdoorV2', path)},
        ${getAreaQuery('unitV2', path)},
        ${getAreaQuery('privateOutdoorV2', path)},
      )
    }
  }
`;

export const useFailuresQuery = (
  country: GenericTypes.Country,
  areaId: string,
  path: string,
  itemId: string,
  issueId: string,
  roomId?: string,
): UseQueryResult<Failures> => {
  return useQuery<Failures>(
    ['serviceRequestFailures', country, areaId, roomId, issueId, itemId],
    () =>
      sanityClient.fetch(getQuery(path), {
        areaId,
        country,
        itemId,
        issueId,
        roomId,
        type: SanityDocumentType.SETTINGS,
        path,
      }),
    { staleTime: Infinity },
  );
};
