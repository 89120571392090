import { TranslationLabels } from '@generated/translation-labels';
import * as Sentry from '@sentry/react';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Carousel, ImageModalPreview, Spinner } from '@shared/components';
import { useUnitQuery } from '@shared/http/mie';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '@shared/translations';
import { useStyles } from './images.styles';
import { ImagePreview } from './image-preview.component';

export const Images: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: propertyUnit, isError, error, isLoading } = useUnitQuery(id);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !propertyUnit) {
    Sentry.captureException(error);
    return null;
  }

  const { images } = propertyUnit;

  if (images.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.imagesItem}>
      <Box className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {t(TranslationLabels.apartmentImagesTitle)}
        </Typography>
        <Carousel
          length={images.length}
          className={classes.carousel}
          modalChildren={({ activeItemIdx, className }) =>
            images.map((url, idx) => (
              <ImageModalPreview
                key={url}
                url={url}
                isVisible={activeItemIdx === idx}
                className={className}
              />
            ))
          }
        >
          {({ activeItemIdx, LeftButton, RightButton, openModal }) => {
            return (
              <>
                <Grid
                  container
                  alignItems="stretch"
                  justify="space-between"
                  className={classes.carouselButtonsContainer}
                >
                  {images.length > 1 && (
                    <>
                      <Grid item>
                        <LeftButton />
                      </Grid>
                      <Grid item>
                        <RightButton />
                      </Grid>
                    </>
                  )}
                </Grid>
                {images.map((url, idx) => (
                  <ImagePreview
                    key={url}
                    url={url}
                    onClick={openModal}
                    isVisible={idx === activeItemIdx}
                  />
                ))}
              </>
            );
          }}
        </Carousel>
      </Box>
    </Grid>
  );
};
