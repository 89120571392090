import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { ElementsData, issuesPathSuffix } from '../../shared';

const getAreaQuery = (area: string, path: string): string => {
  return `
  ${area}.typeId == $areaId => ${area}.rooms[]{
    ...room->{
      "key": fieldId,
      "redirectURL": select(
        defined(^.issues) => "${path}/" + fieldId + "${issuesPathSuffix}",
        defined(^.otherElementId) => "${path}/" + fieldId + "${issuesPathSuffix}/" + ^.otherElementId,
        "${path}/" + fieldId
      ),
      "titleKey": title->key,
      externalWebpageUrl,
      roles,
    },
  }
`;
};

const getQuery = (path: string): string => `
  * [_type == $type && content.country == $country][0]{
    ...content.ticketing.repairMaintenanceRequest.areas{
      "rooms": select(
          ${getAreaQuery('commonV2', path)},
          ${getAreaQuery('outdoorV2', path)},
          ${getAreaQuery('unitV2', path)},
          ${getAreaQuery('privateOutdoorV2', path)},
      )
    }
  }
`;

export const useRoomsQuery = (
  country: GenericTypes.Country,
  areaId: string,
  path: string,
): UseQueryResult<{ rooms: ElementsData[] }> => {
  return useQuery<{ rooms: ElementsData[] }>(
    ['serviceRequestRooms', country, areaId],
    () =>
      sanityClient.fetch(getQuery(path), {
        areaId,
        country,
        type: SanityDocumentType.SETTINGS,
      }),
    { staleTime: Infinity },
  );
};
