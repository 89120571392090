import Grid from '@material-ui/core/Grid';
import { TranslationLabels } from '@generated/translation-labels';
import { Typography } from '@material-ui/core';
import { useTranslation } from '@shared/translations';
import { Box, Status } from '@shared/components';
import { formatStandardDate } from '@shared/helpers';
import React, { FC } from 'react';
import { ReactComponent as HouseIcon } from '@heimstaden/icons-library/img/streamline-regular/shipping-delivery/delivery/delivery-door.svg';
import { ReactComponent as CalendarIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-3.svg';
import { useStyles } from './request-details.styles';
import { CardInfo } from './card-info';
import { renderApartmentNo } from '../../../ticketing.helper';
import {
  ElementName,
  Ticket,
} from '../../../../shared/http/mie/api/ticket/type';
import { getTypeLabel } from './request-details.helper';
import { TicketComments } from '../TicketComments/ticket-comments.component';

type Props = {
  ticket: Ticket;
  status?: Status;
  elementNames?: ElementName[];
};

export const RequestDetails: FC<Props> = ({ ticket, status, elementNames }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    id: ticketId,
    address: apartment,
    apartmentNumber,
    referenceNumber,
    createdDate,
  } = ticket;

  const typeText = getTypeLabel(ticket, t, elementNames);

  return (
    <Box className={classes.container} color="grey">
      <Grid
        container
        spacing={1}
        className={classes.content}
        direction="column"
        justify="space-between"
      >
        <div className={classes.status}>
          {status && <Status status={status} />}
        </div>
        <Typography className={classes.header} variant="h2">
          {`${t(TranslationLabels.ticketingDetailsRequestTitle)}: `}
          <span className={classes.ticketNumber}>{referenceNumber}</span>
        </Typography>
        {apartment && apartmentNumber && (
          <CardInfo
            label={t(TranslationLabels.ticketingTerminationRequestFormTitle)}
            value={`${apartment}${renderApartmentNo(t, apartmentNumber)}`}
            icon={HouseIcon}
          />
        )}
        {typeText && (
          <CardInfo
            label={t(TranslationLabels.ticketingDetailsRequestTypeLabel)}
            value={typeText}
            icon={HouseIcon}
          />
        )}
        <CardInfo
          label={t(TranslationLabels.ticketingDetailsRequestDateLabel)}
          value={formatStandardDate(createdDate)}
          icon={CalendarIcon}
        />
        <div>
          <TicketComments
            ticketId={ticketId}
            referenceNumber={referenceNumber}
          />
        </div>
      </Grid>
    </Box>
  );
};
