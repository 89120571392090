import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';
import { Theme } from '@material-ui/core/styles';
import bg from './assets/bg.svg';

export type Props = {
  imgMaxWidth?: string; // e.g. '100px'
  imgMaxHeight?: string; // e.g. '100px'
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    box: {
      background: `url(${bg}) ${palette.blue10} 50% 50% no-repeat`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4),
      margin: theme.spacing(5, 0),
    },
    imgWrapper: {
      paddingRight: theme.spacing(3),
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'left',
        marginRight: theme.spacing(3),
        position: 'relative',
        width: ({ imgMaxWidth }) => imgMaxWidth,
      },
    },
    img: {
      maxWidth: ({ imgMaxWidth }) => imgMaxWidth,
      maxHeight: ({ imgMaxHeight }) => imgMaxHeight,
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        top: ({ imgMaxHeight }) => `calc(-${imgMaxHeight}/2)`,
        width: ({ imgMaxWidth }) => imgMaxWidth,
        height: ({ imgMaxHeight }) => imgMaxHeight,
      },
    },
    text: {
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      '& span': {
        color: palette.internationalOrange,
      },
    },
    buttonWrapper: {
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        paddingLeft: 0,
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(3),
      },
    },
    buttonIcon: {
      marginLeft: theme.spacing(2),
    },
  }),
);
