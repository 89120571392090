import { TranslationLabels } from '@generated/translation-labels';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Content, Elements } from '../../../shared';
import { RouteParams } from '../invoice-and-customer-account-type';
import { stepper } from '../../stepper.config';
import { useGetSubItemsQuery } from './sub-items.hook';

export const SubItems: FC = () => {
  const { itemId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const country = useCountry();
  const { data: response, isLoading } = useGetSubItemsQuery(
    country,
    pathname,
    itemId,
  );

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
      }}
      isFetching={isLoading}
      stepperConfig={stepper}
    >
      <Elements
        data={response?.items}
        otherElementRedirectURL={response?.otherElementRedirectURL}
        dataTestPrefix="subItems"
      />
    </Content>
  );
};
