import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Bar, Box, HeaderSection, Icon, Spinner } from '@shared/components';
import { generateUniqId, getEnvValue } from '@shared/helpers';
import { useCountry } from '@shared/hooks';
import React, { FC } from 'react';
import { ReactComponent as ArrowRight1Icon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg';
import { useTranslation } from '@shared/translations';
import { icons } from './faq.const';
import { useStyles } from './faq.styles';
import { useCategoriesQuery } from './faq.hook';

export const Faq: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const { data: categories, isLoading, isError } = useCategoriesQuery(country);

  const topicUrlPrefix = `${getEnvValue(
    `KUNDO_URL_${country.toUpperCase()}`,
  )}/posts`;

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !categories) {
    return null;
  }

  return categories.length > 0 ? (
    <div className={classes.container}>
      <HeaderSection htmlText={t(TranslationLabels.ticketingFaqTitle)} />
      <Grid container spacing={2}>
        {categories.map(({ allName, headingName, slug, topics }) => (
          <Grid key={generateUniqId()} item xs={12} sm={6}>
            <Box>
              <div className={classes.categoryTitleWrapper}>
                {icons[slug] && (
                  <Icon
                    className={classes.categoryIcon}
                    height={40}
                    icon={icons[slug]}
                    width={40}
                  />
                )}
                <Typography variant="h3" className={classes.categoryTitle}>
                  <strong>{headingName}</strong>
                </Typography>
              </div>
              <Bar className={classes.bar} />
              <ul>
                {topics.map(({ absoluteUrl, title }) => (
                  <li key={generateUniqId()} className={classes.link}>
                    <a
                      href={absoluteUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      title={title}
                    >
                      <Icon
                        icon={ArrowRight1Icon}
                        className={classes.linkIcon}
                        height={16}
                        width={16}
                      />
                      <Typography
                        component="span"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    </a>
                  </li>
                ))}
              </ul>
              <div className={classes.buttonsRow}>
                <Button
                  className={classes.button}
                  color="primary"
                  href={`${topicUrlPrefix}/${slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="outlined"
                >
                  {allName}
                </Button>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  ) : null;
};
