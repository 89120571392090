import { useQuery, UseQueryResult } from 'react-query';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Suggestion } from '@shared/components/HeaderSearchBox/header-search-box.type';
import flatten from 'lodash-es/flatten';
import uniqBy from 'lodash-es/uniqBy';
import { ticketingAPI } from '../../../+ticketing';

export const useGetSuggestionsQuery = (
  country: GenericTypes.Country,
  isTenant: boolean,
  lang: GenericTypes.SanityLanguageKey,
  query: string,
): UseQueryResult<Suggestion[]> => {
  return useQuery<Suggestion[]>(
    ['ticketingSuggestions', country, isTenant, lang, query],
    async () => {
      if (!country || isTenant === undefined || !lang || !query) return [];

      const response = await Promise.all([
        sanityClient.fetch<Suggestion[]>(
          `
        *[_type == $type && country == $country && (
          [menuTitle->value[$lang], menuText->value[$lang]] match $query ||
          searchEntries[]->{"value": value[$lang]}.value match $query
         ) && defined(searchEntries[0])]{
          "key": route,
          "titleKey": searchEntries[0]->key,
          route
        }
      `,
          {
            query: `*${query}*`,
            lang,
            type: SanityDocumentType.PAGE,
            country,
          },
        ),
        ticketingAPI.getSuggestions$(country, isTenant, lang, query),
      ]);

      const flattenedResponse = flatten(response);

      return uniqBy(flattenedResponse, 'route');
    },
  );
};
