import { TranslationLabels } from '@generated/translation-labels';
import { FieldConfig } from '@shared/FormV2/types';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { Values } from './form.type';

export const getFields = (): FieldConfig<Values>[] => {
  return [
    {
      componentType: 'input',
      field: {
        name: 'summary',
        initialValue: '',
      },
      props: {
        type: 'text',
        label: TranslationLabels.ticketingServiceRequestSummaryLabel,
        required: true,
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'description',
        initialValue: '',
      },
      props: {
        type: 'textarea',
        label: TranslationLabels.ticketingServiceRequestDescriptionLabel,
        required: true,
      },
    },
    {
      componentType: 'attachment',
      field: {
        name: 'attachment',
        initialValue: [],
      },
      props: {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        // onPreviewClick$: ticketingClient.getAttachment$, // todo
      },
    },
    {
      componentType: 'switch',
      field: {
        name: 'visibility',
        initialValue: ServiceRequestVisibilityEnum.PUBLIC,
      },
      props: {
        label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
        checkedLabelKey:
          TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
        checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
        uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
        checkedValueDescriptionKey:
          TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
        helperTextKey:
          TranslationLabels.ticketingServiceRequestVisibilityHelperText,
      },
    },
  ];
};
