import React, { FC, SVGProps } from 'react';
import { Icon, Spinner } from '@shared/components';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { palette } from '@shared/consts';

import { useStyles } from './link-item.styles';

type Props = {
  text: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  url?: GenericTypes.URL;
  onClick?: () => void;
  isLoading?: boolean;
};

export const LinkItem: FC<Props> = ({
  text,
  icon,
  url,
  onClick,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Grid item md={8} xs={12}>
      <MuiLink
        href={url}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.container}
        component={url ? 'a' : 'button'}
      >
        <p className={classes.text}>{text}</p>
        {isLoading ? (
          <Spinner size={24} className={classes.spinner} />
        ) : (
          <Icon
            className={classes.icon}
            icon={icon}
            width={24}
            height={24}
            color={palette.lighterGray}
          />
        )}
      </MuiLink>
    </Grid>
  );
};
