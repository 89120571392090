import { TranslationLabels } from '@generated/translation-labels';

export const PLACEHOLDER = '-';
export const DEFAULT_LANGUAGE = 'en';
export const SENTRY_LOCATION_TAG = 'location';
export const DEFAULT_COUNTRY = 'nl';

export const SUPPORTED_COUNTRIES: GenericTypes.Country[] = ['nl'];
export const SUPPORTED_COUNTRIES_ALL_VERSIONS: GenericTypes.Country[] = [
  'nl',
  'dk',
  'no',
  'is',
  'pl',
  'de',
];
export const COUNTRY_TRANSLATION_LABELS: {
  [key in GenericTypes.Country]: GenericTypes.TranslationLabel;
} = {
  dk: TranslationLabels.denmarkLabel,
  no: TranslationLabels.norwayLabel,
  se: TranslationLabels.swedenLabel,
  is: TranslationLabels.icelandLabel,
  nl: TranslationLabels.netherlandLabel,
  pl: TranslationLabels.polandLabel,
  de: TranslationLabels.germanyLabel,
};
export const SUPPORTED_LANGS: GenericTypes.Language[] = [
  DEFAULT_LANGUAGE,
  'no',
  'da',
  'is',
  'nl',
  'pl',
  'de',
];

// Languages that are released and fully supported on the production
export const PRODUCTION_LANGS: GenericTypes.Language[] = [
  DEFAULT_LANGUAGE,
  'no',
  'da',
  'is',
  'nl',
  'de',
];

export const ADDITIONAL_LANGS: GenericTypes.AdditionalLanguage[] = ['nb', 'nn'];
export const SUPPORTED_LANGS_PER_COUNTRY: Record<
  GenericTypes.Country,
  typeof SUPPORTED_LANGS
> = {
  no: [DEFAULT_LANGUAGE, 'no'],
  dk: [DEFAULT_LANGUAGE, 'da'],
  is: [DEFAULT_LANGUAGE, 'is'],
  nl: [DEFAULT_LANGUAGE, 'nl'],
  pl: [DEFAULT_LANGUAGE, 'pl'],
  de: [DEFAULT_LANGUAGE, 'de'],
  se: [DEFAULT_LANGUAGE],
};
export const LANGS_TRANSLATION_LABELS: {
  [key in GenericTypes.Language]: GenericTypes.TranslationLabel;
} = {
  en: TranslationLabels.englishLang,
  da: TranslationLabels.danishLang,
  no: TranslationLabels.norwegianLang,
  is: TranslationLabels.icelandicLabel,
  nl: TranslationLabels.dutchLang,
  pl: TranslationLabels.polishLang,
  de: TranslationLabels.germanyLabel,
  cimode: 'cimode',
};
