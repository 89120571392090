import { TranslationLabels } from '@generated/translation-labels';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import * as Sentry from '@sentry/react';
import { Icon, ImageModalPreview, Spinner } from '@shared/components';
import { PreviewModal } from '@shared/components/PreviewModal/preview-modal.component';
import { AttachmentValue, AttachmentValueElement } from '@shared/FormV2/fields';
import { useTranslation } from '@shared/translations';
import { ReactComponent as Delete1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove-circle.svg';
import { ReactComponent as ImageFileSearch } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-search.svg';
import { ReactComponent as OfficeFilePdf1 } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/office-files/office-file-pdf-1.svg';
import { useSnackbar } from 'notistack';
import React, { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useStyles } from './attachments-thumb.style';

type Props = {
  files: AttachmentValue;
  disabled?: boolean;

  handleDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClick?: (attachment: AttachmentValueElement) => Promise<string>;
};

export const AttachmentsThumb: FC<Props> = (props) => {
  const { disabled, files, handleDelete } = props;
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState('');
  const [isFetchingFile, setFetchingFile] = useState<boolean | number>(false);
  const isDisabled = useMemo(
    () => disabled || typeof isFetchingFile === 'number',
    [disabled, isFetchingFile],
  );

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleOutcomingAttachmentPreview = (file: Blob): void => {
    const url = URL.createObjectURL(file);
    window.open(url);
  };

  const handleIncomingAttachmentPreview = useCallback(
    async (attachment: AttachmentValueElement, idx: number): Promise<void> => {
      setFetchingFile(idx);

      try {
        const { url, name } = attachment;
        if (!url) {
          return;
        }

        // this condition is checking if attachment is pdf file
        if (name.includes('pdf')) {
          window.open(url);
        } else {
          setPreviewUrl(url);
        }
      } catch (e) {
        enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
          variant: 'error',
        });
        Sentry.captureException(e);
      } finally {
        setFetchingFile(false);
      }
    },
    [enqueueSnackbar, t],
  );
  const handleClick = useCallback(
    async (attachment: AttachmentValueElement, idx: number): Promise<void> => {
      const { url, value } = attachment;

      if (!url) {
        handleOutcomingAttachmentPreview(value as Blob);

        return;
      }

      await handleIncomingAttachmentPreview(attachment, idx);
    },
    [handleIncomingAttachmentPreview],
  );

  if (!files.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      {files.map((file, idx) => {
        const { name, url, id } = file;
        const isPdf = name.split('.').pop() === 'pdf';

        return (
          <ButtonBase
            onClick={() => {
              handleClick(file, idx);
            }}
            className={classes.file}
            disabled={isDisabled}
            key={id || url}
          >
            {isFetchingFile === idx ? (
              <Spinner size={32} className={classes.fileIcon} />
            ) : (
              <Icon
                className={classes.fileIcon}
                height={32}
                icon={isPdf ? OfficeFilePdf1 : ImageFileSearch}
                width={30}
              />
            )}
            <span className={classes.buttonText}>{name}</span>
            {handleDelete && (
              <>
                <span className={classes.divider} />
                <IconButton
                  data-file-index={idx}
                  disabled={isDisabled}
                  onClick={handleDelete}
                  className={classes.button}
                >
                  <Icon
                    className={classes.deleteIcon}
                    height={24}
                    icon={Delete1Icon}
                    width={24}
                    strokeWidth={1.5}
                  />
                </IconButton>
              </>
            )}
          </ButtonBase>
        );
      })}
      <PreviewModal
        isOpen={!!previewUrl}
        LeftButton={() => <></>}
        RightButton={() => <></>}
        onClose={() => {
          setPreviewUrl('');
        }}
      >
        {({ className }) => (
          <ImageModalPreview className={className} isVisible url={previewUrl} />
        )}
      </PreviewModal>
    </div>
  );
};
