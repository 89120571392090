import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo, useState } from 'react';
import {
  useAddAttachments,
  useCreateComplaintRequest,
} from '@shared/http/mie/api/ticket';
import { FormProps } from '@shared/FormV2';
import { MieComplaintRequestType } from '@shared/http/mie/api/ticket/mie.type';
import { useParams } from 'react-router-dom';
import { useCountry } from '@shared/hooks';
import { Content, RequestCreator } from '../../shared';
import { getFields, Values } from './issue-complaint-form.model';
import { ReactComponent as AdditionalInfoImage } from '../../assets/additional-info.svg';
import { stepper } from '../stepper.config';
import { RouteParams } from '../type';

export const IssueComplaintForm: FC = () => {
  const country = useCountry();
  const createComplaintRequestMutation = useCreateComplaintRequest();
  const addAttachments = useAddAttachments();
  const { unitId } = useParams<RouteParams>();
  const [ticketId, setTicketId] = useState('');

  const config = useMemo(
    (): FormProps<Values>['config'] => ({
      onSubmit: async (values) => {
        const response = await createComplaintRequestMutation.mutateAsync({
          summary: values.summary,
          description: values.description,
          entity_uuid: unitId,
          entity_type: 'unit',
          visibility: values.visibility,
          complaint_type: Object.entries(values.type)
            .filter(([, value]) => value)
            .map(([key]) => key as MieComplaintRequestType),
        });
        setTicketId(response.id);
        addAttachments.mutateAsync({
          ticketId: response.id,
          attachments: values.attachment,
        });
      },
    }),
    [createComplaintRequestMutation, unitId, addAttachments],
  );

  const fields = getFields(country);

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingGeneralText,
        titleKey: TranslationLabels.ticketingGeneralTitle,
        childrenRightColumn: <AdditionalInfoImage />,
      }}
      type="columns"
      stepperConfig={stepper}
    >
      <RequestCreator<Values>
        formProps={{ config, fieldsConfig: fields }}
        fullWidth
        ticketType="complaint"
        ticketId={ticketId}
      />
    </Content>
  );
};
