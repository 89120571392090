import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import { CheckboxesProps, CheckboxesValue } from '@shared/FormV2/fields/types';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from '@shared/translations';
import { getFirstError } from '@shared/components';

type Props<TFormValues> = FormikFieldProps<CheckboxesValue, TFormValues> & {
  props: CheckboxesProps;
};

export function CheckboxesFieldV2<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const { meta, form, field, props: fieldProps } = props;
  const { touched, error, value } = meta;
  const { setFieldValue, setFieldTouched } = form;
  const { name } = field;
  const { options, disabled, required, readonly, label } = fieldProps;

  const { t } = useTranslation();
  const errorMessage = touched && getFirstError(t, error);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFieldTouched(name);
    setFieldValue(name, {
      ...value,
      [event.target.name]: event.target.checked,
    });
  };

  const readonlyValue =
    value &&
    (options || [])
      .filter(({ name }) => value[name])
      .map(({ label }) => t(label))
      .join(', ');

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      required={required && !readonly}
      error={Boolean(errorMessage)}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly value={readonlyValue} />
      ) : (
        <FormGroup>
          {(options || []).map(({ name, label }) => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={value[name]}
                  onChange={handleChange}
                  name={name}
                />
              }
              label={t(label)}
              data-test={`checkbox-field-label:${name}`}
            />
          ))}
        </FormGroup>
      )}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
